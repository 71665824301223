<template>
  <section id="tc7">
    <h2 class="red">METHOD<span>成功者の声から生まれた</span></h2>
    <figure class="f1"><img v-lazy="'/top2020/img/tc7_01.webp'" alt=""></figure>
    <p class="pre">JJ ENGLISHエクササイズをコツコツ継続した人は</p>
    <p class="lead">
      なぜ、５人中４人以上も成功できるのか？
    </p>
    <p class="under-lead">
      <span class="marker">秘密は３つの「決め手」の実践でした。</span>
    </p>

    <div class="flame unit1">
      <p class="num"><span><span class="local">決め手</span><span>01</span></span></p>
      <div class="head-outer">
        <div class="head">
          <p class="secondary">高度な文法や単語を知っているから話せるのではない</p>
          <p class="primary">
            中学英語の「知識」を「技能」に変えて<br>
            <span class="marker">まずは、世界標準のシンプル英語をめざす！</span>
          </p>
          <p class="secondary">（“純ジャパEnglish”でいいじゃない！）</p>
        </div>
      </div>
      <div class="title">
        <figure class="main"><img v-lazy="'/top2020/img/img_method01_pc.webp'" class="pc_only"><img v-lazy="'/top2020/img/img_method01_sp.webp'" class="sp_only"></figure>
        <div class="txt">
          <p>
            くり返しになりますが、ネイティブの英語も日常会話は中学英語レベルの文法と基礎的な動詞で成り立っています。また、世界人口の80％以上の非ネイティブが使う英語はシンプルそのもの。母国語訛りも文法の小さなミスも気にしません。
          </p>
          <p>
            それもそのはず。英語は語順で成立する言葉ですから、少々発音が悪くても、小さな文法ミスがあっても、<span class="marker2">語順さえしっかりしていれば、じゅうぶん通じるからです。</span>
          </p>
          <p>
            <span class="marker2">教科書英語は使えないというのも、とんでもないカン違いです。</span><br>
            中学で習う基礎的な語順や文法こそ、英語を話すために必要不可欠な、土台であり、柱となる「知識」なのです。その「知識」を「技能」に変えただけで、<span class="marker2">世界中のどんな人にも通じるシンプルな英語がじゅうぶん話せるのです。</span>
          </p>
          <p>
            この考え方は、<span class="marker2">あなたがすでに持っている学習資産を活かせる上に、ムダがない。</span>だから、「話せるようになる」確率が必然的に高くなるのです。
          </p>
          <div class="catch">
            <p class="speech">
              世界の大多数は中学英語レベルで巧みに話してる！<br>「ネイティブ並み」は、その先のオプション目標でいい
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="flame unit2">
      <p class="num"><span><span class="local">決め手</span><span>02</span></span></p>
      <div class="head-outer">
        <div class="head">
          <p class="secondary">気がつけそうで気がつけない急所のマスター</p>
          <p class="primary">
            「３つの技能」をすべて習得して<br>
            <span class="marker">「純ジャパ特有の壁」を突破する！</span>
          </p>
          <p class="secondary">（トリプル・ラーニング・メソッド）</p>
        </div>
      </div>
      <div class="title">
        <figure class="main"><img v-lazy="'/top2020/img/img_method02_pc.webp'" class="pc_only"><img v-lazy="'/top2020/img/img_method02_sp.webp'" class="sp_only"></figure>
        <div class="txt">
          <p>
            英語のテストはそこそこだったのに少しも話せない――。<br>
            なぜでしょう？ それは、学校で習った英語を実際の会話で「聞ける」「話せる」力を身につけていないから。 つまり英語力がアタマのなかの「知識」でしかないのです。
          </p>
          <p>
            では、「知識」だけの英語力を会話で使える「技能」に変えるには何が必要になるのでしょうか。それは、ズバリ以下の３つの技能を身につけることです。
          </p>
          <p>
            １<span class="marker2">［使い回しの効くテンプレ表現］</span>を 聞けて・話せる<br>
            ２<span class="marker2">［長短まざった英文］</span>を 聞けて・話せる<br>
            ３<span class="marker2">［英語特有の音声変化］</span>を 聞けて・話せる
          </p>
          <p>
            これらの１つ技能を学べる教材はこれまでにもありました。しかしそこには大きな落とし穴があったのです。<br>
            それは、<span class="marker2">これらの１つを身につけただけでは、「知識」が完全には「技能」に変わらないという落とし穴です。</span>そうなのです。これら３つの技能をすべて身につけて、はじめて中学英語を会話でフル活用できる「技能」が手に入るのです。
          </p>
          <p>
            「ほかの教材やアプリをやったけれど話せるようにならなかった」。<br>
            その失敗の原因は、この落とし穴。あまりにも多くの人がそこにはまっています。<br>
            <span class="marker2">３つの技能をすべて習得できるのは、唯一JJ ENGLISHエクササイズだけなのです。</span>
          </p>
          <div class="catch">
            <p class="primary">
              １つの技能を習得しただけでは、<br>
              いつまでたっても英語は話せない(泣)
            </p>
            <p>
              JJ ENGLISHエクササイズの<br>
              <span class="large">4冊のテキスト／すべての動画＆音声</span>は、<br>
              <span class="marker">「３つの技能」の習得を高度に意図して作成されています。</span>
            </p>
          </div>
          <p class="btn-area">
            <a href="/detail/text.html" target="_blank"><img v-lazy="'/top2020/img/btn_text_2.webp'"><img class="btn_arrow" v-lazy="'/top2020/img/btn_arrow.webp'"></a>
          </p>
        </div>
      </div>
    </div>

    <div class="about">
      <figure>
        <img class="balloon" v-lazy="'/top2020/img/tc7_balloon.webp'">
        <img v-lazy="'/top2020/img/img_method03_pc.webp?date=20210727'" class="pc_only">
        <img v-lazy="'/top2020/img/img_method03_sp.webp?date=20210727'" class="sp_only">
      </figure>
      <div class="about_inner">
        <div class="block block2">
          <figure><img v-lazy="'/top2020/img/img_method05_pc.webp'" class="pc_only"><img v-lazy="'/top2020/img/img_method05_sp.webp'" class="sp_only"></figure>
          <p>
            ネイティブはもちろん、話せる人の英語を聞いていると、I think that～（～だと思うんだよね）、It seems that～（～みたいだね）、instead of～（～の代わりに）など、いくつかの中学レベルの表現が多用されることに気づきます。<br>
            このようによく使われるフレーズは、<span class="marker2">一部の単語や「〜」の部分を入れ替えると、別の意味もどんどん言える便利なテンプレ表現です。</span><br>
            <br>
            入門者が話せるようになるためには、<span class="marker2">そうした「使い回しの効くテンプレ表現」を聞けて話せるようになるのが圧倒的に近道。</span><br>
            <br>
            この教材では、そんなテンプレフレーズを中心に、<span class="marker2">360個の「便利な表現」を厳選。それらを「使いこなす」ノウハウと意識を、身体を使ったエクササイズで刷り込みます。</span><br>
            <br>
            360個も！と心配しないでください。そのほとんどは、みなさんが一度は目にしたことのあるフレーズばかりです。
          </p>
          <div class="block_inner">
            <dl>
              <dt><img v-lazy="'/top2020/img/img_method05-1.webp'"></dt>
              <dd>「知識」から「答え」を思い出そうとして、なかなか思い出せない。<br>正しいかどうか考えてなかなか話せない。</dd>
            </dl>
            <p class="arrow"></p>
            <dl>
              <dt><img v-lazy="'/top2020/img/img_method05-2.webp'"></dt>
              <dd>お決まりのフレーズがぽんと口から出る。<br>一部を入れ替えたり、それらを組み合わせて<br>テンポよく話せるようになる。</dd>
            </dl>
          </div>
        </div>
        <div class="block block1">
          <figure><img v-lazy="'/top2020/img/img_method04_pc.webp'" class="pc_only"><img v-lazy="'/top2020/img/img_method04_sp.webp'" class="sp_only"></figure>
          <p>
            短いフレーズなら聞き取れても、連続して英語を話されると耳がついていけない。自分で連続しては話せない。これは、多くの日本人がぶつかる大きな壁のひとつです。<br>
            <br>
            その原因には「音」の問題もあります。しかし、もっと根本的には、<span class="marker2">「英語独特の語順」を「知識」として覚えているだけで、耳と口が覚えていないからです。</span><br>
            <br>
            「適切な語順に単語を並べられる」。じつは、それが英語を話せるという力にほかならないのです。そして、ありがたいことに、<span class="marker2">ネイティブの会話で使われる語順や文型も、すべて中学で習ったものの組み合わせでしかないのです。</span><br>
            <br>
            この教材では、長短織り交ざった会話文のテキストを使い、口・耳・目を動かすエクササイズで、<span class="marker2">英語という言語のエッセンスである語順や基本文型を身体で覚え直します。</span><br>
            短い例文をいくら丸暗記しても、それだけでは、じっさいの会話では歯が立ちません。
          </p>
          <div class="block_inner">
            <dl>
              <dt><img v-lazy="'/top2020/img/img_method04-1.webp'"></dt>
              <dd>相手の言っていることが、<br>部分的にしか聞き取れない。</dd>
            </dl>
            <p class="arrow"></p>
            <dl>
              <dt><img v-lazy="'/top2020/img/img_method04-2.webp'"></dt>
              <dd>相手の言っていることが頭から理解できて、<br>ずっと聞き取っていける。<br>自分でも、次々と話していける。</dd>
            </dl>
          </div>
        </div>
        <div class="block block3">
          <figure><img v-lazy="'/top2020/img/img_method06_pc.webp'" class="pc_only"><img v-lazy="'/top2020/img/img_method06_sp.webp'" class="sp_only"></figure>
          <p>
            「ワザッ」「オニッ」の意味がわかりますか？ もちろん、「技」でも「鬼」でもありません。正解は、What’s that? (それは何？)と on it（それの上に）という中学１年生レベルの英語です。日本人が英語を聞き取れない大きな理由のひとつは、こうした英語特有の省略や音の脱落・連結を理解していないから。<br>
            <br>
            でも安心してください。この教材には、日本人の苦手な「発音の急所」を改善するエクササイズが用意されています。<span class="marker2">そこでつかんだ技能を、日々の音読エクササイズにしっかり反映させると、驚くほどリスニング力がつき、発話も流暢になっていきます。</span>
          </p>
          <div class="block_inner">
            <dl>
              <dt><img v-lazy="'/top2020/img/img_method06-1.webp'"></dt>
              <dd>ネイティブの早い会話が聞き取れない。<br>違う言葉に聞こえてしまう。</dd>
            </dl>
            <p class="arrow"></p>
            <dl>
              <dt><img v-lazy="'/top2020/img/img_method06-2.webp'"></dt>
              <dd>聞き取れなかったのが不思議に思えるほど、<br>早い会話でも楽に聞き取れるようになる。</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <div class="flame unit3">
      <p class="num"><span><span class="local">決め手</span><span>03</span></span></p>
      <div class="head-outer">
        <div class="head">
          <p class="secondary">王道的かつ最新のメソッドを地道に継続</p>
          <p class="primary">
            口、耳、目をしっかり使う「パワー音読」で<br>
            <span class="marker">アタマの中の英語をカラダで覚え直す！</span>
          </p>
          <p class="secondary">（エクササイズ主義！）</p>
        </div>
      </div>
      <div class="title">
        <figure class="main"><img v-lazy="'/top2020/img/img_method07_pc.webp'" class="pc_only"><img v-lazy="'/top2020/img/img_method07_sp.webp'" class="sp_only"></figure>
        <div class="txt">
          <p>
              シンプル英語をものにするために必須の「３つの技能」は、どんな学習法で身につくのでしょう？
              中学英語の「知識」を聞けて話せる「技能」に変える学習法とは？
          </p>
          <p>
            ずばり申し上げます。その答えは<span class="middle marker2">「音読」</span>です。ひたすら<span class="middle marker2">「音読」</span>です。
          </p>
          <p>
            ただし、音読と言っても昔学校でやったような音読ではありません。<span class="marker2">ネイティブの音声をしっかりインプットして、その音声を完全にマネをする「正しい音読」です。<br><br></span>
          </p>
          <p>
            音読？
            そんなことか。面倒くさそう。と思われた方がいらっしゃるかもしれません。でも、ここからの説明を読んでも同じように思われるでしょうか。
          </p>
          <h4 class="center">[日本で英語を話せるようになった78人へのヒアリング結果]</h4>
          <p class="hearing">
            <span><img v-lazy="'/top2020/img/hearing_0.webp'" /></span>
            <span><img v-lazy="'/top2020/img/hearing_73.webp'" /></span>
          </p>
          <p>
            圧倒的なデータです。<br>
            4人の監修者も、もちろん<span class="marker2">全員一致でもっとも効果的な学習法として正しい「音読」を推奨しています。</span>
          </p>
          <p>
            そもそも外国語の習得には、<span class="marker2">スポーツや音楽と同じようにカラダを使った練習が不可欠です。</span>サッカーや野球の解説書をいくら読んでも、カラダを動かす練習をしなければ永遠にプレーヤーにはなれませんよね。
          </p>
          <p>
            逆もまた真です。<span class="marker2">カラダを使った練習さえ継続すれば、スポーツや楽器と同じように、<span class="middle">英語は誰でも話せるようになれるのです。</span></span>カラダを使う練習のうち、もっとも基礎的で効果が高いのが「正しい音読」というわけです。<br>
            ある英語の先生は、<span class="marker2">音読は野球のバッティングにとっての「素振り」</span>だと言われています。「素振り」にはバッティング技術のエッセンスがすべてつまっているのです。<br><br>
          </p>
          <h3 class="red">「正しい音読」のスゴすぎる効果</h3>
          <p>
            では、そこまで多くの成功者がすすめる「正しい音読」の効果とはどんなものなのでしょう？
            4人の監修者＋78名の成功者の体験談と関西学院大学教授・門田修平先生が書かれた『音読で外国語が話せるようになる科学』（ＳＢクリエイティブ刊）をもとに説明していきます。
          </p>
          <h3>１ リスニング力が上がる</h3>
          <p>
            意外に思われるかもしれませんが、最初に表れる音読の大きな効果はリスニング力の向上です。エクササイズ開始後平均して２〜４週間で、ポップスの歌詞やドラマのセリフの一部が聞き取れるようになった。そんな声が「ＪＪ」実践者からもたくさん寄せられています。<br>
            <span class="marker2">これは、「言える言葉は聞ける」という人の身体機能による効果です。</span>
          </p>
          <p>
            シャワーのように英語を聞いても、<span class="marker2">聞くだけではいつまでたってもリスニング力は上がりません。</span>自分で声を出して言えれば聞けるようになる。<span class="blue">これは、話せるようになった人たちだけが知っているとても重要なポイントです。</span>
          </p>
          <h3>２ スピーキング力が上がる</h3>
          <p>
            音読は「模擬会話」「リハーサル会話」と学術的には言われます。そうです。音読をしている間は、文字という“補助輪”付きですが、それ以外は、英語をスピーキングしているのと同じなのです。<br>
            <br>
            その時間が10時間、50時間、100時間と積み重なっていけばどうでしょう？自転車の補助輪がやがて要らなくなるように、<span class="marker2">文字なしでスピーキングでき始めるのはむしろ当たり前のことだと思われませんか？</span>
          </p>
          <h3>３ 文法力や単語力もセットで上がる</h3>
          <p>
            安河内先生の授業を受けた何万人もの受験生や門田先生の実験計測が証明しています。<br>
            とりわけ、英語を話す上で重要な<span class="marker2">語順や修飾の感覚がカラダで覚えられる</span>というのが実践者の共通した声です。<span class="blue">語順感覚の習得は、自在に英語を話せる（英文をつくれる）技能に直結しています。</span>
          </p>
          <h3>４ 発音がよくなる</h3>
          <p>
            発音とひとくちにいっても、正しい音の発声、イントネーション、アクセント、リズムなどいくつかの要素があります。徹底的にネイティブのお手本をマネする<span class="marker2">音読エクササイズは、いうまでもなくそれらのすべてを向上させます。</span>
          </p>
          <h3>５ 長期的記憶ができる</h3>
          <p>
            じっさいに口を動かし目と耳も同時に使う「音読」によって得られた記憶は、黙読による暗記と違って、<span class="marker2">「長期記憶」からやがて「潜在記憶」というほぼ一生忘れない脳の分野に定着する</span>こともわかっています。文法、単語、音声（発音）。それらがまさにカラダで覚えられるのです。<br><br>
          </p>
          <!-- <p>
            ここからは、「英語を話せるようになる」目標からは少し離れますが、次のような嬉しい効果が「音読」にあることも門田先生らの研究でわかっています。
          </p>
          <h3>６ 英語の黙読速度が上がる</h3>
          <p>
            安河内先生の授業を受けられた何万人もの受験生が証明してくれています。
          </p>
          <h3>７ 記憶力全般が向上する<span class="normal">（認知症の改善、進行抑制効果もある）</span></h3>
          <p>
            じつは日本語の音読でも効果があることがわかっています。ですが、意味を理解しながらの外国語の音読では、その効果がより高いことが研究で明らかになっています。<br><br>
          </p> -->
          <p>
            以上がこの教材で「音読」をメインエクササイズとして位置づけている理由です。<br>
            いかがですか？ いますぐにでも始めたくなってきませんか？<br><br>
          </p>
          <h3 class="red">「パワー音読」の効果はもっとスゴい</h3>
          <p>
            しかもです。この教材で推奨するのは、一般的な「音読」の効果をさらに強化させた「パワー音読」という最新メソッドです。すでに５冊以上の書籍が発売され、すべてがベストセラー。<span class="marker2">全国の中学校、高校の授業にも続々と導入されています。</span><br>
          </p>
          <p class="btn-area">
            <a href="/detail/ondoku.html" target="_blank"><img v-lazy="'/top2020/img/btn_ondoku_2.webp'"><img class="btn_arrow" v-lazy="'/top2020/img/btn_arrow.webp'"></a>
          </p>
          <p class="about-ondoku">
            <span class="blue">※「パワー音読」の具体的なやり方については、付属の「ウエルカムBOOK」と動画ファイルでわかりやすく説明してあります。下のアイコンをクリックすると動画のダイジェストをご覧いただけます。</span>
          </p>
          <figure class="ondoku-movie sub movie up">
            <a href="https://vyqqhb44.user.webaccel.jp/movie/ondoku-short-sample.mp4" target="_blank">
              <img v-lazy="'/top2020/img/tc9_01_sub.webp'" alt="">
            </a>
            <p class="movie-text">↑クリックしてダイジェストを見る</p>
          </figure>
          <h3 class="red">「７つのエクササイズ」でさらに効果UP</h3>
          <p>
            さらに、この教材では、「パワー音読」の効果を相乗的に高める６つのエクササイズが用意されています。<br>
            たとえば、3人の講師による<span class="blue">「レクチャー動画」</span>の視聴は、各レッスンのキーとなるテンプレ表現を理解できるので音読の予習として効果的。動く英字テロップを見ながら音声を聞く<span class="blue">「ワイプリスニング」</span>は、文法はほぼ完璧だけど文字と音が一致していないという人にうってつけの画期的なエクササイズ。リスニングの向上が加速します。<br>
          </p>
          <p class="btn-area">
            <a href="/detail/exercise.html" target="_blank"><img v-lazy="'/top2020/img/btn_exercise_2.webp'"><img class="btn_arrow" v-lazy="'/top2020/img/btn_arrow.webp'"></a>
          </p>
          <h3 class="red"><img v-lazy="'/top2020/img/bubble_title.webp'"></h3>
          <p>
            これほどまでに効果的なエクササイズである音読（パワー音読）ですが、じつは、どんなテキストを選ぶのかがとても重要になります。
          </p>
          <p>
            <span class="marker2">音読エクササイズは、読むテキストの質や内容がそのまま身になります。</span>一部の目的に特化したテキストを読んだなら、その技能しか身につきません。レベルの低いテキストを読めば、身につく英語力もそれなりになってしまうからです。
          </p>
          <p>
            理想のテキストを示す前に、推奨できないテキスト、上達の効果が薄いテキストを示してみます。
          </p>
          <p class="img-wrapper">
            <img v-lazy="'/top2020/img/bad_text.webp'">
          </p>
          <p>
            みなさん、お気づきでしょうか？<br>
            上にあげたテキストは、<span class="middle">じつは、一般的に流通している多くの英語テキストそのものなのです。</span>
          </p>
          <p>
            書籍教材のなかには優れたテキストもいくつかあります。しかし、書籍という性格上、<span class="marker2">ひとつの学習目的に特化したものがほとんどで、その１冊では、アタマのなかの「知識」を使える「技能」には変えられないのです。</span>英語をカラダで覚え直すためには、<span class="marker2">分量的に足りないのもかなり致命的です。</span>
          </p>
          <p>
            ネットで販売されている高価な教材はどうでしょう。こちらは残念なことに、<span class="marker2">質量ともに粗悪なものや値段に見合っていなものが少なくありません。</span>その結果、<br>
            <br>
            ◯<span class="blue bold">お金を払い、まじめに実践してもほとんど効果がない。</span><br>
            <br>
            ◯<span class="blue bold">いろんな種類の教材を大量に試行錯誤することになる。</span>（成功者78名のヒアリングによると、平均30冊以上のテキスト／費用＝7万円以上／時間＝3年以上）<br>
            <br>
            という、コスト的にも労力的にもとても残念な事態におちいってしまうのです。<br><br>
          </p>
          <h3 class="red">成功者の声から生まれた理想のテキスト</h3>
          <p>
            そうなのです。『JJ ENGLISHエクササイズ』のテキストは、こうした残念な事態に終止符を打つべく、<span class="marker2">4人の監修者と78名の成功者の考え抜かれたアドバイスをもとに、英文内容、質、分量のすべてを入念に計算して生み出された</span>のです。
          </p>
          <p class="img-wrapper">
            <img v-lazy="'/top2020/img/good_text.webp'">
          </p>
          <!-- <p class="btn-area">
            <a href="/detail/exercise.html" target="_blank"><img v-lazy="'/top2020/img/btn_exercise_2.webp'"><img class="btn_arrow" v-lazy="'/top2020/img/btn_arrow.webp'"></a>
            <a href="/detail/ondoku.html" target="_blank"><img v-lazy="'/top2020/img/btn_ondoku_2.webp'"><img class="btn_arrow" v-lazy="'/top2020/img/btn_arrow.webp'"></a>
          </p> -->
          <p class="btn-area">
            <a href="/detail/text.html" target="_blank"><img v-lazy="'/top2020/img/btn_text_2.webp'"><img class="btn_arrow" v-lazy="'/top2020/img/btn_arrow.webp'"></a>
          </p>
        </div>
      </div>
      <!-- <div id="doga" class="video-wrapper">
        <iframe width="840" height="472" src="https://www.youtube.com/embed/-SJti1bMxk4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <p>
          【運命の動画】みなさんの英語人生が大きく変わること間違いありません！！ <br>
          ただし、３分間の音読が実践できる環境やタイミングでご視聴ください。
        </p>
      </div> -->
      <!-- <div class="ondoku">
        <div class="unit1">
          <p class="secondary">
            アナタの音読をネイティブ講師が無料診断
          </p>
          <p class="primary">
            グイグイ上達します！
          </p>
          <p class="description">
            JJ ENGLISHエクササイズは、学習サポートも万全です。<br>
            <br>
            購入後合計12回受けられる「音読音声の個別診断」では、<span class="marker2">アナタの英語が通じるレベルかどうかの診断はもちろん、苦手な発音の矯正、ミスの改善法、アナタに合った練習法</span>まで、ネイティブ講師がていねいにアドバイスします。<br>
            <br>
            診断動画はメールで送られてくるので、<span class="marker2">好きな時に何度でも見返せます</span>。「スクール以上に効果的！」と大評判のサポートです。
          </p>
          <p class="look">↓↓クリックして実際のサンプル動画をご覧ください↓↓</p>
          <div class="movie">
            <a href="https://vyqqhb44.user.webaccel.jp/movie/ondoku_native.mp4" target="_blank"><img v-lazy="'/top2020/img/ondoku_native.webp'"></a>
            <img class="arrow pc" v-lazy="'/top2020/img/ondoku_arrow_h.webp'">
            <img class="arrow sp" v-lazy="'/top2020/img/ondoku_arrow_v.webp'">
            <a href="https://vyqqhb44.user.webaccel.jp/movie/ondoku_student.mp4" target="_blank"><img v-lazy="'/top2020/img/ondoku_student.webp'"></a>
          </div>
          <div class="point">
            <p>
              無料で<br>
              ６ヵ月12回！
            </p>
            <p>
              何度でも<br>
              見返せる！
            </p>
          </div>
        </div>
        <p class="ondoku-notice1">
          ※上達には個人差があります。
        </p>
        <p class="ondoku-notice">
          ※当教材には、「パワー音読」の実践方法をガイドする動画のほか、その他６つのエクササイズの実践方法をガイドする「ウエルカムBOOK」が付属しています。２年間無料のメールサポートも受けられます。<span class="marker2">英語学習においてもっとも大切な「学習の実践方法」について、ここまで万全なガイドがあるのは、高額なコーチング以外では、唯一「JJ ENGLISHエクササイズ」だけとなります。</span>
        </p>
      </div> -->
    </div>

    <div class="pkg">
      <div class="unit1">
        <div class="unit1_inner">
          <h3>
            <span class="matome">まとめ</span><br>
            そうなのです。JJ ENGLISHエクササイズは、<br>
            <em>日本でしか英語を学んだことのない<br>
            私たち“純ジャパ”が、もっとも<span class="marker">着実</span>かつ<span class="marker">効率的</span>に<br>
            「シンプルな英語（グローバルENGLISH）」を話せるようになる<br>
            <span class="matome margin-0">成功の方程式</span>を<br>
            <span class="marker">まるごとパッケージ化</span>した教材なのです。</em><br>
            <br>
            <img v-lazy="'/top2020/img//victory.webp'"><br>
            <span class="list">
              <img v-lazy="'/top2020/img//victory_fig.webp'"><br>
              <br>
              <em><span class="blue">そして、このメソッドは、<br>
                4人の監修者と78名の成功者が<br>
                試行錯誤の果てに見つけた<br>
                「成功の核心」そのものともいえます。</span></em><br>
              <br>
              <em>
                その方たちの成功の理由は<br>
                「特別な才能」などではありません。<br>
              </em>
              <span class="normal">
                英語が苦手だった。英語の偏差値が４０以下だった。<br>
                そのレベルから成功した方が何人もいるからです。<br>
                <br>
                そう、最大の理由は１つ。「成功の核心」の手応えを信じて、<br>
              </span>
              <em>
                地道にそれを継続した。<br>
                ただ、それだけなのです。<br>
              </em>
              <br>
              <em>
                同じことを、あなたにできない理由が<br>
                あるでしょうか？<br>
              </em>
              <br>
              <p class="more-point">
                <img v-lazy="'/top2020/img//more_point.webp'">
              </p>
              <br>
            </span>
            <!-- <br> -->
            <!-- <em>そうです。あとみなさんに必要な行動は、<br>
            <span class="marker">「決める」</span><span class="marker">「始める」</span><span class="marker">「継続する」。</span><br>
            たったのそれだけです。</em> -->
          </h3>
          <!-- <div class="step">
            <h3>上達の３段ステップ</h3>
            <div class="content">
              <h4>STEP１</h4>
              <p>
                「パワー音読」をメインとする<br>
                「７つのエクササイズ」を継続すれば<br>
                「知識」が「技能」に変わっていき<br>
                <span class="marker">中学英語を聞けて話せ始めます。</span><br>
                <span class="normal">（だいたい１〜２ヵ月目。感動しますよ！）</span>
              </p>
              <h4>STEP２</h4>
              <p>
                エクササイズと並行して、<br>
                「その時の自分の英語」で<br>
                <span class="marker">できるだけ、たくさん話していく。</span><br>
                オンラインでもOK。<br>
                <span class="normal">（楽しくてたまらないですよ！）</span><br>
                <span class="normal">（無料のアプリやサイトもあります）</span>
              </p>
              <h4>STEP３</h4>
              <p>
                たくさん話せば話すほど、<br>
                あなたの英語は高度になり<br>
                <span class="marker">どこまでも洗練されていきます。</span><br>
                <span class="normal">（ネイティブ並みを目指すのもあり！）</span>
              </p>
            </div>
          </div> -->
        </div>
        <!-- <div class="koushite">
          <h3>その先にはハッピーな未来が！</h3>
          <ul>
            <li><img v-lazy="'/top2020/img/tc2_01_2.webp'" /></li>
            <li><img v-lazy="'/top2020/img/tc2_02_2.webp'" /></li>
            <li><img v-lazy="'/top2020/img/tc2_08.webp'" /></li>
            <li><img v-lazy="'/top2020/img/tc2_04_2.webp'" /></li>
          </ul>
        </div>
        <p class="best-10">
          <img v-lazy="'/top2020/img/best_10.webp?date=20230725'" /><br>
          <br>
          ※購入者の主観的感想をアンケート集計したものです。
        </p> -->
      </div>
      <div class="unit2">
        <!-- <p>
          日本人の特性にマッチした、<br>
          話せるようになるために必要不可欠な要素を<br>
          １つにパッケージ化した教材−−。<br>
          <br>
          そんなの当たり前ではと思われましたか？<br>
          <span class="black">
            <span class="marker">残念ながらそれが当たり前ではないのです。</span><br>
            2023年の今なお、高額なコーチングを除くと<br>
            <span class="list">○「３つの技能」を同時に学べる教材<span class="none">なし</span><br>
            ○ 効果が実証された学習法を詳しく指導してある教材<span class="none">なし</span></span><br>
            これが英会話教材業界の現状です。<br>
            じつは、そんな現状を打ち破るために<br>
            JJ ENGLISHエクササイズは生まれました。<br>
            <br>
          </span>
          <span class="marker">一流大手出版社のクオリティとこの価格で<br>
          その当たり前を提供している教材・サービスは<br>
          JJ ENGLISHエクササイズ以外にはありません。<br></span>
          <br>
          ほかの教材やスクールで失敗してきた人も、<br>
          初めて英会話に挑戦した人も、<br>
          JJ ENGLISHエクササイズで、<br>
          次つぎと「英語が話せる人」の<br>
          仲間入りをしています！
        </p> -->
        <div class="catch">
          <p>
              <br>
              週に数回のスクール。聞き流し。短文の大量暗記などをメインにして、<br>
              英語を話せるようになりましたか？<br>
              <br>
              JJ ENGLISHエクササイズは断言します。<br>
              <br>
              <span class="large">
                ［３つの技能が身につくテキスト］<br>
              </span>
              を使った<br>
              <span class="large">
                ［正しい音読エクササイズ］<br>
              </span>
              <br>
              <span class="large">
                これこそ、“純ジャパ”の私たちが<br>
                <span class="marker">英会話学習の「核」にすべき学習法です。</span><br>
              </span>
              <br>
              「知識」を「技能」に変えるこの学習法がエンジンとなり、<br>
              外国人との実際のトークはもちろん、単語や熟語、<br>
              この教材でも実践できる瞬間英作、聞き流し学習など<br>
              <span class="marker">
                必要と感じて始めるすべての学習が<br>
                相乗効果を生みながらぐんぐん身についていくからです。<br>
              </span>
              <span class="large">
                <span class="marker">「話せる」へ着実に進んで行くからです。</span>
              </span>
          </p>
          <p>
            <span class="large">
              ほかの教材やスクールで失敗してきた人も、<br>
              初めて英会話に挑戦した人も、<br>
              JJ ENGLISHエクササイズで、<br>
              次つぎと「英語が話せる人」の<br>
              仲間入りをしています！
            </span>
          </p>
        </div>
        <h3>
          話せるヨロコビは無限大！！<br>
          “純ジャパEnglish”でいいじゃない！<br>
          <em class="margin">
            <span>成功者たち</span>が歩んだ<span>着実な方法</span>で<br>
            今度こそ<span>夢</span>を<span>達成</span>してみませんか？<br>
          </em>
          <span class="margin">想像以上に心躍る未来が<br>
          必ずあなたに訪れます。</span>
        </h3>
        <figure><img v-lazy="'/top2020/img/img_method09.webp?date=20220625'"></figure>
      </div>
    </div>
    <div>
      <slot name="product" />
      <slot name="bt_purchase" />
      <slot name="download" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'Tc7',
}
</script>

<style scoped lang="scss">
figure {
  &.method08 {
    text-align: center;
    img {
      width: 56%;
    }
  }
}
h3 {
  margin-bottom: 10px;
  .normal {
    font-weight: normal;
  }
  &.red {
    color: #fe3340;
    font-size: 32px;
  }
}
h4 {
  &.center {
    text-align: center;
  }
}
#tc7 {
  padding-top: 130px;
}
#tc7 h2.red {
  margin-bottom: 30px;
}
#tc7 figure.f1 {
  width: 360px;
  margin: 0 auto 15px auto;
}
#tc7 .pre {
  font-size: 20px;
  text-align: center;
  margin-bottom: 8px;
  margin-top: 30px;
}
#tc7 .lead {
  font-size: 56px;
  margin-bottom: 20px;
  font-weight: 700;
  text-align: center;
}
#tc7 .under-lead {
  font-size: 46px;
  margin-bottom: 100px;
  font-weight: 700;
  text-align: center;
}
#tc7 .over {
  font-size: 20px;
  text-align: center;
  margin-bottom: 70px;
}
#tc7 p.num {
  background-color: #fe3340;
  padding: 5px;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin: 0 auto 30px auto;
}
#tc7 p.num > span {
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  font-weight: 700;
  font-size: 32px;
  font-family: 'Roboto Condensed', sans-serif;
  height: 100%;
  box-sizing: border-box;
}
#tc7 p.num > span span{
  line-height: 1;
}
#tc7 p.num > span .local {
  font-size: 16px;
  font-weight: normal;
  display: block;
}
#tc7 .flame{
  width: 100%;
  max-width: 1300px;
  margin: 0 auto 36px;
  padding: 0 50px;
  box-sizing: border-box;
}
.btn-area {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  a {
    display: inline-block;
    margin-left: 28px;
    margin-right: 28px;
    margin-bottom: 50px;
    box-shadow: 4px 6px 6px rgba(0, 0, 0, 0.3);
    display: inline-block;
    border-radius: 20px;
    transition: ease 0.3s;
    position: relative;
    .btn_arrow {
      position: absolute;
      bottom: -20px;
      right: -6px;
      width: 32px;
      height: 50px !important;
    }
    &:hover {
      opacity: 0.8;
      //transform: scale(1.02) translateY(-1px);
    }
    img {
      display: block;
      height: 100px !important;
    }
  }
}
.about-ondoku {
  margin-top: -24px;
}
.ondoku-movie {
  width: 70%;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
}
#tc7 .flame .head-outer {
  text-align: center;
  .head {
    display: inline-block;
  }
}
#tc7 .flame .secondary{
  text-align: left;
  font-size: 26px;
  font-weight: 700;
  color: #fe3340;
  margin-bottom: 8px;
}
#tc7 .flame .thirdary{
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  color: #fe3340;
  margin-bottom: 8px;
}
#tc7 .flame .primary{
  text-align: left;
  font-size: 44px;
  font-weight: 700;
  color: #fe3340;
  margin-bottom: 10px;
}
#tc7 .flame .title{
  margin-top: 60px;
}
#tc7 .flame .txt{
  position: relative;
  width: 70%;
  margin: -200px auto 0;
  background:rgba(255,255,255,0.95);
  box-sizing: border-box;
  padding: 50px 8% 20px 8%;
  border-radius: 10px;
  .blue {
    color: #0a74d7;
    &.bold {
      font-weight: bold;
      font-size: 18px;
    }
  }
  .middle {
    font-size: 24px;
  }
}
#tc7 .flame .txt > p{
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  &.img-wrapper {
    width: 130%;
    margin-left: -15%;
  }
}
#tc7 .flame .txt > p:last-of-type{
  margin-bottom: 34px;
}
#tc7 .catch{
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}
#tc7 .catch p{
  display: inline-block;
  margin: 0 auto;
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  color: #fe3340;
  line-height: 1.5;
  position: relative;
  &.primary {
    text-align: center;
    font-size: 38px;
    font-weight: 700;
    color: #fe3340;
    margin-bottom: 30px;
  }
  .large {
    font-size: 29px;
  }
}
#tc7 .catch p.speech::before {
  content: '';
  display: inline-block;
  width: 7px;
  height: 70px;
  background: url("/top2020/img/fukidashi2.webp") no-repeat;
  background-size: contain;
  position: absolute;
  left: -30px;
  bottom: 0;
}
#tc7 .catch p.speech::after {
  content: '';
  display: inline-block;
  width: 7px;
  height: 70px;
  background: url("/top2020/img/fukidashi2.webp") no-repeat;
  background-size: contain;
  position: absolute;
  right: -30px;
  bottom: 0;
}
#tc7 .flame.unit2 {
  .txt {
    padding-bottom: 20px;
  }
}
#tc7 .flame.unit2 .catch p{
  letter-spacing: -0.02em;
  margin-bottom: 40px;
  .large {
    font-size: 30px;
  }
}
#tc7 .about{
  width: 100%;
  max-width: 1016px;
  margin: 0 auto 80px;
  padding: 0 50px;
  box-sizing: border-box;
  > figure {
    position: relative;
    img.balloon {
      position: absolute;
      width: 30%;
      top: -40%;
      left: 10%;
    }
  }
}
#tc7 .about .about_inner{
  background: url("/top2020/img/bg_method.webp") repeat;
  padding: 60px 12% 60px;
}
#tc7 .about .block{
  margin-bottom: 50px;
  .blue {
    color: #0a74d7;
  }
}
#tc7 .about .block:last-child{
  margin-bottom: 0;
}
#tc7 .about .block > figure{
  margin-bottom: 30px;
}
#tc7 .about .block > p{
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 54px;
}
#tc7 .about .block_inner{
  width: 92%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
#tc7 .about .block_inner dl{
  width: 45%;
}
#tc7 .about .block_inner dt{
  margin-bottom: 12px;
}
#tc7 .about .block_inner dt img{
  width: 100%;
}
#tc7 .about .block_inner dd{
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  letter-spacing: -0.05em;
}
#tc7 .about .block_inner .arrow{
  height: 34px;
  display: inline-block;
  vertical-align: middle;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 24px solid #fe3340;
  margin-top: 16%;
}
#tc7 .pkg .unit1 .koushite h3 {
  font-size: 38px;
  margin-bottom: 50px;
}
#tc7 .koushite {
  padding: 0 50px;
  margin: 0 auto 60px;
  max-width: 1016px;
  h3 {
    color: #fe3340;
    font-size: 42px;
    margin-bottom: 30px;
    .large {
      font-size: 48px;
    }
    text-align: center;
  }
  img {
    display: block;
    &.sp {
      display: none;
    }
  }
  ul {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
    li {
      width: 160px;
      height: 160px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
#tc7 .pkg .unit1 .step {
  padding: 0px 8% 60px;
  width: 70%;
  margin: 0 auto;
  text-align: center;
  .content {
    display: inline-block;
  }
  h3, h4, p {
    color: #fe3340;
    text-align: left;
  }
  h3 {
    font-size: 38px;
    margin-bottom: 30px;
    text-align: center;
    border-top: none;
    border-bottom: none;
  }
  h4, p {
    font-size: 24px;
  }
  h4 {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 30px;
    font-weight: bold;
    .normal {
      color: #fe3340;
      font-weight: normal;
    }
  }
}
#tc7 .pkg{
  width: 100%;
  max-width: 1016px;
  margin: 0 auto 0;
  padding: 0 50px;
  box-sizing: border-box;
  .blue {
    color: #0a74d7;
  }
}
#tc7 .pkg .unit1{
  background: url("/top2020/img/bg_pkg.webp") repeat;
  padding: 60px 4.6% 70px;
  margin-bottom: 40px;
  &.no-bg {
    background: none;
    padding: 0 4.6% 0;
    margin-bottom: 0;
  }
  .normal {
    margin-top: 60px;
    text-align: center;
    font-size: 20px;
    color: black;
  }
  .best-10 {
    margin-top: 100px;
    margin-bottom: 30px;
  }
}
#tc7 .pkg .unit1 h3{
  padding: 20px 0;
  border-top: 2px dotted #666;
  border-bottom: 2px dotted #666;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -0.05em;
  .matome {
    display: inline-block;
    font-size: 56px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 20px;
    &.margin-0 {
      margin-bottom: 0;
    }
  }
  .list {
    display: inline-block;
    text-align: center;
    line-height: 2em;
    margin-bottom: 20px;
    .normal {
      font-size: 18px;
      font-weight: 500;
    }
    .blue {
      color: #0a74d7;
    }
    .list-item {
      position: relative;
      padding-left: 1.25em;
      .small {
        font-size: 18px;
        position: relative;
        top: -16px;
        font-weight: normal;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0.25em;
        margin-left: -1.25em;
        width: 1em;
        height: 1em;;
        background-image: url("/top2020/img/check.webp");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
#tc7 .pkg .unit1 h3 em{
  font-size: 39px;
  font-style: normal;
}
#tc7 .pkg .unit1 h3 .sub{
  font-size: 20px;
  font-weight: normal;
  &.skill {
    line-height: 1em;
    position: relative;
    top: -16px;
  }
}
#tc7 .pkg .unit1 figure{
  position: relative;
}
#tc7 .pkg .unit1 img.icon{
  position: absolute;
  top: 10%;
  right: 10%;
  width: 16%;
}
#tc7 .pkg .unit2 .catch {
  padding: 0 60px 0;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
  p {
    text-align: center;
    margin-bottom: 60px;
  };
}
#tc7 .pkg .unit2 > p{
  text-align: center;
  font-size: 34px;
  color: #fe3340;
  margin-bottom: 70px;
  .black {
    text-align: center;
    font-size: 34px;
    color: black;
    .list {
      font-size: 24px;
      display: inline-block;
      margin-top: 30px;
      margin-bottom: 30px;
      .none {
        margin-left: 1em;
      }
    }
  }
}
#tc7 .pkg .unit2 h3{
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 100px;
  .margin {
    display: block;
    margin-top: 30px;
  }
}
#tc7 .pkg .unit2 h3 em{
  font-size: 50px;
  font-style: normal;
}
#tc7 .pkg .unit2 h3 em span{
  color: #fe3340;
}
#tc7 .annotation{
  background-color: #fe3340;
  margin-bottom: 100px;
}
#tc7 .annotation .annotation_inner{
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}
#tc7 .annotation .catch{
  background-color: #fcfe36;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  color: #fe3340;
  display: inline-block;
  margin: 0 auto;
  padding: 21px 160px 23px;
  border-radius: 100px;
  margin-top: -46px;
  position: relative;
  line-height: 1.2;
}
#tc7 .pkg2{
  width: 100%;
  max-width: 1016px;
  margin: 80px auto 0;
  padding: 0 50px;
  box-sizing: border-box;
  text-align: center;
  h2 {
    font-size: 36px;
    color: #fe3340;
    margin-bottom: 30px;
    display: inline-block;
    text-align: left;
  }
}
#tc7 .annotation .catch::after{
  content: "";
  position: absolute;
  margin: 0;
  bottom: -30px;
  right: 24%;
  width: 0;
  height: 0;
  border-top: 31px solid #fcfe36;
  border-left: 4px solid transparent;
  border-right: 26px solid transparent;
}
#tc7 .annotation .block{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 0 50px;
}
#tc7 .annotation .block figure{
  width: 34%;
}
#tc7 .annotation .block .txt{
  width: 60%;
  font-size: 39px;
  font-weight: 700;
  line-height: 1.5;
  color: #fff;
  text-align: left;
}
#tc7 .container02 h3 {
  font-size: 65px;
  line-height: 80px;
  text-align: center;
  color: #fe3340;
  margin-bottom: 25px;
}
#tc7 .container02 h3 span {
  font-size: 34px;
  display: block;
  line-height: normal;
}
#tc7 .container02 .f2 {
  width: 88%;
  margin: 0 auto 30px auto;
}
#tc7 .container02 .cover {
  margin-bottom: 50px;
}
#tc7 .container02 .cover figure {
  width: 32%;
}
#tc7 .container02 .p1 {
  font-size: 41px;
  line-height: 60px;
  text-align: center;
  .red {
    color: #fe3340;
  }
}
#tc7 .container02 .p2 {
  font-size: 26px;
  line-height: 60px;
  text-align: center;
  color: #fe3340;
  margin-bottom: 40px;
}
#tc7 .container02 .f3 {
  width: 700px;
  margin: 0 auto;
}
#tc7 .flame {
  .ondoku {
    .unit1 {
      .secondary, .primary {
        text-align: center;
      }
    }
  }
  .hearing {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 60px;
    margin-top: 20px;
    span {
      text-align: center;
    }
    img {
      height: 200px !important;
    }
  }
}
.ondoku {
  padding: 0 50px;
  margin: 0 auto 60px;
  max-width: 1016px;
  margin-top: 100px;
  .ondoku-notice {
    margin-top: 40px;
    color: #fe3340;
  }
  .ondoku-notice1 {
    margin-top: 20px;
    font-size: 16px;
  }
  .unit1 {
    background: url("/top2020/img/bg_pkg.webp") repeat;
    padding: 60px 4.6% 70px;
    border: 4px solid #fe3340;
    .description {
      margin-top: 60px;
    }
    .movie {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      .arrow {
        flex-grow: 0;
        flex-shrink: 0;
        width: 100px;
        height: auto;
        margin: 20px;
        &.pc {
          display: block;
        }
        &.sp {
          display: none;
        }
      }
      a {
        position: relative;
        z-index: 0;
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          background-image: url("/top2020/img/icon_movie.svg");
          background-position: center;
          background-size: 30% 30%;
          background-repeat: no-repeat;
        }
        &:hover {
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
        }
      }
    }
    .look {
      margin-top: 60px;
      background-color: #0a74d7;
      color: white;
      font-weight: 500;
      line-height: 40px;
      height: 40px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 10px;
      text-align: center;
      width: 500px;
    }
    .point {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      p {
        text-align: center;
        font-size: 32px;
        font-weight: 700;
        color: #fe3340;
        margin-left: 30px;
        margin-right: 30px;
        background-color: #fcfe36;
        border-radius: 50%;
      }
    }
  }
}
#tc7 {
  .video-wrapper {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 60px;
    iframe {
      max-width: 100%;
    }
    p {
      margin-top: 10px;
    }
  }
}
@media only screen and (max-width: 768px) {
  #tc7 {
    .video-wrapper {
      width: 100%;
      margin-bottom: 0;
      p {
        font-size: 14px;
      }
    }
  }
  #tc7 {
    padding-bottom: 40px;
    padding-top: 90px;
  }
  #tc7 figure.f1 {
    width: 220px;
  }
  #tc7 .lead {
    font-size: 30px;
    margin-bottom: 10px;
    padding: 0 14px;
  }
  #tc7 .under-lead {
    font-size: 26px;
    margin-bottom: 60px;
    padding: 0 14px;
  }
  #tc7 .over {
    padding: 0 20px;
    font-size: 14px;
    line-height: 24px;
  }
  #tc7 p.num {
    padding: 3px;
    width: 50px;
    height: 50px;
    margin: 0 auto 20px auto;
  }
  #tc7 p.num > span {
    font-size: 19px;
  }
  #tc7 p.num > span .local {
    font-size: 10px;
  }
  #tc7 .flame{
    max-width: none;
    padding: 0 20px;
    margin-bottom: 56px;
    &.unit3 {
      .btn-area {
        display: block;
        text-align: center;
      }
    }
  }
  #tc7 .flame .secondary{
    font-size: 13px;
    margin-bottom: 5px;
  }
  #tc7 .flame .thirdary{
    font-size: 13px;
    margin-bottom: 5px;
  }
  #tc7 .flame .primary{
    font-size: 23px;
    margin-bottom: 5px;
    line-height: 1.4;
  }
  #tc7 .flame .primary .small{
    font-size: 15px;
  }
  #tc7 .flame .title{
    margin-top: 20px;
  }
  #tc7 .flame .txt{
    position: relative;
    width: 100%;
    margin: 16px auto 0;
    background:none;
    padding: 0;
    border-radius: 0;
  }
  #tc7 .flame .txt > p{
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
    &.img-wrapper {
      width: 100%;
      margin-left: 0;
    }
  }
  #tc7 .flame .txt > p:last-of-type{
    margin-bottom: 22px;
  }
  #tc7 .catch {
    p {
      font-size: 18px;
      line-height: 1.4;
      width: calc(100% - 70px);
      &.primary {
        font-size: 23px;
        margin-bottom: 5px;
        line-height: 1.4;
      }
      .large {
        font-size: 19px;
      }
    }
  }
  #tc7 .catch p.speech::before {
    width: 27px;
    height: 67px;
    left: -35px;
    bottom: -5px;
  }
  #tc7 .catch p.speech::after {
    width: 27px;
    height: 67px;
    right: -35px;
    bottom: -5px;
  }
  #tc7 .flame.unit2{
    margin-bottom: 20px;
  }
  #tc7 .flame.unit2 .catch p{
    width: 100%;
  }
  #tc7 .about{
    max-width: none;
    margin: 0 auto 36px;
    padding: 0 20px;
  }
  #tc7 .about .about_inner{
    padding: 30px 20px 30px;
  }
  #tc7 .about .block{
    margin-bottom: 36px;
  }
  #tc7 .about .block > figure{
    margin-bottom: 16px;
  }
  #tc7 .about .block > p{
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 26px;
  }
  #tc7 .about .block_inner{
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  #tc7 .about .block_inner dl{
    width: 100%;
  }
  #tc7 .about .block_inner dt{
    width: 100%;
    margin-bottom: 12px;
  }
  #tc7 .about .block_inner dt img{
    width: 100%;
  }
  #tc7 .about .block_inner dd{
    font-size: 14px;
  }
  #tc7 .about .block_inner .arrow{
    height: 18px;
    width: 26px;
    text-align: center;
    border-top: 18px solid #fe3340;
    border-right: 14px solid transparent;
    border-left: 14px solid transparent;
    margin-top: 18px;
    margin-bottom: 6px;
  }
  #tc7 .koushite {
    padding: 20px 0px 40px;
    margin: 0 auto 0px;
    width: 100%;
    h3 {
      font-size: 18px;
      margin-top: 40px;
      margin-bottom: 40px;
      .large {
        font-size: 19px;
      }
    }
    img {
      &.pc {
        display: none;
      }
      &.sp {
        display: block;
      }
    }
    ul {
      flex-flow: row wrap;
      li {
        width: 140px;
        height: 140px;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
      }
    }
  }
  #tc7 .pkg .unit1 .step {
    padding: 20px 20px 40px;
    width: 100%;
    margin: 0 auto;
    h3 {
      font-size: 23px;
    }
    h4, p {
      font-size: 18px;
    }
  }
  #tc7 .pkg{
    max-width: none;
    margin: 0 auto 70px;
    padding: 0 20px;
  }
  #tc7 .pkg .unit1{
    background: url("/top2020/img/bg_pkg.webp") repeat;
    padding: 30px 4% 30px;
    margin-bottom: 24px;
  }
  #tc7 .pkg .unit1 h3{
    padding: 14px 0;
    margin-bottom: 16px;
    text-align: center;
    font-size: 13px;
    .matome {
      font-size: 30px;
    }
    .sub{
      font-size: 13px;
    }
    .list {
      .list-item {
        .small {
          font-size: 13px;
          top: 0;
        }
      }
    }
  }
  #tc7 .pkg .unit1 h3 em{
    font-size: 18px;
    display: block;
    margin-top: 2px;
  }
  #tc7 .pkg .unit2 > p{
    font-size: 18px;
    margin-bottom: 46px;
    .black {
      font-size: 18px;
      .list {
        font-size: 14px;
      }
    }
  }
  #tc7 .pkg .unit2 h3{
    font-size: 16px;
    margin-bottom: 60px;
    line-height: 1.3;
    letter-spacing: -0.05em;
  }
  #tc7 .pkg .unit2 h3 em{
    font-size: 21px;
    line-height: 1.3;
    display: block;
    margin-top: 4px;
  }
  #tc7 .annotation{
    margin-bottom: 50px;
    background: linear-gradient(180deg, #ffffff 0%, #ffffff 26px, #fe3340 26px, #fe3340 100%);
  }
  #tc7 .annotation .annotation_inner{
    max-width: none;
    padding: 0 20px;
  }
  #tc7 .annotation .catch{
    font-size: 22px;
    padding: 12px 22px 14px;
    margin-top: 0;
  }
  #tc7 .annotation .catch::after{
    bottom: -15px;
    right: 10%;
    border-top: 15px solid #fcfe36;
    border-left: 2px solid transparent;
    border-right: 15px solid transparent;
  }
  #tc7 .annotation .block{
    flex-wrap: wrap;
    padding: 26px 0 30px;
  }
  #tc7 .annotation .block figure{
    width: 75%;
    margin: 0 auto;
    order: 2;
  }
  #tc7 .annotation .block .txt{
    order: 1;
    width: 100%;
    font-size: 22px;
    text-align: center;
    margin-bottom: 16px;
  }
  #tc7 .container02 h3 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 25px;
  }
  #tc7 .container02 h3 span {
    font-size: 17px;
  }
  #tc7 .container02 .f2 {
    width: 100%;
    margin: 0 auto 10px auto;
  }
  #tc7 .container02 .cover {
    margin-bottom: 30px;
  }
  #tc7 .container02 .p1 {
    font-size: 21px;
    line-height: 30px;
    margin-bottom: 8px;
  }
  #tc7 .container02 .p2 {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 25px;
  }
  #tc7 .container02 .f3 {
    width: 100%;
  }
  #tc7 .flame {
    .hearing {
      flex-flow: column nowrap;
      gap: 20px;
      img {
        width: auto;
      }
    }
  }
  .ondoku {
    margin-top: 0;
    padding: 0;
    .unit1 {
      .movie {
        flex-flow: column nowrap;
        .arrow {
          width: auto;
          height: 100px !important;
          &.pc {
            display: none;
          }
          &.sp {
            display: block;
          }
        }
      }
      .look {
        width: auto;
        font-size: 11px;
      }
      .point {
        p {
          font-size: 23px;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }
  #tc7 .pkg2{
    padding: 0 20px;
    h2 {
      font-size: 18px;
    }
  }
}
::v-deep .purchase-button {
  margin-top: 80px;
}
</style>
