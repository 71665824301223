<template>
  <div>
    <Layout>
      <template #header>
        <Header />
      </template>
      <template #contents>
        <section class="column_sp2_pc2 imp_sec">
          <h2 class="imp_h2">英語教材動画に飽きないためには目標設定が重要！英語力を鍛えるには空き時間の有効活用を</h2>
          <ColumnShareButtons v-if="basePath === '/'" :url="`https://jjenglish.co.jp${basePath}column/englishskills-englishteachingmaterials-video`" />
          <div class="imp_subpage_text">
            <div class="u-pb30 l-container">

              <div id="breadcrumbs" itemscope itemtype="http://schema.org/BreadcrumbList">
                <span itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" :href="`${basePath}`"><span itemprop="name">ホーム</span></a><meta itemprop="position" content="1" />
                </span> &raquo;
                <span itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" :href="`${basePath}column/`"><span itemprop="name">コラム一覧</span></a><meta itemprop="position" content="2" />
                </span> &raquo;
                <span itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" :href="`${basePath}column/englishskills-englishteachingmaterials-video`"><span itemprop="name">英語教材動画に飽きないためには目標設定が重要！英語力を鍛えるには空き時間の有効活用を</span></a><meta itemprop="position" content="3" />
                </span>
              </div>

              <div class="l-section">
                <div class="u-mb30 c-block">
                  <p class="u-m0 u-pb1em">
                    英語教材で提供される動画コンテンツは、有効に利用することで英語力の成長につなげられます。空き時間にも対応しやすい動画での学習は、毎日の勉強の機会を作るきっかけになるでしょう。</p>
                  <p class="u-m0 u-pb1em">
                  しかし、動画での継続した学習は意外と難しく、飽きないための対策が求められることもあります。特に英語教材を使って勉強を始めたばかりの初心者は、飽きないで動画を使うコツを把握しておくことがおすすめです。</p>
                  <p class="u-m0 u-pb1em">
                  こちらでは初心者が英語教材から学んでおきたいスキルと、飽きないで動画コンテンツを学習し続けるための目標設定について解説します。これから英語教材を使って本格的な学習をする予定なら、こちらを参考に飽きないで継続するための準備を進めておきましょう。
                  </p>
                </div>

                <h3 class="u-mb20 l-heading-secondary">初心者が英語教材で身につけたいスキルとは？英語力を鍛えるための方向性を確認</h3>
                <div class="u-mb30 c-block">
                  <div class="img-right-s">
                    <img alt="鉛筆と英語で書かれたノート" src="/column/images/pencil-english-note.jpg">
                  </div>
                  <p class="u-m0 u-pb1em">
                    英語教材を使ったことのない初心者は、まず「単語」「簡単な英文の作成方法」「リスニング力」に関する3つのスキルを重点的に学んでいくことがおすすめです。
                  </p>
                  <p class="u-m0 u-pb1em">
                    方向性を定めずに勉強を進めると挫折の危険があるので、勉強に飽きないためにもまずはこの3点から身につけていきましょう。
                  </p>
                  <p class="u-m0 u-pb1em">
                    単語は英語を話すための基本となる情報であり、知識が増えるほどに英語力の成長を実感できるスキルになります。わからない単語が出たら辞書を使って積極的に意味を調べて、自分の中にボキャブラリーを蓄えるようにしましょう。ただ覚えるだけでなく、最終的には会話の中でスムーズに引き出せるようになるまで定着させるのがポイントです。
                  </p>
                  <p class="u-m0 u-pb1em">
                    簡単な英文を作成できるスキルも、初心者が英語教材の動画から学んでおきたい内容です。英文が頭の中で組み立てられるようになれば、それを会話の中で応用することもできます。中学校で習うような簡単なレベルでも十分なので、学習中は意識して英文をイメージするようにしましょう。
                  </p>
                  <p class="u-m0 u-pb1em">
                    相手の話す英語を聴きとる能力を高めるためにも、リスニングのスキルを身につけることは重要です。会話のキャッチボールを成立させるには、相手の言葉を正確に把握する必要があります。飽きないで動画の視聴を繰り返すことが、耳を英語に慣らすように鍛えるきっかけになるでしょう。
                  </p>
                </div>

                <h3 class="u-mb20 l-heading-secondary">飽きないで動画を見るためには目標設定が重要！空き時間を有効活用する方法も</h3>
                <div class="u-mb30 c-block">
                  <div class="img-right-s">
                    <img alt="ノートパソコンとスマホとヘッドホン" src="/column/images/note-computer-head-phone.jpg">
                  </div>
                  <p class="u-m0 u-pb1em">
                    飽きないで英語教材の動画を見続けるには、はっきりとした目標設定が重要となります。ただ惰性で動画を視聴するのではなく、明確な目標を持って利用することで継続した学習がしやすくなるでしょう。</p>
                  <p class="u-m0 u-pb1em">
                    例えば「TOEICで〇〇点以上を取る」「英検を取得する」「仕事の中で英会話を活用する」といった内容が目標として設定できます。目標を決めたら期限も設定し、スケジュールを立てるのもコツです。ただ目標を定めただけで満足しないように、きちんと達成のための計画を考えましょう。
                  </p>
                  <p class="u-m0 u-pb1em">
                    また、英語教材による動画学習を飽きないで続けるためには、短期的な視点と長期的な視点の2つで目標を設定することをおすすめします。短期的な小目標を積み重ねて、長期的な大きい目標を達成できるように行動していけば、英語力をスムーズに高めていけるでしょう。
                  </p>
                  <p class="u-m0 u-pb1em">
                    英語学習における目標設定は飽きないで動画を見続けるためだけでなく、空き時間を有効利用することにもつながります。目標が定まっていると「この空き時間は目標達成のために使えるな」という発見につながるでしょう。目標に向かうための過程において空き時間は重要な要素になるので、柔軟に利用するのがポイントです。
                  </p>
                </div>

                <h3 class="u-mb20 l-heading-secondary">英語教材を上手に活用して飽きないで長期間継続できる学習環境を作ろう！</h3>
                <div class="u-mb30 c-block">
                  <p class="u-m0 u-pb1em">
                    英語教材の動画を上手に利用するためには、飽きないで長期間使い続けられるように工夫することが必要です。まずは初心者が学ぶべきスキルを確認し、そのうえで飽きないための目標を決めていきましょう。
                  </p>
                  <p class="u-m0 u-pb1em">
                    わかりやすい授業で大人気の東進ハイスクールのカリスマ講師・安河内哲也先生らが監修したJJ ENGLISHエクササイズであれば、魅力的な英語教材によって初心者からの学習をサポートします。英語力を本格的に高めたい気持ちがあるのなら、この機会に飽きないレベルのクオリティを持つ動画を体験してみてください。
                  </p>
                </div>
              </div>

              <ColumnShareButtons v-if="basePath === '/'" :url="`https://jjenglish.co.jp${basePath}column/englishskills-englishteachingmaterials-video`" />

              <div class="c-btn u-pt20">
                <a :href="`${basePath}trial`" target="_blank" rel="noopener" class="c-btn-anchor">サンプル視聴はこちら</a>
              </div>

              <ColumnLinkBlock class="u-mt50" />
              <ColumnAboout title="英語教材の動画で飽きない勉強方法のご相談はJJ ENGLISHエクササイズへ" />
            </div>
          </div>
        </section>
      </template>
      <template #footer>
        <Footer />
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from '@/components/Layout.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ColumnShareButtons from '@/components/ColumnShareButtons.vue'
import ColumnLinkBlock from '@/components/ColumnLinkBlock.vue'
import ColumnAboout from '@/components/ColumnAboout.vue'

export default {
  name: 'Column08',
  components: {
    Layout,
    Header,
    Footer,
    ColumnShareButtons,
    ColumnLinkBlock,
    ColumnAboout,
  },
  computed: {
    basePath() {
      return this.$store.state.basePath
    }
  },
  head: {
    script: [
      {
        type: 'text/javascript',
        inner: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5HP4P8JK');
        `,
      }
    ],
  },
}
</script>
