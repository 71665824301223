<template>
  <div>
    <Layout>
      <template #header>
        <Header />
      </template>
      <template #contents>
        <section class="column_sp2_pc2 imp_sec">
          <h2 class="imp_h2">コラム一覧</h2>
          <ColumnShareButtons v-if="basePath === '/'" :url="`https://jjenglish.co.jp${basePath}column/`" />
          <div class="imp_subpage_text">
            <div class="u-pb30 l-container">
              <div id="breadcrumbs" itemscope itemtype="http://schema.org/BreadcrumbList">
                <span itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" :href="`${basePath}`"><span itemprop="name">ホーム</span></a><meta itemprop="position" content="1" />
                </span> &raquo;
                <span itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" :href="`${basePath}column/`"><span itemprop="name">コラム一覧</span></a><meta itemprop="position" content="2" />
                </span>
              </div>
              <ColumnLinkBlock />
              <ColumnAboout title="独学で英会話の学習方法のことならJJ ENGLISHエクササイズへ" />
            </div>
          </div>
        </section>
      </template>
      <template #footer>
        <Footer />
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from '@/components/Layout.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ColumnShareButtons from '@/components/ColumnShareButtons.vue'
import ColumnLinkBlock from '@/components/ColumnLinkBlock.vue'
import ColumnAboout from '@/components/ColumnAboout.vue'

export default {
  name: 'ColumnIndex',
  components: {
    Layout,
    Header,
    Footer,
    ColumnShareButtons,
    ColumnLinkBlock,
    ColumnAboout,
  },
  computed: {
    basePath() {
      return this.$store.state.basePath
    }
  },
  head: {
    script: [
      {
        type: 'text/javascript',
        inner: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5HP4P8JK');
        `,
      }
    ],
  },
}
</script>
