<template>
  <div class="pkg2">
    <div class="unit1">
      <div class="unit1_inner">
        <h2>
          「着実に話せるようになる」という品質は<br>
          結局、優れたコストパフォーマンスにつながります！
        </h2>
        <figure><img v-lazy="'/top2020/img/comparison_table.webp'"></figure>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductComparisonTable',
}
</script>

<style scoped lang="scss">
.pkg2{
  width: 100%;
  max-width: 1016px;
  margin: 80px auto 0;
  padding: 0 50px;
  box-sizing: border-box;
  text-align: center;
  h2 {
    font-size: 36px;
    color: #fe3340;
    margin-bottom: 30px;
    display: inline-block;
    text-align: left;
  }
}
@media only screen and (max-width: 768px) {
  .pkg2 {
    padding: 0 20px;
    h2 {
      font-size: 18px;
    }
  }
}
</style>
