<template>
  <div>
    <Layout>
      <template #header>
        <Header />
      </template>
      <template #contents>
        <section class="column_sp2_pc2 imp_sec">
          <h2 class="imp_h2">会社概要</h2>
          <table>
            <tr class="imp_tr_line">
              <td class="title">社名</td>
              <td>株式会社JJイングリッシュ社</td>
            </tr>
            <tr>
              <td class="title">所在地</td>
              <td>〒141-0022 東京都品川区東五反田5-19-2（本社）</td>
            </tr>
            <tr>
              <td class="title">設立</td>
              <td>2019年3月8日</td>
            </tr>
            <tr>
              <td class="title">事業内容</td>
              <td>独習型英会話教材「JJENGLISHエクササイズ」の制作及び販売</td>
            </tr>
            <tr>
              <td class="title">代表者</td>
              <td>秋藤幸司</td>
            </tr>
            <tr>
              <td class="title">取引銀行</td>
              <td>みずほ銀行 きらぼし銀行</td>
            </tr>
            <tr>
              <td class="title">業務提携会社</td>
              <td>
                <ul id="companies">
                  <li>株式会社講談社</li>
                  <li>安河内哲也オフィス</li>
                  <li>一般財団法人実用英語推進機構</li>
                  <li>株式会社エイジャ</li>
                  <li>株式会社ダイナワード</li>
                  <li>株式会社 11aside</li>
                  <li>ジパングマネジメント株式会社</li>
                  <li>佐川印刷株式会社</li>
                  <li>楽天株式会社</li>
                  <li>アマゾンジャパン合同会社</li>
                </ul>
              </td>
            </tr>
          </table>
        </section>
      </template>
      <template #footer>
        <Footer />
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from '@/components/Layout.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Company',
  components: {
    Layout,
    Header,
    Footer,
  },
  head: {
    script: [
      {
        type: 'text/javascript',
        inner: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5HP4P8JK');
        `,
      }
    ],
  },
}
</script>

<style scoped lang="scss">
.column_sp2_pc2 .title {
  width: 16%;
  padding-right: 10px;
}
section.imp_sec table td {
  padding: 18px 0;
}
#companies {
  li {
    display: inline-block;
    margin-right: 1em;
  }
}
</style>
