<template>
  <section id="tc16">
    <!-- <div class="container04 inner01">
      <div class="wrapper">
        <p class="hit">大ヒット上映中！</p>
        <p class="subtitle">松竹映画100周年記念作品</p>
        <p class="title">キネマの神様</p>
        <p class="description">
          出演……沢田研二／菅田将暉 他<br>
          監督……山田洋次
        </p>
        <p class="red">お近くの劇場でご鑑賞ください！</p>
      </div>
      <p class="image">
        <img v-lazy="'/top2020/img/kinema_god.webp'" /><br>
        ©2021「キネマの神様」製作委員会
      </p>
    </div> -->
    <div class="container02 lazyload inner01">
      <h3>独学で英会話力が身につく教材をお探しなら</h3>
      <p class="inner02 txt">独学で英会話を勉強したいという方は、JJ ENGLISHエクササイズの教材をお試しください。「テキスト」「動画」「音声」で学べる教材を販売しています。目と耳と口を動かすエクササイズのため、英語をしっかりと身につけることができます。教えるプロの先生方監修の教材で、中学英語をベースに楽しく英会話を学べるのが特徴です。英会話教室との併用もおすすめですので、ぜひお申し込みください。教材の詳細に関する問い合わせもお待ちしています。</p>
      <p class="btn"><a :href="`${basePath}column/`">コラム一覧 ＞</a></p>
    </div>
    <div class="container03 inner01">
      <h3>ＪＪイングリッシュ社の起業ビジョン</h3>
      <p class="inner02 txt">
        日本にいながらにして世界共通語である英語が話せるようになる。そのための適切な学習方法と機会を、それらを望み必要とする人たちに向けて、手軽な形で廉価に提供していく。これが私たちのミッションです。<br>
        <br>
        このミッションの実現により、1人でも多くのみなさまの人生に、喜び、感動、充実、飛躍をもたらす。それが私たちのかけがえのない喜びとなります。<br>
        <br>
        このミッションの実現により、日本社会が、どこにでも英語を話せる人が普通にいる社会となり、文化・歴史・価値観を異にする諸外国との交流が多次元的に活発化し深まっていく。日本人の心が世界に伝わっていく。そのことがわが国や世界の豊かさにつながっていく。そんな未来が私たちの果てしない夢となります。
      </p>
    </div>
    <p class="btm">
      株式会社ＪＪイングリッシュ社は、株式会社講談社のライセンシー社です。<br>
      <br>
      「JJ ENGLISHエクササイズ」は<br class="sp_only">株式会社ＪＪイングリッシュ社の登録商標です。
    </p>
  </section>
</template>

<script>
export default {
  name: 'Tc16',
  computed: {
    basePath() {
      return this.$store.state.basePath
    }
  }
}
</script>

<style scoped lang="scss">
#tc16 .container02 {
  padding: 60px;
  &.lazyloaded {
    background-image: url("/top2020/img/tc13_bg2.webp");
  }
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  margin-bottom: 60px;
}
#tc16 .container02 h3 {
  border: 1px solid #fff;
  color: #fff;
  border-radius: 30px;
  font-size: 22px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto 20px auto;
  font-weight: 400;
  background-color: #fe3340;
}
#tc16 .container02 p.txt {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 50px;
}
#tc16 .container02 p.btn a {
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #fe3340;
  color: #fe3340;
  border-radius: 30px;
  font-size: 18px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto 0px auto;
  font-weight: 400;
  background-color: #fff;
  width: 320px;
}
#tc16 .container03 {
  padding: 60px;
  margin-bottom: 60px;
}
#tc16 .container03 h3 {
  border: 1px solid #fff;
  color: #fff;
  border-radius: 30px;
  font-size: 22px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto 20px auto;
  font-weight: 400;
  background-color: #fe3340;
}
#tc16 .container03 p.txt {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 50px;
  padding: 0;
}
#tc16 .btm {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 40px;
}
#tc16 .container04 {
  display: flex;
  justify-content: center;
  flex-flow: row-reverse nowrap;
  text-align: center;
  background-color: rgb(220, 220, 220);
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 100px;
  margin-bottom: 120px;
  p {
    line-height: 1.6em;
  }
  .wrapper {
    padding-left: 20px;
    padding-right: 40px;
    background-color: transparent;
    width: 50%;
    .hit {
      font-size: 34px;
      color: #fe3340;
      font-weight: bold;
      text-align: center;
    }
    .subtitle {
      font-size: 20px;
      text-align: center;
    }
    .title {
      font-size: 54px;
      text-align: center;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .description {
      font-size: 20px;
      text-align: left;
      display: inline-block;
      margin-bottom: 20px;
    }
    .red {
      font-size: 20px;
      text-align: center;
      color: #fe3340;
    }
  }
  .image {
    padding-left: 40px;
    padding-right: 20px;
    text-align: center;
    width: 50%;
    font-size: 16px;
    text-align: left;
  }
}
@media only screen and (max-width: 768px) {
  #tc16 .container02 {
    padding: 30px 20px;
  }
  #tc16 .container02 h3 {
    font-size: 15px;
    height: 60px;
    margin: 0 auto 20px auto;
  }
  #tc16 .container02 p.txt {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 50px;
    padding: 0;
  }
  #tc16 .container03 {
    padding: 30px 20px;
  }
  #tc16 .container03 h3 {
    font-size: 15px;
    height: 60px;
    margin: 0 auto 20px auto;
  }
  #tc16 .container03 p.txt {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 50px;
    padding: 0;
  }
  #tc16 .btm {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  #tc16 .container04 {
    display: block;
    .wrapper {
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
      .hit {
        font-size: 24px;
      }
      .subtitle {
        font-size: 16px;
      }
      .title {
        font-size: 36px;
      }
      .description {
        font-size: 16px;
      }
      .red {
        font-size: 16px;
        margin-bottom: 30px;
      }
    }
    .image {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 12px;
    }
  }
}
</style>
