<template>
  <section id="tc6" class="lazyload">
    <div class="inner01">
      <p class="p1 inner02">
        <span>
          JJ ENGLISHエクササイズを２〜10カ月（平均6カ月）実践して、<br>
          英語が話せるようになった人の割合
        </span>
      </p>
      <p class="sub">※自社・講談社モニタリング結果。詳しくはQ&A欄参照</p>
      <p class="p2 inner02"><span>5</span>人中<span>4</span>人 以上</p>
      <figure class="f1">
        <img v-lazy="'/top2020/img/tc6_01.webp'" alt="">
        <img class="youcan" v-lazy="'/top2020/img/you_can.webp'" alt="">
      </figure>
      <p class="p4">
        <span class="yellow">JJ ENGLISHエクササイズのメソッドは独自ではあっても奇抜なものではありません。</span>４人の監修者を始めとして、教材開発の協力者ーー日本に居ながらにして英語をものにした78人のほとんど全員がたどり着いていた、ほぼ同じ考え方と学習法をパッケージ化したにすぎないのです。<br>
        <br>
        そうなのです。つまり、この教材のメソッドは、「王道中の王道」と言っていい学習法。<br>
        <span class="yellow">王道だからこそ確実性があり、「失敗の確率はとても低い」と自信をもって言えるのです。</span><br><br>
        <br>
        確実性とともに追求したのは「楽しさ」です。<br>
        テキストの内容、レクチャー動画の演出も、<span class="yellow">「読むだけで楽しい」「見るだけで楽しい」</span>と、実践者の多くからうれしい評価をいただいています。
      </p>
      <div class="p5">
        <p>
          王道だから「確か」。そして「楽しい」
        </p>
      </div>
      <!-- <div class="bukuro flexb">
        <figure>
          <img v-lazy="'/top2020/img/tc6_02.webp'" alt="">
          <img class="badge" v-lazy="'/top2020/img/bukuro_badge.webp'">
        </figure>
        <div class="txt">
          <h3>ジュンク堂書店池袋本店の<br>
            語学書フロアでも販売中です！</h3>
          <p>ネットでのみ売られている英会話教材が大半を占めるなか、JJ ENGLISHエクササイズは書店でも販売されています。大手書店チェーンを展開するジュンク堂書店の池袋本店の語学書フロアでは、実物を手に取ってご覧いただけます。</p>
        </div>
      </div> -->
      <slot name="bt_purchase" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'Tc6',
}
</script>

<style scoped lang="scss">
#tc6 {
  &.lazyloaded {
    background-image: url("/top2020/img/tc6_bg.webp");
  }
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: bottom;
  padding-top: 100px;
  padding-bottom: 100px;
  /*position: relative;*/
  /*z-index: 0;*/
  margin-top: -100px;
}
#tc6 .p1 {
  width: 100%;
  border: 2px solid #fff;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  &+ .sub {
    font-size: 16px;
    color: #fff;
    margin-bottom: 20px;
    text-align: center;
  }
}
#tc6 .p2 {
  font-size: 90px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background: linear-gradient(transparent 88%, #fcfe36 12%);
  margin-bottom: 70px;
  padding-bottom: 15px;
  max-width: 820px;
}
#tc6 .p2 span {
  font-size: 170px;
}
#tc6 .p3 {
  font-size: 68px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}
#tc6 .p3 span {
  font-size: 120px;
}
#tc6 .inner01 > figure {
  margin-bottom: 50px;
  text-align: center;
  img {
    width: 80%;
  }
}
#tc6 .p4 {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 50px;
	padding: 0 150px;
}
#tc6 .p4 .yellow {
  color: #f7f954;
}
#tc6 .p5 {
  margin-bottom: 60px;
  text-align: center;
}
#tc6 .p5 p {
  position: relative;
  display: inline-block;
  font-size: 48px;
  line-height: 1.3em;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
}
#tc6 .bukuro {
  padding: 30px;
  margin-bottom: 60px;
  border: 1px solid #fff;
  border-radius: 10px;
  width: calc(100% - 160px);
	margin:0 auto 60px auto;
}
#tc6 .bukuro figure {
  width: 180px;
  position: relative;
  .badge {
    position: absolute;
    width: 60%;
    height: 60%;
    top: -30%;
    right: -30%;
  }
}
#tc6 .bukuro .txt {
  width: 520px;
}
#tc6 .bukuro .txt h3 {
  font-size: 30px;
  line-height: 45px;
  color: #f7f954;
  margin-bottom: 15px;
}
#tc6 .bukuro .txt p {
  font-size: 18px;
  line-height: 28px;
  color: #fff;
}
#tc6 .f1 {
  position: relative;
}
#tc6 .f1 .youcan {
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
}
@media only screen and (max-width: 768px) {
  #tc6 {
    padding-top: 150px;
    padding-bottom: 80px;
  }
  #tc6 .p1 {
    font-size: 14px;
    height: auto;
  }
  #tc6 .p2 {
    font-size: 45px;
    padding: 0;
  }
  #tc6 .p2 span {
    font-size: 65px;
  }
  #tc6 .p3 {
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 15px;
    padding-bottom: 5px;
    padding: 0 0 5px 0;
  }
  #tc6 .p3 span {
    font-size: 58px;
  }
  #tc6 .inner01 > figure {
    margin-bottom: 20px;
  }
  #tc6 .p4 {
    font-size: 14px;
    line-height: 24px;
    padding: 0;
  }
  #tc6 .p5 p {
    font-size: 24px;
  }
	#tc6 .bukuro{
		width:100%;
	}
  #tc6 .bukuro figure {
    width: 85px;
    margin: 0 auto 10px auto;
  }
  #tc6 .bukuro .txt h3 {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 10px;
    text-align: center;
  }
  #tc6 .bukuro .txt p {
    font-size: 14px;
    line-height: 24px;
  }
}
</style>
