<template>
  <div class="u-mt50 c-nap">
    <h3 class="u-mb20">{{ title }}</h3>
    <table>
      <tbody>
        <tr class="imp_tr_line">
          <th class="title">社名</th>
          <td>株式会社JJイングリッシュ社</td>
        </tr>
        <tr>
          <th class="title">代表者</th>
          <td>秋藤幸司</td>
        </tr>
        <tr>
          <th class="title">住所</th>
          <td>
            <ul>
              <li>〒141-0022 東京都品川区東五反田5丁目19−2（本社）</li>
            </ul>
          </td>
        </tr>
        <tr>
          <th class="title">電話番号</th>
          <td><a href="tel:0120197917">0120-197917</a></td>
        </tr>
        <tr>
          <th class="title">メールアドレス</th>
          <td><a href="mailto:info@jjenglish.co.jp">info@jjenglish.co.jp</a></td>
        </tr>
        <tr>
          <th class="title">URL</th>
          <td><a href="https://jjenglish.co.jp/">https://jjenglish.co.jp/</a></td>
        </tr>
        <tr>
          <th class="title">設立</th>
          <td>2019年3月8日</td>
        </tr>
        <tr>
          <th class="title">事業内容</th>
          <td>独習型英会話教材「JJENGLISHエクササイズ」の制作及び販売</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ColumnAbout',
  props: {
    title: {
      type: String,
      required: true,
    }
  }
}
</script>
