<template>
  <div>
    <Layout>
      <template #header>
        <Header />
      </template>
      <template #contents>
        <section class="column_sp2_pc2 imp_sec">
          <h2 class="imp_h2">英語学習は手元ツールを使って効率アップ！スマホ対応教材をダウンロードしよう</h2>
          <ColumnShareButtons v-if="basePath === '/'" :url="`https://jjenglish.co.jp${basePath}column/download-englishlearning-handtool`" />
          <div class="imp_subpage_text">
            <div class="u-pb30 l-container">

              <div id="breadcrumbs" itemscope itemtype="http://schema.org/BreadcrumbList">
                <span itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" :href="`${basePath}`"><span itemprop="name">ホーム</span></a><meta itemprop="position" content="1" />
                </span> &raquo;
                <span itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" :href="`${basePath}column/`"><span itemprop="name">コラム一覧</span></a><meta itemprop="position" content="2" />
                </span> &raquo;
                <span itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" :href="`${basePath}column/download-englishlearning-handtool`"><span itemprop="name">英語学習は手元ツールを使って効率アップ！スマホ対応教材をダウンロードしよう</span></a><meta itemprop="position" content="3" />
                </span>
              </div>

              <div class="l-section">
                <div class="u-mb30 c-block">
                  <p class="u-m0 u-pb1em">
                    英語学習を行う際には、効率の良い学習スタイルを見つけることがポイントになります。限られた時間の中で成果を出すためにも、効率化については常に考えておく必要があるでしょう。英語学習の効率化においておすすめされるのが、手元ツールの活用です。</p>
                  <p class="u-m0 u-pb1em">
                  スマホやタブレットに英語学習の専用動画をダウンロードしておけば、勉強の機会を劇的に増やすことができるでしょう。手元ツールを活用できれば、日常の何もしていない時間を英語学習のために使うことができます。隙間時間の利用はさらなる効率化を進め、英語能力を身につけるステップアップにつながるでしょう。</p>
                  <p class="u-m0 u-pb1em">
                  こちらでは、英語学習の効率アップを目指す際の手元ツールの使い方と、スマホ対応教材を勉強に応用するメリットを紹介します。英語学習の効率化を目指すなら、この機会に手元ツールに注目しましょう。
                  </p>
                </div>

                <h3 class="u-mb20 l-heading-secondary">英語学習の肝は「効率アップ」！時間を有効活用できる手元ツールを使おう</h3>
                <div class="u-mb30 c-block">
                  <div class="img-right-s">
                    <img alt="パソコンとヘッドホンとスマホ" src="/column/images/computer-head-phone-smart-phone.jpg">
                  </div>
                  <p class="u-m0 u-pb1em">
                    英語学習の効率をアップさせるには、簡単に使える手元ツールの利用が欠かせません。
                  </p>
                  <p class="u-m0 u-pb1em">
                    スマホをはじめとした手元ツールを使えば、耳だけでその内容を学ぶことができるので、別の作業をしつつ英語学習を進められるのです。耳が暇をしている時間を手元ツールで有効活用することが、効率アップの基本になるでしょう。
                  </p>
                  <p class="u-m0 u-pb1em">
                    日常の中には、耳だけが何もしていない時間がたくさんあります。作業中は手や目はふさがってしまうものですが、耳は意外とフリーになっていることが多く、たくさんの人が持て余しているのです。手元ツールを使えば、そんな持て余している耳を利用して英語学習を行えます。
                  </p>
                  <p class="u-m0 u-pb1em">
                    スマホ対応の英会話動画などを流しておけば、耳から英語を学ぶことが可能です。本来は別のことをしているはずの時間を、同時に耳を使った英語学習の時間に変えることができます。
                  </p>
                  <p class="u-m0 u-pb1em">
                    例えば「通勤時間」「家事の作業中」「入浴中」「洗濯・掃除の時間」などは、耳がフリーな時間だと言えます。これらのタイミングで英語関連の動画を流せば、それだけ効率アップを進めることができるのです。 英語学習の効率アップを目指すときには、まず自分の生活の中で耳を自由に使える時間がどれくらいあるのかを調べてみましょう。
                  </p>
                </div>

                <h3 class="u-mb20 l-heading-secondary">スマホ対応の教材で英語学習を行うメリットとは？動画をダウンロードして環境を整えよう</h3>
                <div class="u-mb30 c-block">
                  <div class="img-right-s">
                    <img alt="スマホを持つ手" src="/column/images/smart-phone-hand.jpg">
                  </div>
                  <p class="u-m0 u-pb1em">
                    スマホ対応の動画で英語学習を行うことには、たくさんのメリットがあります。テキストや書籍では実現できない点が数多くあるので、英語の勉強を本格的に進めるのなら手元ツールで動画をみる習慣は重要なものになるでしょう。
                  </p>
                  <p class="u-m0 u-pb1em">
                    スマホ対応の動画で学習を行うメリットは、「場所を選ばずに勉強できる」「授業を受けている感覚で学べる」「視覚や聴覚に印象付けられるので覚えやすい」といったものが主になります。
                  </p>
                  <p class="u-m0 u-pb1em">
                    スマホのように持ち運びやすい手元ツールに動画をダウンロードしておけば、場所を選ばずに学習を開始できます。お風呂の中、キッチン、ベッドの中など、本来は別のことをする時間も英語学習に利用できるのです。
                  </p>
                  <p class="u-m0 u-pb1em">
                    講師がこちらに解説するような動画であれば、授業を受けるような感覚で英語学習を進められます。相手の話を聞くスタイルは集中しやすいため、英語学習への意欲が高められるでしょう。動画をみたり聴いたりする勉強方法になるので、視覚や聴覚から具体的な内容を吸収することができます。映像や音を知識と直接結びつけることもできるため、より効率良く学習内容覚えることも可能です。
                  </p>
                  <p class="u-m0 u-pb1em">
                    このように動画を使うことで、英語学習にたくさんのメリットを与えられます。英語教材を選ぶときには、スマホ対応の教材を優先するといいでしょう。
                  </p>
                </div>

                <h3 class="u-mb20 l-heading-secondary">スマホ対応の教材をお探しならJJ ENGLISHエクササイズを 手元ツールで英語学習が可能</h3>
                <div class="u-mb30 c-block">
                  <p class="u-m0 u-pb1em">
                    JJ ENGLISHエクササイズはスマホ対応の動画を、MP4やMP3ファイルでダウンロードすることができます。手元ツールに移行したうえで学習が進められるので、勉強の効率アップにつなげることができるでしょう。
                  </p>
                  <p class="u-m0 u-pb1em">
                    動画MP4ファイル全311本（約25.6時間）、音声MP3ファイル全281本（約8.6時間）、テキスト4冊（全608ページ）＋ウェルカムブック（マニュアル）40ページと、圧倒的ボリュームが特徴です。目・耳・口を動かすエクササイズ主義を基本メソッドとしており、中学英語がベースなので初心者でも学びやすいでしょう。
                  </p>
                  <p class="u-m0 u-pb1em">
                    動画を使っての英語学習に興味があるのなら、JJ ENGLISHエクササイズをぜひお試しください。
                  </p>
                </div>
              </div>

              <ColumnShareButtons v-if="basePath === '/'" :url="`https://jjenglish.co.jp${basePath}column/download-englishlearning-handtool`" />

              <div class="c-btn u-pt20">
                <a :href="`${basePath}trial`" target="_blank" rel="noopener" class="c-btn-anchor">サンプル視聴はこちら</a>
              </div>

              <ColumnLinkBlock class="u-mt50" />
              <ColumnAboout title="英語学習で手元ツールの活用で勉強の効率アップを図りたいとお考えの方はJJ ENGLISHエクササイズへ" />
            </div>
          </div>
        </section>
      </template>
      <template #footer>
        <Footer />
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from '@/components/Layout.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ColumnShareButtons from '@/components/ColumnShareButtons.vue'
import ColumnLinkBlock from '@/components/ColumnLinkBlock.vue'
import ColumnAboout from '@/components/ColumnAboout.vue'

export default {
  name: 'Column06',
  components: {
    Layout,
    Header,
    Footer,
    ColumnShareButtons,
    ColumnLinkBlock,
    ColumnAboout,
  },
  computed: {
    basePath() {
      return this.$store.state.basePath
    }
  },
  head: {
    script: [
      {
        type: 'text/javascript',
        inner: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5HP4P8JK');
        `,
      }
    ],
  },
}
</script>
