<template>
  <section id="tc7">
    <div class="pkg">
      <div class="unit1">
        <p class="best-10">
          <img v-lazy="'/top2020/img/best_10.webp?date=20230725'" /><br>
          <br>
          ※購入者の主観的感想をアンケート集計したものです。
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Best10',
}
</script>

<style scoped lang="scss">
#tc7 .pkg {
  width: 100%;
  max-width: 1016px;
  margin: 0 auto 0;
  padding: 0 50px;
  box-sizing: border-box;
  .blue {
    color: #0a74d7;
  }
}
#tc7 .pkg .unit1{
  background: url("/top2020/img/bg_pkg.webp") repeat;
  padding: 60px 4.6% 70px;
  &.no-bg {
    background: none;
    padding: 0 4.6% 0;
    margin-bottom: 0;
  }
  .normal {
    margin-top: 60px;
    text-align: center;
    font-size: 20px;
    color: black;
  }
  .best-10 {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 768px) {
  #tc7 .pkg{
    max-width: none;
    margin: 0 auto 70px;
    padding: 0 20px;
  }
  #tc7 .pkg .unit1{
    background: url("/top2020/img/bg_pkg.webp") repeat;
    padding: 30px 4% 30px;
  }
}
</style>
