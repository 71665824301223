<template>
  <header class="flexb"> <a class="logo">
    <p>本気で話したいなら！</p>
    <h1><img src="/top2020/img/logo01.webp" alt=""></h1>
    </a>
    <!-- <div class="tell">
      <div class="flex">
        <figure><img src="/top2020/img/freedial.webp" alt=""></figure>
        <p>0120-197-917</p>
      </div>
      <p>10:00-18:00(土日祝休）</p>
    </div> -->
    <figure class="h_cv"><a href="#" class="bt_purchase cf" @click.prevent="onClick"><img src="/top2020/img/sp_cv.webp" alt=""></a></figure>
  </header>
</template>

<script>
export default {
  name: 'TopHeader',
  methods: {
    onClick() {
      // const form = document.querySelector('.cartjs_box form')
      // form.submit()
      location.href = this.$store.state.shopUrl
    }
  }
}
</script>

<style scoped lang="scss">
header {
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 110px;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  align-items: center;
  padding: 0 2%;
  box-sizing: border-box;
  z-index: 10;
}
header .logo {
  width: 220px;
}
header .logo p {
  color: #ef4054;
  font-size: 16px;
}
header .tell {
  width: 240px;
  margin: 0 10px 0 auto;
}
header .tell .flex {
  align-items: center;
}
header .tell .flex figure {
  margin-right: 5px
}
header .tell .flex p {
  font-size: 30px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}
header .tell > p {
  font-size: 12px;
  margin-left: 50px;
}
header .h_cv {
  width: 260px;
}
header .h_cv a {
  transition: ease 0.3s;
}
header .h_cv a:hover {
  opacity: 0.8;
}
@media only screen and (max-width: 768px) {
  header {
    height: 55px;
  }
  header .logo p {
    font-size: 10px;
  }
  header .logo {
    width: 120px;
  }
  header .tell {
    display: none;
  }
  header .h_cv {
    width: 160px;
  }
}
</style>
