<template>
  <section id="tc11">
    <div class="container lazyload inner01">
      <figure class="over"><img v-lazy="'/top2020/img/tc11_01.webp'" alt=""></figure>
      <h2><span>JJ ENGLISH エクササイズは</span>3人のネイティブが<br>
        英文とナレーションを担当</h2>
      <p class="under">日本と日本人を熟知した「言葉のプロ」</p>
      <div class="flexb box_cover">
        <div class="box">
          <figure class="f1"><img v-lazy="'/top2020/img/tc11_02.webp'" alt=""></figure>
          <figure class="f2"><img v-lazy="'/top2020/img/ntf_401.webp'" alt=""></figure>
          <h3>Jamie Reed</h3>
          <p>米国ニュージャージー州ハケッツタウン出身。ペンシルバニア州立イースト・ストラスバーグ大学卒。2006年に来日。英語教師、雑誌記事の英文翻訳者として活躍中。ペインティング作品の個展を開催するなどアーティストとしても活動。（英文作成／ナレーションを担当）</p>
        </div>
        <div class="box">
          <figure class="f1"><img v-lazy="'/top2020/img/tc11_03.webp'" alt=""></figure>
          <figure class="f2"><img v-lazy="'/top2020/img/ntf_401.webp'" alt=""></figure>
          <h3>Robert Mullen</h3>
          <p>米国マサチューセッツ州ボストン市出身。サフォーク大学および同大学ロースクール卒。歴史、哲学を専攻。マサチューセッツ大学院にて応用言語学修士号取得。2007年に来日。高校英語教師として活躍中。（英文作成補佐／文法監修／ナレーションを担当）</p>
        </div>
        <div class="box">
          <figure class="f1"><img v-lazy="'/top2020/img/tc11_04.webp'" alt=""></figure>
          <figure class="f2"><img v-lazy="'/top2020/img/ntf_601.webp'" alt=""></figure>
          <h3>Magnus Sullivan</h3>
          <p>オーストラリア南オーストラリア州アデレード市出身。南オーストラリア州立アデレード大学卒。2006年に日本の公立中学校の英語教員として来日。現在はスポーツ関連の取材記者として海外へ記事を配信するほか、英文翻訳者としても活躍中。（英文作成補佐／ナレーションを担当）</p>
        </div>
      </div>
      <p class="kome">※教材では紹介した３名の他にも、ナレーションなどでアメリカ、イギリスなど多くのネイティブの方に協力をいただきました</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Tc11',
}
</script>

<style scoped lang="scss">
#tc11 {
  padding-top: 300px;
  padding-bottom: 140px;
}
#tc11 .container {
  &.lazyloaded {
    background-image: url("/top2020/img/tc11_bg.webp");
  }
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  padding: 0 85px 100px 85px;
}
#tc11 .container figure.over {
  width: 524px;
  height: 100px;
  margin: 0px auto 50px auto;
  position: relative
}
#tc11 .container figure.over img {
  position: absolute;
  bottom: 0;
}
#tc11 .container h2 {
  font-size: 42px;
  line-height: 58px;
  font-weight: 700;
  color: #fe3340;
  text-align: center;
  margin-bottom: 20px;
}
#tc11 .container .under {
  font-weight: 700;
  margin-bottom: 60px;
}
#tc11 .container h2 span {
  font-size: 20px;
  display: block;
}
#tc11 .container .box_cover {
  margin-bottom: 50px;
}
#tc11 .container .box {
  width: 30%;
}
#tc11 .container .box .f1 {
  width: 240px;
  margin: 0 auto 30px auto;
}
#tc11 .container .box .f2 {
  width: 40px;
  margin: 0 0 10px 0;
}
#tc11 .container .box h3 {
  font-size: 22px;
  line-height: 32px;
  color: #fe3340;
  margin-bottom: 20px;
}
#tc11 .container .box p {
  font-size: 16px;
  line-height: 24px;
}
#tc11 .container p.kome {
  font-size: 16px;
  line-height: 24px;
}
@media only screen and (max-width: 768px) {
  #tc11 {
    padding-top: 150px;
    padding-bottom: 70px;
  }
  #tc11 .container {
    &.lazyloaded {
      background-image: url("/top2020/img/sp_tc11_bg.webp");
    }
    padding: 0 20px 20px 20px;
    width: 90%;
  }
  #tc11 .container figure.over {
    width: 260px;
    height: 50px;
    margin: 0px auto 30px auto;
  }
  #tc11 .container h2 {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 10px;
  }
  #tc11 .container h2 span {
    font-size: 14px;
  }
  #tc11 .container .under {
    margin-bottom: 30px;
  }
  #tc11 .container .box_cover {
    margin-bottom: 10px;
  }
  #tc11 .container .box {
    width: 100%;
    margin-bottom: 20px;
  }
  #tc11 .container .box .f1 {
    width: 120px;
    margin: 0 auto 10px auto;
  }
  #tc11 .container .box .f2 {
    width: 30px;
    margin: 0 0 10px 0;
  }
  #tc11 .container .box h3 {
    font-size: 15px;
    line-height: 16px;
    margin-bottom: 10px;
  }
  #tc11 .container .box p {
    font-size: 14px;
    line-height: 24px;
  }
  #tc11 .container p.kome {
    font-size: 14px;
    line-height: 24px;
  }
}
</style>
