<template>
  <section id="tc12">
    <div class="container01 lazyload">
      <figure><img v-lazy="'/top2020/img/tc12_01.webp'" alt=""></figure>
      <p>1日５分でも10分でも、コツコツ続ければ大丈夫</p>
      <h2>学習プランの立て方と学習法</h2>
    </div>
    <!-- <div class="container02 lazyload inner01">
      <h3><img v-lazy="'/top2020/img/tc12_02.webp'" alt="" class="pc_only"><img v-lazy="'/top2020/img/sp_tc12_02.webp'" alt="" class="sp_only"></h3>
      <p class="under">英会話を習得するために必要な学習姿勢の原則は、<br>
        次の3つに集約されます。</p>
      <div class="gensoku_over flexb">
        <div class="gensoku">
          <p class="num">01</p>
          <p class="p1">エクササイズは</p>
          <p class="p2">毎日コツコツ</p>
        </div>
        <div class="gensoku">
          <p class="num">02</p>
          <p class="p1">英語は</p>
          <p class="p2">使って覚える</p>
        </div>
        <div class="gensoku">
          <p class="num">03</p>
          <p class="p1">目的と理想を</p>
          <p class="p2">常に忘れない</p>
        </div>
      </div>
      <p class="p1">時間がないからといって、週末にまとめて５、６時間やるのと、毎日40分をやるのとでは、後者の方が断然効果的です。時間がとれない日があっても、５分だけでも「ワイプリスニング」をやるなど、できるだけ毎日やるよう心がけてください。</p>
      <p class="p1">「英語は使って覚える」は、その次の段階として、日々の練習の成果を実際に使ってみるという試合形式の場で試し、鍛えてください。今なら授業料がリーズナブルなオンライン英会話などもおススメです。</p>
      <p class="p1">最後の「目的と理想を忘れない」は、語学習得のための重要な鉄則です。語学を身につけるためには、「必要にかられる」ことが大きなモチベーションとなりますが、日本にいて「英語を話す必要にかられる」環境に身を置くことはなかなかできません。それに代わるのが、「英語を話せるようになりたい」と思う、あなたの目的や動機です。「英語を話せるようになってこんな自分になりたい」という理想を常に忘れずにいてください。</p>
    </div> -->
    <div class="container03 lazyload inner01">
      <h3><img v-lazy="'/top2020/img/tc12_03.webp'" alt="" class="pc_only"><img v-lazy="'/top2020/img/sp_tc12_03.webp'" alt="" class="sp_only"></h3>
      <p class="under">基本は7つのエクササイズ。<br>
        好きなものだけやってもOK！</p>
      <div class="flexb">
        <figure><img v-lazy="'/top2020/img/tc12_04.webp'" alt=""></figure>
        <div class="txt">
          <h4>週に3～5回</h4>
          <p>1.レクチャー動画の視聴（約8～13分）<br>
            2.ワイプリスニング（約5～7分）<br>
            3.会話英文のパワー音読（約20～30分）</p>
          <h4>週に1、2回</h4>
          <p>4.重要表現例文のパワー音読（約15～30分）<br>
            5.外国人お助け英会話のパワー音読（約15分）<br>
            6.「発音のツボ」エクササイズ（約15分）<br>
            7.My ENGLISHバンキング＝重要表現の例文を自分が使う想定でカスタマイズ。それをストックして音読（約10分）</p>
        </div>
      </div>
      <p class="p1">おすすめの１週間プラン例</p>
      <figure class="table"><img v-lazy="'/top2020/img/tc12_05.webp'" alt=""></figure><br><br>
      <p class="p1">ゆるめのプラン例</p>
      <figure class="table"><img v-lazy="'/top2020/img/tc12_05_2.webp'" alt=""></figure>
      <p class="p2">＊他にも、お忙しい方向けのプランなど、効果的なエクササイズの組み合わせ例が、<br>
        教材に付属するマニュアル冊子（「A to Z」）にも掲載されています。<br><br></p>
      <!-- <p class="p1">他の教材との比較</p>
      <figure class="table">
        <img class="pc_only" v-lazy="'/top2020/img/tc12_06.webp'" alt="">
        <img class="sp_only" v-lazy="'/top2020/img/tc12_06_sp.webp'" alt="">
      </figure> -->
    </div>
  </section>
</template>

<script>
export default {
  name: 'Tc12',
}
</script>

<style scoped lang="scss">
#tc12 {
  padding-bottom: 140px;
}
#tc12 .container01 {
  height: 330px;
  margin-bottom: 80px;
}
#tc12 .container01 figure {
  width: 324px;
  height: 80px;
  margin: 0px auto 10px auto;
  position: relative
}
#tc12 .container01 figure img {
  position: absolute;
  bottom: 0;
}
#tc12 .container01 p {
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}
#tc12 .container01 h2 {
  font-size: 50px;
  line-height: 75px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}
#tc12 .container01 {
  padding: 60px;
  &.lazyloaded {
    background-image: url("/top2020/img/tc12_bg1.webp");
  }
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
#tc12 .container02 {
  padding: 60px;
  &.lazyloaded {
    background-image: url("/top2020/img/tc12_bg2.webp");
  }
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  margin-bottom: 60px;
}
#tc12 .container02 h3{
	margin-bottom: 20px;
}
#tc12 .container02 .under {
	font-size:20px;
	line-height: 30px;
	font-weight: 700;
  margin-bottom: 60px;
}
#tc12 .container02 .gensoku_over {
  margin-bottom: 60px;
}
#tc12 .container02 .gensoku {
  background-color: #fff;
  border-radius: 50%;
  width: 240px;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
}
#tc12 .container02 .gensoku p {
  text-align: center;
  color: #fe3340;
}
#tc12 .gensoku p.num {
  font-size: 42px;
  line-height: 39px;
  font-family: 'Roboto Condensed', sans-serif;
  margin-bottom: 10px;
}
#tc12 .gensoku p.p1 {
  font-size: 20px;
  line-height: 24px;
}
#tc12 .gensoku p.p2 {
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
}
#tc12 .container02 > p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
#tc12 .container02 > p:last-child {
  margin-bottom: 0;
}
#tc12 .container03 {
  padding: 60px;
  &.lazyloaded {
    background-image: url("/top2020/img/tc12_bg3.webp");
  }
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
#tc12 .container03 h3{
  margin-bottom: 20px;
}
#tc12 .container03 .under {
  font-size:20px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 60px;
}
#tc12 .container03 .flexb {
  margin-bottom: 70px;
}
#tc12 .container03 .flexb figure {
  width: 400px;
}
#tc12 .container03 .flexb .txt {
  width: 340px;
}
#tc12 .container03 .flexb .txt h4 {
  border: 1px solid #fe3340;
  color: #fe3340;
  border-radius: 30px;
  font-size: 18px;
  width: 150px;
  height: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 400;
}
#tc12 .container03 .flexb .txt p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
}
#tc12 .container03 .flexb .txt p:first-child {
  margin-bottom: 0;
}
#tc12 .container03 .p1 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
}
#tc12 .container03 .p2 {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 40px;
}
#tc12 .container03 .table {
  margin-bottom: 20px;
}
@media only screen and (max-width: 768px) {
  #tc12 {
    padding-bottom: 70px;
  }
  #tc12 .container01 {
    height: 225px;
    margin-bottom: 30px;
  }
  #tc12 .container01 figure {
    width: 160px;
    height: 40px;
  }
  #tc12 .container01 p {
    font-size: 15px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  #tc12 .container01 h2 {
    font-size: 25px;
    line-height: 30px;
  }
  #tc12 .container01 {
    padding: 20px;
  }
  #tc12 .container02 .gensoku_over {
    margin-bottom: 20px;
  }
  #tc12 .container02 .gensoku {
    width: 150px;
    height: 150px;
    padding-top: 20px;
    margin: 0 auto 20px auto;
  }
  #tc12 .gensoku p.num {
    font-size: 21px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  #tc12 .gensoku p.p1 {
    font-size: 13px;
    line-height: 24px;
  }
  #tc12 .gensoku p.p2 {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
  }
  #tc12 .container02 {
    padding: 30px 20px;
    &.lazyloaded {
      background-image: url("/top2020/img/sp_tc12_bg2.webp");
    }
    margin-bottom: 30px;
    width: 90%;
  }
  #tc12 .container03 {
    padding: 30px 20px;
    &.lazyloaded {
      background-image: url("/top2020/img/sp_tc12_bg3.webp");
    }
    width: 90%;
  }
  #tc12 .container02 > p {
    font-size: 14px;
    line-height: 24px;
  }
  #tc12 .container03 .flexb figure {
    width: 200px;
    margin: 0 auto 20px auto;
  }
  #tc12 .container03 .flexb .txt h4 {
    margin: 0 auto 20px auto;
  }
  #tc12 .container03 .flexb .txt p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  #tc12 .container03 .p1 {
    font-size: 14px;
    line-height: 30px;
  }
  #tc12 .container03 .p2 {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
</style>
