<template>
  <section id="tc8">
    <h2 class="inner02 huki"><span>JJ ENGLISHエクササイズや英語学習について…</span>こんな疑問やお悩みがありませんか？</h2>
    <div class="container inner01">
      <div class="box">
        <h3><span>Q1.</span>中学英語の文法に不安があります。大丈夫でしょうか？</h3>
        <div class="answer" :class="{ expanded: expanded_answer_1 }">
          <p class="first">
            はい、大丈夫です。この教材は、音読を中心とする7つのエクササイズを実践することで、<span class="marker2">中学英語の文法も思い出しながら、英会話力を確実に身に着けられるように設計されています。</span><br>
            心配されているのが、三単現のＳとか正しい冠詞は？といった<span class="marker2">細かい文法知識についてだとしたら、ますます心配不要です。</span>そうした細かい文法は、通じる英語を身につけるためには、けして完璧である必要のないスキルだからです。<br>
            いま仮に細かい文法に不安があっても、レッスンを続けていけば、細かい文法も「自然と身についていく」テキストやエクササイズになっています。<br>
            また、文法に不安のある方のために、以下のような工夫やコンテンツも準備しています。安心して、レッスンをスタートしてください。
          </p>
          <p>
            <strong>１ 重要テンプレ「スーパー・センテンス」は基礎文法の復習にもなる</strong><br>
            各レッスンで登場する重要テンプレ表現「スーパー・センテンス」には、時制、比喩、関係代名詞などの基本文法も含まれていて、担当の安河内先生が動画で文法的な解説もされています。テキストの解説もあります。<br>
            <strong>２ 今なら、安河内先生の中学文法復習動画も30本が特典で（特典欄参照）</strong><br>
            <strong>３ ちょっと難しいネイティブ表現は、テキストにワンポイント解説あり</strong><br>
            <strong>４ 文法的な理解を助ける「超直訳BOOK」（PDF）も専用サイトにアップ</strong>
          </p>
          <div class="mask" v-show="!expanded_answer_1"></div>
        </div>
        <div class="more-wrapper" v-show="!expanded_answer_1">
          <div class="more"><a href="#" @click.prevent="expanded_answer_1 = true">続きを読む</a></div>
        </div>
      </div>
      <div class="box">
        <h3><span>Q2.</span>他の教材では長続きせずに失敗してきました。私には英語の才能がないのかと不安です。</h3>
        <div class="answer" :class="{ expanded: expanded_answer_2 }">
          <p class="first">
            <span class="marker2">英語を話せるようになるのに才能やセンスは関係ありません。</span>
          </p>
          <p>
            これまでの教材が長続きしなかったのは、ある程度続けても上達が感じられなかったからではないでしょうか。そうだとしたら、その原因は、その教材がアナタに合っていなかったか、その教材だけでは英語を話すために必要なスキルが身につけられなかったか。ただ、それだけです。つまり、<span class="marker2">教材（学習方法）選びを失敗しただけなのです。</span>
          </p>
          <p>
            日本語は世界でもっとも難しい言語の１つです。一方、英語は比較的易しい言語です。日本語を話せているアナタが英語を話せないはずはないのです。
          </p>
          <p>
            ただし、日本語と英語との間には、文法や発音に隔たりがあります。その隔たりを埋めるためには、このサイトで説明するように、３つのスキルを連動して身につける「正しい学習方法」が必要です。そうでないと、隔たりが次第に縮まっていることを実感できなくて、挫折を繰り返してしまいます。
          </p>
          <p>
            同じような悩みを抱えていた人たちが、この教材で次々と逆転大勝利を収めています。私たちのモニタリングによると、<span class="marker2">別の教材で失敗を経験した人の方が学習意欲も高く、他の教材との効果の違いも実感できるので、継続率も成功率も高くなっています。</span>ぜひもう一度、この教材で英語に挑戦してください。
          </p>
          <div class="mask" v-show="!expanded_answer_2"></div>
        </div>
        <div class="more-wrapper" v-show="!expanded_answer_2">
          <div class="more"><a href="#" @click.prevent="expanded_answer_2 = true">続きを読む</a></div>
        </div>
      </div>
      <div class="box">
        <h3><span>Q3.</span>英会話教材にするかオンライン英会話にするか迷っています。スクールだけでは話せるようにならないのでしょうか？</h3>
        <div class="answer" :class="{ expanded: expanded_answer_3 }">
          <p class="first">
            残念ながら、オンライン英会話を含めて、スクールで英語をものにできる人の割合は、50％以下というデータもあります。その理由としては、<br>
            1.文法や語法のインプット量が少ない。<br>
            2.グループレッスンで自分が話す時間が短い。<br>
            3.基礎ができていないため、英語の意味がわからず、レッスンについていけない。<br>
            などが挙げられます。
          </p>
          <p>
            １と２については、スクールだけでは、学習の量が足りないということを示しています。また、３の問題については、１対１のオンライン英会話などで起こりがちですが、英語で授業する先生の言うことが聞き取れないので、何度も聞き返しているうちに時間があっという間に過ぎていく、という失敗パターンも多く聞かれます。<br>
            こうした失敗を防ぐために、<span class="marker2">発話のエクササイズのできる教材との併用。</span>または、適切な教材を使った独学である程度の力をつけてから、スクールを始めることをおススメします。成功者のアドバイスによると、<span class="marker2">目安として、６，７割は先生の言う英語が聞き取れるようになってから</span>（体験レッスンで確認）スクールを始めると効果が高くなります。
          </p>
          <div class="mask" v-show="!expanded_answer_3"></div>
        </div>
        <div class="more-wrapper" v-show="!expanded_answer_3">
          <div class="more"><a href="#" @click.prevent="expanded_answer_3 = true">続きを読む</a></div>
        </div>
      </div>
      <div class="box">
        <h3><span>Q4.</span>１日の学習時間はどのくらいですか？</h3>
        <div class="answer" :class="{ expanded: expanded_answer_4 }">
          <p class="first">
            推奨しているのは、通勤通学などのスキマ時間に１５分以上、帰宅してパワー音読を２０分以上、合計すると１日４０分程度を推奨しています。ただしこれは、あくまで、平均的な英語力をお持ちの方が6ヵ月で外国人と英語でコミュニケーションを取れるようになるためのモデルケースです。<br>
            英語は継続が重要です。<span class="marker2">ご自分の生活や英語力に合わせて、無理なく継続できる、学習時間を設定してください。</span>その際に目安となる考え方をお答えします。<br>
            1 確実に上達していくためには→<strong>［週3回以上、20分以上のパワー音読が必要］</strong><br>
            2 忙しい時期が1週間、1ヵ月など長期にわたる場合でも→<strong>［1日10〜15分程度のエクササイズは続ける＝まったくやらない期間をつくらない］</strong><br>
            どうしても時間が取れない時期にオススメなのは、動く字幕付きの動画による「ワイプリスニング」／チャンク音読をレッスンの半分だけ／各レッスンの２見開き目にある重要表現（テンプレ）例文またはBOOK４「お助け英会話」のパワー音読／発音のツボエクササイズ などです。
          </p>
          <div class="mask" v-show="!expanded_answer_4"></div>
        </div>
        <div class="more-wrapper" v-show="!expanded_answer_4">
          <div class="more"><a href="#" @click.prevent="expanded_answer_4 = true">続きを読む</a></div>
        </div>
      </div>
      <div class="box">
        <h3><span>Q5.</span>テキストの分量が相当あるようですが、全部やらないと話せるようにならないのでしょうか？</h3>
        <div class="answer" :class="{ expanded: expanded_answer_5 }">
          <p class="first">
            いいえ、安心してください。テキストは<span class="marker2">およそ３分の２以上をこなしたところで「外国人とコミュニケーション」がとれる英語力がつく</span>ように設計されています。
          </p>
          <p>
            当教材の主要テキストであるBook1～Book3の３冊には全120レッスンが収録されていますが、各レッスンは会話でよく使う表現（テンプレ中心に360個）が、重要なものほど何度も出てくるように構成されています。なので、テキストを３分の２程度こなしてもらった段階でも、「外国人と英語でコミュニケーションをとる」には十分の英語力が身につくようになっています。
          </p>
          <p>
            英語教育の専門家の間では、<span class="marker2">初級レベルの英語が話せるようにために必要なテキスト量として、NHK「ラジオ英会話」の10カ月分のスキット量が目安</span>だとよく言われます。JJのメインテキストの２／３の英文量はほぼそれに匹敵します。少しゆるめのプランでも、半年程度でこなしていただける分量となっています。
          </p>
          <p>
            その量について、他の教材と比べて多いと思われるかもしれません。たしかに受験勉強の場合は、「同じテキストを深く」という勉強法も有効です。でも、英会話の場合は違います。<span class="marker2">「覚えるのではなく英語に慣れる」のが効果的な学習法</span>です。そのためには、<span class="marker2">どうしても一定以上の英文量に接する必要がある</span>のです。（５万円台の上級者向け教材の英文量は、JJのさらに３倍以上あります。JJの英文量は、サブテキストや特典を含めるとその分量に近くなります）
          </p>
          <p>
            ちなみに、一般的な「書籍教材」の場合、ページ数にどうしても制限があるため、掲載英文量はJJ ENGLISHエクササイズ１／10〜１／８程度です。ですから、<span class="marker2">書籍教材はあくまで理論やノウハウを学ぶためのもの</span>で、エクササイズの実践には他の手段を想定してあるものがほとんどです。
          </p>
          <div class="mask" v-show="!expanded_answer_5"></div>
        </div>
        <div class="more-wrapper" v-show="!expanded_answer_5">
          <div class="more"><a href="#" @click.prevent="expanded_answer_5 = true">続きを読む</a></div>
        </div>
      </div>
      <div class="box">
        <h3><span>Q6.</span>値段が少し高いのですが…</h3>
        <div class="answer" :class="{ expanded: expanded_answer_7 }">
          <p class="first">
            はい。まだまだ弊社の努力不足があるのかと存じ上げます。こうしたご意見の回答になるかどうかわかりませんが、「ムダな金額ではない」とできるだけお客様にご納得していただくために、以下に、他ページでご説明する以外の「品質」に関するご案内をさせていただきます。ご参照ください。<span class="marker2">（サイト最下部の同価格帯「他社教材との比較表」もご参照ください）</span>
          </p>
          <h4>［JJ ENGLISHエクササイズが追求したこだわりの品質］</h4>
          <p>
            <strong>１ 確実に話せるようになるメソッド確立のために</strong><br>
            <ul>
              <li>４人のトップクラスの監修者以外に、25名の英語教育関係者（主に大高中学の先生、ネイティブを含む英会話講師、英語関連書籍の著者）に綿密なヒアリングを行いました。</li>
              <li>ベースとなりました「おもてなし純ジャパENGLISH」の読者を中心に、総勢78名の英会話成功者に長時間のヒアリングを行いました。</li>
              <li>既存の37品の英語教材（アプリ含む）を実際に使用して、また、150冊を超える英語書籍などを分析研究し、メソッド確立にフィードバックさせました。</li>
            </ul>
          </p>
          <p>
            <strong>２ テキストブックについて</strong><br>
            <ul>
              <li>価格を下げるために製本テキストのない形態も検討しましたが、上記ヒアリング等の結果、やはり製本テキストのある形態にいたしました。テキストには、学習効率の最大化を優先し、コスト的には不利になりますが、下記のような工夫や規格の採用をいたしました。</li>
            </ul>
            ア）英語を話せるようになるためには、一定以上の英文量に接っすることが必要。その最低ラインを英語書籍教材の英文量の８〜10冊分と算出。索引インデックスも収録した。<br>
            イ）1冊の製本テキストにすればコストが下がるが、持ち運びの利便性を考えて合計5冊の製本にした。<br>
            ウ）読みやすい活字の大きさ／フリガナのほか、学習時に書き込みができるよう、空白スペースのあるレイアウトにした／インクの裏写りがない、軽い、長時間使用しても目が疲れない、長期間使用しても破れない資材（紙）を採用した。<br>
            エ）日本語を読むだけでも楽しいテキストをめざし、日本語の原文を実績のある一流ライターに依頼し、取材も加えたビビッドな文章にした。<br>
            オ）英文校正については、大手出版社や一流企業をクライアントに持つ、日本有数の専門会社である株式会社ダイナワードに依頼した。
          </p>
          <p>
            <strong>３ ソフトについて</strong><br>
            <strong>【動画ソフトの採用】</strong><br>
            <ul>
              <li>より効率的な英会話スキル向上のため、制作コストが音声ソフトの10倍以上かかる動画ソフトをあえて導入いたしました。単にテキスト部分を動画表現するだけでなく、3人の業界トップクラスの講師陣の、400本に及ぶ講義の動画を収録しました（ファイル数は160本）。また、「パワー音読」の実技指導の動画も収録しました。</li>
            </ul>
            <strong>【音声ソフトについて】</strong><br>
            <ul>
              <li>音声については、「聞き流し学習」も実践できるように、テキストに含まれる単語集、備考的英文を含め、全英語について英語音声を収録してあります。</li>
              <li>重要例文集の音声は、機械式ではなく、ネイティブの生発声による2段階スピードを収録になっています。タイムアタック音読用音声を含めると3段階以上のスピードの音声を体験できます。</li>
            </ul>
          </p>
          <p>
            <strong>４ その他</strong><br>
            <ul>
              <li>安定性と利便性を確保するために、保存用ＤＶＤ—ＲＯＭディスクと専用のストリーミング／ダウンロードサイトの両方をご利用できるようにいたしました。</li>
              <li>上記専用サイトから、新たな学習法の提案や新規特典企画を随時追加（無償）で利用いただけます。</li>
            </ul>
          </p>
          <p>
            最後になりますが、スクールやサブスクリプション（定期支払）型のサービスとの比較について申し上げます。これは弊社教材に限りませんが、独習型教材は、<span class="marker2">その価格をお支払いいただければ、「話せるようになるまで」一切の追加出費はございません。</span>また、弊社教材は、2年でも3年でも繰り返し継続学習していただければ、上級者に上達していただけるメソッドとテキスト内容になっております。
          </p>
          <div class="mask" v-show="!expanded_answer_7"></div>
        </div>
        <div class="more-wrapper" v-show="!expanded_answer_7">
          <div class="more"><a href="#" @click.prevent="expanded_answer_7 = true">続きを読む</a></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Tc8',
  data() {
    return {
      expanded_answer_1: false,
      expanded_answer_2: false,
      expanded_answer_3: false,
      expanded_answer_4: false,
      expanded_answer_5: false,
      expanded_answer_6: false,
      expanded_answer_7: false,
    }
  }
}
</script>

<style scoped lang="scss">
#tc8 {
  background-color: #fe3340;
  padding: 60px 0 170px 0;
}
#tc8 h2 {
  font-size: 38px;
  line-height: 52px;
  color: #fff;
  background-image: url("/top2020/img/tc8_huki.webp");
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  background-position: bottom;
  padding-bottom: 30px;
  text-align: center;
  margin-bottom: 50px;
}
#tc8 h2 span {
  font-size: 22px;
  display: block;
}
#tc8 .box {
  background-color: #fff;
  margin-bottom: 40px;
  padding: 50px 50px 80px 50px;
  border-top-left-radius: 50px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  position: relative;
  .answer {
    position: relative;
    max-height: 60px;
    overflow: hidden;
    transition: all 0.5s;
    &.expanded {
      max-height: 5000px;
    }
    .mask {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 60px;
      background: linear-gradient(transparent, white);
    }
  }
  .more-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;
    padding-left: 50px;
    padding-right: 50px;
    .more {
      text-align: center;
      height: 30px;
      max-width: 760px;
      margin: 0 0 0 auto;
      a {
        display: inline-block;
        color: white;
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
        height: 30px;
        border-radius: 15px;
        padding-left: 30px;
        padding-right: 30px;
        background-color: #fe3340;
      }
    }
  }
}
#tc8 .box h3 {
  font-size: 28px;
  line-height: 40px;
  color: #fe3340;
  padding-left: 2.6em;
  text-indent: -2.6em;
  margin-bottom: 40px;
}
#tc8 .box h3 span {
  font-size: 45px;
}
#tc8 .box p, #tc8 .box h4 {
  max-width: 760px;
  font-size: 14px;
  line-height: 24px;
  margin: 0 0 40px auto;
  padding-left: 100px;
  strong {
    font-weight: bold;
  }
  .red {
    color: #fe3340;
  }
}
#tc8 .box h4 {
  font-size: 16px;
  text-align: center;
}
#tc8 .box ul li {
  max-width: 760px;
  font-size: 14px;
  line-height: 24px;
  margin-left: 1em;
  position: relative;
  &::before {
    display: inline-block;
    position: absolute;
    left: -1em;
    top: 0;
    content: "・";
    font-size: 14px;
    line-height: 24px;
  }
}
#tc8 .box p:last-child {
  margin-bottom: 0;
}
#tc8 .box p.first {
  position: relative
}
#tc8 .box p.first:before {
  width: 70px;
  height: 1px;
  background-color: #000;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 10px;
}
@media only screen and (max-width: 768px) {
  #tc8 {
      padding: 60px 0;
  }
  #tc8 h2 {
    font-size: 25px;
    line-height: 31px;
    color: #fff;
    padding-bottom: 20px;
    margin-bottom: 30px;
    width: 90%;
  }
  #tc8 h2 span {
    font-size: 14px;
  }
  #tc8 .box {
    padding: 30px 20px 40px 20px;
    border-top-left-radius: 30px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-bottom: 20px;
  }
  #tc8 .box h3 {
    font-size: 15px;
    line-height: 24px;
    color: #fe3340;
    padding-left: 3em;
    text-indent: -3em;
    margin-bottom: 20px;
  }
  #tc8 .box h3 span {
    font-size: 22px;
    margin-right: 5px;
  }
  #tc8 .box p {
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 20px auto;
    padding-left: 40px;
  }
  #tc8 .box p.first:before {
    display: none;
  }
}
</style>
