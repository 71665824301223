<template>
  <div>
    <Layout>
      <template #header>
        <Header />
      </template>
      <template #contents>
        <section class="column_sp2_pc2 imp_sec">
          <h2 class="imp_h2">特定商取引に関する法律に基づく表示</h2>
          <table>
            <tr>
              <td class="imp_td_left">販売業者</td>
              <td>株式会社JJイングリッシュ社</td>
            </tr>
            <tr>
              <td>運営統括責任者</td>
              <td>秋藤幸司</td>
            </tr>
            <tr>
              <td>所在地</td>
              <td>〒141-0022 東京都品川区東五反田5-19-2（本社）</td>
            </tr>
            <tr>
              <td>商品代金以外の料金の説明</td>
              <td>・消費税<br>・送料全国一律無料</td>
            </tr>
            <tr>
              <td>電話番号</td>
              <td>0120-197-917</td>
            </tr>
            <tr>
              <td>メールアドレス</td>
              <td>info@jjenglish.co.jp</td>
            </tr>
            <tr>
              <td>申し込みの有効期限</td>
              <td>原則、受注確認（受注確認の為の自動送信メール発信）後、5日間とします。</td>
            </tr>
            <tr>
              <td>不良品</td>
              <td v-if="basePath === '/gold/'">
                商品到着時に必ず商品をご確認ください。<br>
                次の場合は、交換にて対応をいたします。・申し込まれた商品と届いた商品が異なっている場合 ・損傷している、汚れている商品 <br>
                商品到着後、７日以内にinfo@jjenglish.co.jpまでご連絡ください。交換商品を送らせていただきます。
              </td>
              <td v-else>
                【通常版】<br>
                商品到着時に必ず商品をご確認ください。<br>
                次の場合は、交換にて対応をいたします。・申し込まれた商品と届いた商品が異なっている場合 ・損傷している、汚れている商品 <br>
                商品到着後、７日以内にinfo@jjenglish.co.jpまでご連絡ください。交換商品を送らせていただきます。<br>
                <br>
                【DL版】<br>
                不都合などについては、info@jjenglish.co.jpまでお問い合わせください。
              </td>
            </tr>
            <tr>
              <td>引き渡し時期</td>
              <td>ご注文受付後、2日以内に発送いたします。<br>天候不順・配送業者の都合・その他何らかの理由により遅延する場合はメール等でお知らせいたします。
              </td>
            </tr>
            <tr>
              <td>お支払い方法</td>
              <td v-if="basePath === '/'">
                【通常版】<br>
                クレジットカード・Amazon Pay・代金引換・NP後払い<br>
                <br>
                【DL版】<br>
                クレジットカード
              </td>
              <td v-else-if="basePath === '/gold/'">
                クレジットカード・代金引換・コンビニ決済<br>
              </td>
              <td v-else>
                【通常版】<br>
                クレジットカード・代金引換・NP後払い<br>
                <br>
                【DL版】<br>
                クレジットカード
              </td>
            </tr>
            <tr>
              <td>お支払い期限</td>
              <td>
                ・代金引換の場合、商品のお届け時に宅急便配達員に代金のお支払いをお願いします。代引き手数料は無料です。<br>
                ・後払いの場合は、請求書発行後14日以内。
              </td>
            </tr>
            <tr>
              <td>返品期限</td>
              <td v-if="basePath === '/gold/'">
                20Lesson以上、当教材推奨のエクササイズを実践されても学習の成果がまったく得られないと感じられた場合、58日間の返金期間を設けております。返金希望の方はinfo@jjenglish.co.jpまで、件名に「返金希望」と明記の上、メール本文に下記の内容を記載しお送りください。<br>
                ・お名前<br>
                ・メールアドレス<br>
                ・注文番号<br>
                ・返品理由（未開封や実践の形跡が認められない場合、返金をお断りする場合があります）<br>
                ・振込先の金融機関名・支店名・口座種別・口座番号・名義<br>
                ※弊社にて教材の返品を確認後、15営業日以内にお客様の口座に全額返金いたします。<br>
                ※購入時のセットが揃っている方に限ります。返品時の送料・振込手数料はお客様のご負担となります。<br>
                <span class="blue">※領収書を発行してのご購入の場合は返金できません。ご注意ください。</span><br>
                ※58日間の保証はご注文日からの起算となります。<br>
              </td>
              <td v-else>
                【通常版】<br>
                20Lesson以上、当教材推奨のエクササイズを実践されても学習の成果がまったく得られないと感じられた場合、58日間の返金期間を設けております。返金希望の方はinfo@jjenglish.co.jpまで、件名に「返金希望」と明記の上、メール本文に下記の内容を記載しお送りください。<br>
                ・お名前<br>
                ・メールアドレス<br>
                ・注文番号<br>
                ・返品理由（未開封や実践の形跡が認められない場合、返金をお断りする場合があります）<br>
                ・振込先の金融機関名・支店名・口座種別・口座番号・名義<br>
                ※弊社にて教材の返品を確認後、15営業日以内にお客様の口座に全額返金いたします。<br>
                ※購入時のセットが揃っている方に限ります。返品時の送料・振込手数料はお客様のご負担となります。<br>
                <span class="blue">※ポイントサイトをご利用してのご購入、領収書を発行してのご購入の場合は返金できません。ご注意ください。</span><br>
                ※58日間の保証はご注文日からの起算となります。<br>
                <br>
                【DL版】<br>
                商品の性格上、返品返金には対応しておりません。
              </td>
            </tr>
            <tr>
              <td>返品送料</td>
              <td>お客様都合による返品につきましてはお客様のご負担とさせていただきます。不良品に該当する場合は当方で負担いたします。</td>
            </tr>
          </table>
        </section>
      </template>
      <template #footer>
        <Footer />
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from '@/components/Layout.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Low',
  components: {
    Layout,
    Header,
    Footer,
  },
  computed: {
    basePath() {
      return this.$store.state.basePath
    }
  },
  head: {
    script: [
      {
        type: 'text/javascript',
        inner: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5HP4P8JK');
        `,
      }
    ],
  },
}
</script>

<style scoped lang="scss">
  .blue {
    color: blue;
  }
</style>
