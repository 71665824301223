import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    basePath: '/gold/',
    shopUrl: 'https://jjenglishgold.easy-myshop.jp/c-item-detail?ic=A000000001',
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
