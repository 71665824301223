<template>
  <section id="tc7">
    <div class="pkg">
      <div class="unit1">
        <div class="unit1_inner">
          <div class="step">
            <h3>上達の３段ステップ</h3>
            <div class="content">
              <h4>STEP１</h4>
              <p>
                「パワー音読」をメインとする<br>
                「７つのエクササイズ」を継続すれば<br>
                「知識」が「技能」に変わっていき<br>
                <span class="marker">中学英語を聞けて話せ始めます。</span><br>
                <span class="normal">（だいたい１〜２ヵ月目。感動しますよ！）</span>
              </p>
              <h4>STEP２</h4>
              <p>
                エクササイズと並行して、<br>
                「その時の自分の英語」で<br>
                <span class="marker">できるだけ、たくさん話していく。</span><br>
                オンラインでもOK。<br>
                <span class="normal">（楽しくてたまらないですよ！）</span><br>
                <span class="normal">（無料のアプリやサイトもあります）</span>
              </p>
              <h4>STEP３</h4>
              <p>
                たくさん話せば話すほど、<br>
                あなたの英語は高度になり<br>
                <span class="marker">どこまでも洗練されていきます。</span><br>
                <span class="normal">（ネイティブ並みを目指すのもあり！）</span>
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="koushite">
          <h3>その先にはハッピーな未来が！</h3>
          <ul>
            <li><img v-lazy="'/top2020/img/tc2_01_2.webp'" /></li>
            <li><img v-lazy="'/top2020/img/tc2_02_2.webp'" /></li>
            <li><img v-lazy="'/top2020/img/tc2_08.webp'" /></li>
            <li><img v-lazy="'/top2020/img/tc2_04_2.webp'" /></li>
          </ul>
        </div> -->
        <!-- <p class="best-10">
          <img v-lazy="'/top2020/img/best_10.webp?date=20230725'" /><br>
          <br>
          ※購入者の主観的感想をアンケート集計したものです。
        </p> -->
      </div>
      <!-- <div class="unit2">
        <p>
          日本人の特性にマッチした、<br>
          話せるようになるために必要不可欠な要素を<br>
          １つにパッケージ化した教材−−。<br>
          <br>
          ほかの教材やスクールで失敗してきた人も、<br>
          初めて英会話に挑戦した人も、<br>
          JJ ENGLISHエクササイズで、<br>
          次つぎと「英語が話せる人」の<br>
          仲間入りをしています！
        </p>
        <h3>
          話せるヨロコビは無限大！！<br>
          <em>
            <span>確実性が高い王道の</span>メソッドで<br>
            あなたも<span>夢</span>を<span>叶えて</span>みませんか？
          </em>
        </h3>
        <figure><img v-lazy="'/top2020/img/img_method09.webp?date=20220625'"></figure>
      </div> -->
    </div>
  </section>
</template>

<script>
export default {
  name: 'Tc7',
}
</script>

<style scoped lang="scss">
h3 {
  margin-bottom: 10px;
  .normal {
    font-weight: normal;
  }
  &.red {
    color: #fe3340;
    font-size: 32px;
  }
}
h4 {
  &.center {
    text-align: center;
  }
}
#tc7 h2.red {
  margin-bottom: 30px;
}
#tc7 figure.f1 {
  width: 360px;
  margin: 0 auto 15px auto;
}
#tc7 .flame{
  width: 100%;
  padding: 0 50px;
  box-sizing: border-box;
  background-color: #FFE1E1;
  padding-top: 60px;
}
#tc7 .flame-result {
  width: 100%;
  margin-bottom: 36px;
  background-color: white;
  padding: 0 50px;
  .result {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}
.btn-area {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  a {
    display: inline-block;
    margin-left: 28px;
    margin-right: 28px;
    margin-bottom: 50px;
    box-shadow: 4px 6px 6px rgba(0, 0, 0, 0.3);
    display: inline-block;
    border-radius: 20px;
    transition: ease 0.3s;
    position: relative;
    .btn_arrow {
      position: absolute;
      bottom: -20px;
      right: -6px;
      width: 32px;
      height: 50px !important;
    }
    &:hover {
      opacity: 0.8;
      //transform: scale(1.02) translateY(-1px);
    }
    img {
      display: block;
      height: 100px !important;
    }
  }
}
.about-ondoku {
  margin-top: -24px;
}
.ondoku-movie {
  width: 70%;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
}
#tc7 .flame .head-outer {
  display: flex;
  justify-content: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  .head {
    display: inline-block;
  }
  .num {
    flex-shrink: 0;
  }
}
#tc7 .flame .secondary{
  text-align: left;
  font-size: 26px;
  font-weight: 700;
  color: #fe3340;
  margin-bottom: 8px;
}
#tc7 .flame .thirdary{
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  color: #fe3340;
  margin-bottom: 8px;
}
#tc7 .flame .primary{
  text-align: left;
  font-size: 36px;
  font-weight: 700;
  color: #fe3340;
  margin-bottom: 10px;
}
#tc7 .flame .title{
  margin-top: 60px;
}
#tc7 .flame .txt{
  position: relative;
  width: 70%;
  margin: -200px auto 0;
  background:rgba(255,255,255,0.95);
  box-sizing: border-box;
  padding: 50px 8% 20px 8%;
  border-radius: 10px;
  .blue {
    color: #0a74d7;
    &.bold {
      font-weight: bold;
      font-size: 18px;
    }
  }
  .middle {
    font-size: 24px;
  }
}
#tc7 .pkg .unit1 .koushite h3 {
  font-size: 38px;
  margin-bottom: 50px;
}
#tc7 .koushite {
  padding: 0 50px;
  margin: 0 auto 60px;
  max-width: 1016px;
  h3 {
    color: #fe3340;
    font-size: 42px;
    margin-bottom: 30px;
    .large {
      font-size: 48px;
    }
    text-align: center;
  }
  img {
    display: block;
    &.sp {
      display: none;
    }
  }
  ul {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
    li {
      width: 160px;
      height: 160px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
#tc7 .pkg .unit1 .step {
  padding: 0px 8% 60px;
  width: 70%;
  margin: 0 auto;
  text-align: center;
  .content {
    display: inline-block;
  }
  h3, h4, p {
    color: #fe3340;
    text-align: left;
  }
  h3 {
    font-size: 38px;
    margin-bottom: 30px;
    text-align: center;
    border-top: none;
    border-bottom: none;
  }
  h4, p {
    font-size: 24px;
  }
  h4 {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 30px;
    font-weight: bold;
    .normal {
      color: #fe3340;
      font-weight: normal;
    }
  }
}
#tc7 .pkg{
  width: 100%;
  max-width: 1016px;
  margin: 0 auto 0;
  padding: 0 50px;
  box-sizing: border-box;
  .blue {
    color: #0a74d7;
  }
}
#tc7 .pkg .unit1{
  padding: 60px 4.6% 0;
  &.no-bg {
    background: none;
    padding: 0 4.6% 0;
    margin-bottom: 0;
  }
  .normal {
    margin-top: 60px;
    text-align: center;
    font-size: 20px;
    color: black;
  }
  .best-10 {
    margin-bottom: 30px;
  }
}
#tc7 .pkg .unit1 h3{
  padding: 20px 0;
  margin-bottom: 80px;
  border-top: 2px dotted #666;
  border-bottom: 2px dotted #666;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -0.05em;
  .matome {
    display: inline-block;
    font-size: 56px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 20px;
    &.margin-0 {
      margin-bottom: 0;
    }
  }
  .more-point {
    overflow: hidden;
    border-radius: 80px;
    border: 1px solid #cccccc;
  }
  .list {
    display: inline-block;
    text-align: left;
    line-height: 2em;
    margin-bottom: 20px;
    .blue {
      color: #0a74d7;
    }
    .list-item {
      position: relative;
      padding-left: 1.25em;
      .small {
        font-size: 18px;
        position: relative;
        top: -16px;
        font-weight: normal;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0.25em;
        margin-left: -1.25em;
        width: 1em;
        height: 1em;;
        background-image: url("/top2020/img/check.webp");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
#tc7 .pkg .unit1 h3 em{
  font-size: 40px;
  font-style: normal;
}
#tc7 .pkg .unit1 h3 .sub{
  font-size: 20px;
  font-weight: normal;
  &.skill {
    line-height: 1em;
    position: relative;
    top: -16px;
  }
}
#tc7 .pkg .unit1 figure{
  position: relative;
}
#tc7 .pkg .unit1 img.icon{
  position: absolute;
  top: 10%;
  right: 10%;
  width: 16%;
}
#tc7 .pkg .unit2 > p{
  text-align: center;
  font-size: 34px;
  color: #fe3340;
  margin-bottom: 70px;
  .black {
    text-align: center;
    font-size: 34px;
    color: black;
    .list {
      font-size: 24px;
      display: inline-block;
      margin-top: 30px;
      margin-bottom: 30px;
      .none {
        margin-left: 1em;
      }
    }
  }
}
#tc7 .pkg .unit2 h3{
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 100px;
}
#tc7 .pkg .unit2 h3 em{
  font-size: 50px;
  font-style: normal;
}
#tc7 .pkg .unit2 h3 em span{
  color: #fe3340;
}
@media only screen and (max-width: 768px) {
  #tc7 figure.f1 {
    width: 220px;
  }
  #tc7 .koushite {
    padding: 20px 0px 40px;
    margin: 0 auto 0px;
    width: 100%;
    h3 {
      font-size: 18px;
      margin-top: 40px;
      margin-bottom: 40px;
      .large {
        font-size: 19px;
      }
    }
    img {
      &.pc {
        display: none;
      }
      &.sp {
        display: block;
      }
    }
    ul {
      flex-flow: row wrap;
      li {
        width: 140px;
        height: 140px;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
      }
    }
  }
  #tc7 .pkg .unit1 .step {
    padding: 20px 20px 40px;
    width: 100%;
    margin: 0 auto;
    h3 {
      font-size: 23px;
    }
    h4, p {
      font-size: 18px;
    }
  }
  #tc7 .pkg{
    max-width: none;
    padding: 0 20px;
  }
  #tc7 .pkg .unit1{
    padding: 30px 0 0;
  }
  #tc7 .pkg .unit1 h3{
    padding: 14px 0;
    margin-bottom: 16px;
    text-align: center;
    font-size: 13px;
    .matome {
      font-size: 30px;
    }
    .sub{
      font-size: 13px;
    }
    .list {
      .list-item {
        .small {
          font-size: 13px;
          top: 0;
        }
      }
    }
  }
  #tc7 .pkg .unit1 h3 em{
    font-size: 18px;
    display: block;
    margin-top: 2px;
  }
  #tc7 .pkg .unit2 > p{
    font-size: 18px;
    margin-bottom: 46px;
    .black {
      font-size: 18px;
      .list {
        font-size: 14px;
      }
    }
  }
  #tc7 .pkg .unit2 h3{
    font-size: 16px;
    margin-bottom: 60px;
    line-height: 1.3;
    letter-spacing: -0.05em;
  }
  #tc7 .pkg .unit2 h3 em{
    font-size: 21px;
    line-height: 1.3;
    display: block;
    margin-top: 4px;
  }
}
::v-deep .purchase-button {
  margin-top: 80px;
}
</style>
