<template>
  <div class="modal">
    <div class="modal__bg" @click="$emit('close')"></div>
    <div class="modal__content">
      <slot />
      <p class="close_btn" href="" @click="$emit('close')"><img src="/top2020/img/close.webp" alt=""></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
}
</script>

<style scoped lang="scss">
.modal {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}
.modal__bg {
  background: rgba(0, 0, 0, 0.8);
  height: 100vh;
  position: absolute;
  width: 100%;
}
.modal__content {
  background: #fff;
  left: 50%;
  padding: 40px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
}
.close_btn {
  position: fixed;
  top: -60px;
  right: 0;
  width: 50px;
}
@media only screen and (max-width: 768px) {
  .modal__content {
    padding: 20px;
    width: 90%;
  }
}
</style>
