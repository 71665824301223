<template>
  <section id="tc15">
    <figure><img v-lazy="'/top2020/img/tc15_01.webp'" alt=""></figure>
    <div class="container flexb inner01">
      <div class="box">
        <h3>JJ ENGLISH<br>エクササイズは<br>58日間全額返金保証</h3>
        <p>
          20Lesson以上、当教材推奨のエクササイズを実践されても学習の成果が得られないと感じられた場合、58日間の返金期間を設けております。詳しくは、「お買い物ガイド欄」をご確認ください。
          <span v-if="basePath === '/'">※DL版は対象外です</span>
        </p>
      </div>
      <div class="box">
        <h3>学習の質問に<br>メールでサポート<br>（2年間無料）</h3>
        <p>インストール用ディスクおよびテキストに収録されている英語・学習方法に関するご質問には監修者と専任ネイティブがメールでお答えいたします！（購入から２年間） </p>
      </div>
      <div class="box">
        <h3>法人・学校関係の<br>お客様へ</h3>
        <p>法人・学校関係のお客様はご購入方法等について別途ご案内申し上げます。<br>info@jjenglish.co.jp <br>またはフリーダイヤル 0120-197-917までお問い合わせください。 </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Tc15',
  computed: {
    basePath() {
      return this.$store.state.basePath
    }
  }
}
</script>

<style scoped lang="scss">
#tc15 {
  padding-bottom: 140px;
  position: relative;
  .blue {
    color: #0a74d7;
  }
}
#tc15 > figure {
  width: 930px;
  margin: 100px auto 60px auto;
}
#tc15 .flexb .box {
  width: calc(100% / 3);
  padding: 10px 20px;
}
#tc15 .flexb .box:nth-child(2) {
  border-left: 1px solid;
  border-right: 1px solid;
}
#tc15 .flexb .box h3 {
  font-size: 28px;
  line-height: 37px;
  margin-bottom: 30px;
  color: #fe3340;
  text-align: center;
}
#tc15 .flexb .box p {
  font-size: 18px;
  line-height: 28px;
}
@media only screen and (max-width: 768px) {
  #tc15 > figure {
    width: 95%;
  }
  #tc15 .flexb .box {
    width: 100%;
    padding: 10px 20px;
  }
  #tc15 .flexb .box:first-child {
    border-top: 1px solid;
  }
  #tc15 .flexb .box {
    border-bottom: 1px solid;
  }
  #tc15 .flexb .box:nth-child(2) {
    border-left: none;
    border-right: none;
  }
  #tc15 .flexb .box h3 {
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  #tc15 .flexb .box p {
    font-size: 14px;
    line-height: 24px;
  }
}
</style>
