<template>
  <div>
    <Layout>
      <template #header>
        <Header />
      </template>
      <template #contents>
        <section class="column_sp2_pc2 imp_sec">
          <h2 class="imp_h2">プライバシーポリシー</h2>
          <div class="imp_subpage_text">
            株式会社JJイングリッシュ社（以下、「弊社」といいます）は、当プライバシーポリシーを掲載し、当プライバシーポリシーに準拠して提供されるサービス（以下、「本サービス」といいます）をご利用される方、企業、団体等（以下、「ユーザー」といいます）のプライバシーを尊重し、ユーザーの個人情報の管理に細心の注意を払い、これを取扱うものとします。
          </div>

          <div class="imp_subpage_text">
            <h3>1. 個人情報の定義</h3>
            「個人情報」とは、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、メールアドレス、クレジットカード番号、その他の記述等により特定の個人を識別することができるもの、及び他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものをいいます。
          </div>

          <div class="imp_subpage_text">
            <h3>2. 個人情報の利用目的</h3>
            個人情報の利用目的は以下のとおりです。利用目的を超えて利用することはありません。
            <br><br>
            ・お客様の個人認証及び本サービスの提供<br>
            ・ご注文の確認や商品の配送<br>
            ・会員制サービスへの登録の確認やサービスの提供<br>
            ・商品に関連するアフターサービス<br>
            ・取扱商品、関連イベント・セミナーなど、本サービスの利用に伴う連絡・DMを含む各種お知らせ等の配信・送付<br>
            ・キャンペーン、アンケート、取材等の実施<br>
            ・本サービス、取扱商品に関するご意見、お問い合わせ内容の確認・返信
            <br><br>
            【特記】当サイトでは、広告の効果測定のため、第三者の運営するツールから当サイトに訪れる前にクリックされている広告の情報（クリック日や広告掲載サイトなど）を取得し、ご注文の情報と照合する場合がございます。
            <br><br>
            弊社では、下記の場合を除いてはお客様の断りなく第三者に個人情報を開示・提供することはいたしません。
            <br><br>
            ・ユーザーが第三者に不利益を及ぼすと判断した場合<br>
            ・法令に基づく場合や、国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合<br>
            ・裁判所、検察庁、警察又はこれに準じた権限を有する機関から、個人情報についての開示を求められた場合<br>
            ・ユーザー本人から明示的に第三者への開示又は提供を求められた場合<br>
            ・法令により開示又は提供が許容されている場合<br>
            ・合併その他の理由による事業の承継に伴い個人情報を提供する場合であって、承継前の利用目的の範囲で取り扱われる場合
          </div>

          <div class="imp_subpage_text">
            <h3>3. 個人情報の安全管理</h3>
            弊社は、不正アクセス、不正利用、改ざん等のリスクから個人情報を保護するため、必要な技術的保護措置を講じます。また、社内規定を整備して従業員及び業務委託先に対して周知徹底するとともに、継続して見直し改善を進めます。
          </div>

          <div class="imp_subpage_text">
            <h3>4. 個人情報の訂正、削除</h3>
            個人情報の訂正、削除は下記の問い合わせ先よりお知らせください。本人からの請求であることを確認したうえで、これに応じます。
          </div>

          <div class="imp_subpage_text">
            <h3>5. Cookie（クッキー）の使用について</h3>
            弊社は、ユーザーによりよいサービスを提供するため、cookie（クッキー）を使用することがありますが、これにより個人を特定できる情報の収集を行えるものではなく、ユーザーのプライバシーを侵害することはありません。
            <br>
            また、弊社では、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。「Googleアナリティクス」はトラフィックデータの収集のためにCookieを使用しています。トラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することができますので、お使いのブラウザの設定をご確認ください。「Googleアナリティクス」の規約についてはこちらをご覧ください。
            <br>
            https://marketingplatform.google.com/about/analytics/terms/jp/
          </div>

          <div class="imp_subpage_text">
            <h3>6. クッキー（cookie）の広告配信利用について</h3>
            弊社では、ヤフー株式会社をはじめとする第三者から配信される広告が掲載される場合があり、これに関連して、当該第三者が、当サイトを訪問したユーザーのクッキー情報等を取得し、利用している場合があります。
            当該第三者によって取得されたクッキー情報等は、当該第三者のプライバシーポリシーに従って取り扱われます。<br>
            ユーザーは、当該第三者のウェブサイト内に設けられたオプトアウトページにアクセスして、当該第三者によるクッキー情報等の広告配信への利用を停止することができます。<br>
            クッキーとは、ウェブページを利用したときに、ブラウザーとサーバーとの間で送受信した利用履歴や入力内容などを、お客様のコンピューターにファイルとして保存しておく仕組みです。次回、同じページにアクセスすると、クッキーの情報を使って、ページの運営者はお客様ごとに表示を変えたりすることができます。お客様がブラウザーの設定でクッキーの送受信を許可している場合、ウェブサイトは、ユーザーのブラウザーからクッキーを取得できます。<br>
            なお、お客様のブラウザーは、プライバシー保護のため、そのウェブサイトのサーバーが送受信したクッキーのみを送信します。<br>
            お客様は、クッキーの送受信に関する設定を「すべてのクッキーを許可する」、「すべてのクッキーを拒否する」、「クッキーを受信したらユーザーに通知する」などから選択できます。設定方法は、ブラウザーにより異なります。クッキーに関する設定方法は、お使いのブラウザーの「ヘルプ」メニューでご確認ください。<br>
            すべてのクッキーを拒否する設定を選択されますと、認証が必要なサービスを受けられなくなる等、インターネット上の各種サービスの利用上、制約を受ける場合がありますのでご注意ください。
          </div>

          <div class="imp_subpage_text">
            <h3>7. プライバシーポリシーの変更について</h3>
            弊社は、個人情報に関して適用される日本の法令を遵守するとともに、収取する個人情報の変更、利用目的の変更など、本ポリシーの内容を適宜見直しその改善に努めます。修正された最新のプライバシーポリシーは常に本ページにて開示されます。
          </div>

          <div class="imp_subpage_text">
            <h3>8. お問い合わせ先</h3>
            個人情報の開示等のお申し出については、下記のお問い合わせ先にご連絡ください。<br>
            info@jjenglish.co.jp
          </div>
        </section>
      </template>
      <template #footer>
        <Footer />
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from '@/components/Layout.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Privacy',
  components: {
    Layout,
    Header,
    Footer,
  },
  head: {
    script: [
      {
        type: 'text/javascript',
        inner: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5HP4P8JK');
        `,
      }
    ],
  },
}
</script>
