<template>
  <div>
    <Layout>
      <template #header>
        <Header />
      </template>
      <template #contents>
        <section class="column_sp2_pc2 imp_sec">
          <h2 class="imp_h2">お問い合わせ</h2>
          <div class="imp_subpage_text">
            <div class="imp_text_center">お問い合わせは下記メールフォームよりお願いします。</div>
            <form action="php/mailform.php" method="post" id="mail_form">
              <dl>
                <dt><span class="required">必須</span>名前</dt>
                <dd class="required">
                  <input type="text" id="name_1" name="name_1" v-model="name_1" value="" @input="input1" />
                  <input type="text" id="name_2" name="name_2" v-model="name_2" value="" @input="input2" />
                  <span class="error_blank" v-show="verified && (name_1 === '' || name_2 === '')">名前が入力されていません</span>
                </dd>
                <dt><span class="optional">任意</span>ふりがな</dt>
                <dd>
                  <input type="text" id="read_1" name="read_1" v-model="read_1" value="" />
                  <input type="text" id="read_2" name="read_2" v-model="read_2" value="" />
                </dd>
                <dt><span class="required">必須</span>メールアドレス</dt>
                <dd class="required">
                  <input type="email" id="mail_address" name="mail_address" v-model="mail_address" value="" />
                  <span class="error_blank" v-show="verified && (mail_address === '')">メールアドレスが入力されていません</span>
                </dd>
                <dt><span class="required">必須</span>メールアドレス<br />(確認用)</dt>
                <dd class="required">
                  <input type="email" id="mail_address_confirm" name="mail_address_confirm" v-model="mail_address_confirm" value="" />
                  <span class="error_blank" v-show="verified && (mail_address_confirm === '')">メールアドレス(確認用)が入力されていません</span>
                  <span class="error_match" v-show="verified && (mail_address_confirm !== mail_address)">メールアドレスが一致しません</span>
                </dd>
                <dt><span class="optional">任意</span>性別</dt>
                <dd>
                  <ul>
                    <li><label><input type="radio" class="gender" name="gender" value="男性" v-model="gender" />男性</label></li>
                    <li><label><input type="radio" class="gender" name="gender" value="女性" v-model="gender" />女性</label></li>
                  </ul>
                </dd>
                <dt><span class="required">必須</span>お問い合わせの内容</dt>
                <dd class="required">
                  <textarea id="contents" name="contents" v-model="contents" cols="40" rows="5"></textarea>
                  <span class="error_blank" v-show="verified && (contents === '')">お問い合わせの内容が入力されていません</span>
                </dd>
              </dl>
              <p id="form_submit" class="imp_text_center"><input type="button" id="form_submit_button" value="送信する" @click="onClick" /></p>
            </form>
          </div>
        </section>
      </template>
      <template #footer>
        <Footer />
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from '@/components/Layout.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import * as AutoKana from 'vanilla-autokana';

let autoKana_1
let autoKana_2

export default {
  name: 'Inquiry',
  components: {
    Layout,
    Header,
    Footer,
  },
  head: {
    script: [
      {
        type: 'text/javascript',
        inner: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5HP4P8JK');
        `,
      }
    ],
  },
  data() {
    return {
      verified: false,
      name_1: '',
      name_2: '',
      read_1: '',
      read_2: '',
      mail_address: '',
      mail_address_confirm: '',
      gender: '',
      contents: '',
    }
  },
  methods: {
    input1() {
      this.read_1 = autoKana_1.getFurigana()
    },
    input2() {
      this.read_2 = autoKana_2.getFurigana()
    },
    verify() {
      this.verified = true;
      if (this.name_1 === '' ||
          this.name_2 === '' ||
          this.mail_address === '' ||
          this.mail_address_confirm === '' ||
          this.mail_address !== this.mail_address_confirm ||
          this.contents === ''
      ) {
        return false;
      } else {
        return true;
      }
    },
    submit() {
      const now_url = encodeURI(document.URL);
      const before_url = encodeURI(document.referrer);
      const payload = {
        now_url,
        before_url,
        name_1: this.name_1,
        name_2: this.name_2,
        read_1: this.read_1,
        read_2: this.read_2,
        mail_address: this.mail_address,
        gender: this.gender,
        contents: this.contents,
        javascript_action: true,
        order_1: 'text,name_1,false,名前',
        order_2: 'text,name_2,true,名前',
        order_3: 'text,read_1,false,ふりがな',
        order_4: 'text,read_2,true,ふりがな',
        order_5: 'email,mail_address,false,メールアドレス',
        order_6: 'email,mail_address_confirm,false,メールアドレス(確認用)',
        order_7: 'radio,gender,false,性別',
        order_8: 'textarea,contents,false,お問い合わせの内容',
        order_count: 8,
      };
      const body = Object.keys(payload).reduce((o,key)=>(o.set(key, payload[key]), o), new FormData());
      const headers = {
        'Accept': 'application/json'
      };
      fetch('/form/php/mailform.php', { method: 'post', headers, body })
        .then((res)=> res.json())
        .then((res) => {
          if (res.message === 'OK') {
            this.$router.push('/thanks')
          } else {
            window.alert('送信に失敗しました。')
          }
        })
        .catch(error => console.error(error));
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    onClick() {
      if (this.verify()) {
        if (window.confirm('送信してもよろしいですか？')) {
          this.submit();
          this.scrollToTop()
        }
      } else {
        this.scrollToTop()
      }
    },
  },
  mounted() {
    autoKana_1 = AutoKana.bind('#name_1')
    autoKana_2 = AutoKana.bind('#name_2')
  },
}
</script>

<style scoped lang="scss">

form#mail_form input#name_1,
form#mail_form input#read_1 {
  margin-right: 10px;
}
form#mail_form * {
  margin: 0;
  padding: 0;
}
form#mail_form {
  width: 860px;
  margin: 50px auto;
  padding: 10px 0;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 7px;
  box-shadow: 0 0 7px rgba( 0, 0, 0, 0.2 );
  font-size: 95%;
  line-height: 1.8;
}
form#mail_form dl {
  width: 90%;
  margin: 0 auto;
  overflow: hidden;
}
form#mail_form dl dt {
  clear: both;
  width: 30%;
  float: left;
  border-top: 1px solid #cccccc;
  padding: 15px 0;
  text-align: right;
  overflow: hidden;
}
form#mail_form dl dd {
  width: 65%;
  float: right;
  border-top: 1px solid #cccccc;
  padding: 15px 0 15px 5%;
}
form#mail_form dl dt:first-child,
form#mail_form dl dt:first-child + dd {
  border: none;
}
form#mail_form dl dt span {
  display: block;
  font-size: 85%;
  color: #3377ff;
}

/* -- for JavaScript ここから -------------------------------------------------------------------------------- */

form#mail_form dl dt span.required,
form#mail_form dl dt span.optional {
  display: block;
  float: left;
  color: #ffffff;
  padding: 4px 10px;
  border-radius: 3px;
}
form#mail_form dl dt span.required {
  background: #d9534f;
  border: 1px solid #d43f3a;
}
form#mail_form dl dt span.optional {
  background: #337ab7;
  border: 1px solid #2e6da4;
}
form#mail_form dl dd span.error_blank,
form#mail_form dl dd span.error_format,
form#mail_form dl dd span.error_match {
  display: block;
  color: #ff0000;
  margin-top: 3px;
}
span.loading {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-top: 5px solid rgba( 255, 255, 255, 0.2 );
  border-right: 5px solid rgba( 255, 255, 255, 0.2 );
  border-bottom: 5px solid rgba( 255, 255, 255, 0.2 );
  border-left: 5px solid #ffffff;
  -webkit-transform: translateZ( 0 );
  -ms-transform: translateZ( 0 );
  transform: translateZ( 0 );
  -webkit-animation: load-circle 1.0s linear infinite;
  animation: load-circle 1.0s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
}

@-webkit-keyframes load-circle {
  0% {
    -webkit-transform: rotate( 0deg );
    transform: rotate( 0deg );
  }
  100% {
    -webkit-transform: rotate( 360deg );
    transform: rotate( 360deg );
  }
}

@keyframes load-circle {
  0% {
    -webkit-transform: rotate( 0deg );
    transform: rotate( 0deg );
  }
  100% {
    -webkit-transform: rotate( 360deg );
    transform: rotate( 360deg );
  }
}

/* -- for JavaScript ここまで -------------------------------------------------------------------------------- */

form#mail_form input[type="text"],
form#mail_form input[type="email"],
form#mail_form input[type="tel"] {
  max-width: 90%;
  padding: 7px 2%;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background: #fafafa;
  -webkit-appearance: none;
  font-size: 100%;
  font-family: inherit;
  margin-top: 7px;
}
form#mail_form input[type="text"]:focus,
form#mail_form input[type="email"]:focus,
form#mail_form input[type="tel"]:focus,
form#mail_form textarea:focus {
  box-shadow: 0px 0px 5px #55ccff;
  border: 1px solid #55ccff;
  background: #ffffff;
}
form#mail_form ul li input[type="radio"],
form#mail_form ul li input[type="checkbox"] {
  margin-right: 10px;
  margin-top: 10px;
}
form#mail_form ul li:first-child input[type="radio"],
form#mail_form ul li:first-child input[type="checkbox"] {
  margin-top: 0px;
}
form#mail_form select {
  font-size: 100%;
  font-family: inherit;
  margin-top: 10px;
}
form#mail_form textarea {
  display: block;
  width: 90%;
  max-width: 90%;
  height: 200px;
  padding: 7px 2%;
  resize: vertical;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background: #fafafa;
  -webkit-appearance: none;
  font-size: 100%;
  font-family: inherit;
}
form#mail_form ul {
  list-style-type: none;
}
form#mail_form ul li label:hover {
  cursor: pointer;
}
form#mail_form input#company {
  width: 60%;
}
form#mail_form input#name_1,
form#mail_form input#name_2,
form#mail_form input#read_1,
form#mail_form input#read_2,
form#mail_form input#postal,
form#mail_form input#phone,
form#mail_form input#schedule {
  width: 30%;
}
form#mail_form input#mail_address,
form#mail_form input#mail_address_confirm {
  width: 80%;
}
form#mail_form input#postal + a {
  display: inline-block;
  padding: 5px 15px;
  border: 1px solid #46b8da;
  border-radius: 3px;
  background: #5bc0de;
  font-size: 100%;
  color: #ffffff;
  text-decoration: none;
}
form#mail_form input#postal + a:hover {
  cursor: pointer;
  background: #31b0d5;
  border: 1px solid #269abc;
}
form#mail_form input#address {
  width: 90%;
}
form#mail_form p#form_submit {
  width: 90%;
  margin: 0 auto;
  padding: 15px 0;
  border-top: 1px solid #cccccc;
}
form#mail_form input[type="button"] {
  padding: 7px 40px;
  border: 1px solid #4cae4c;
  border-radius: 3px;
  background: #5cb85c;
  font-size: 100%;
  color: #ffffff;
  font-family: inherit;
  -webkit-appearance: none;
}
form#mail_form input[type="button"]:hover {
  cursor: pointer;
  background: #449d44;
  border: 1px solid #398439;
}

/* -- responsive ----------------------------------------------------------------------------------------------------------------- */

/* 1000pixel start */
@media screen and ( max-width: 1000px ) {

  form#mail_form {
    width: 95%;
    font-size: 100%;
  }
  form#mail_form dl {
    overflow: visible;
  }
  form#mail_form dl dt {
    width: auto;
    float: none;
    text-align: left;
    padding: 15px 0 5px;
  }
  form#mail_form dl dd {
    width: auto;
    float: none;
    border-top: none;
    padding: 0px 0 20px 0px;
  }
  form#mail_form dl dt span {
    font-weight: normal;
  }

  /* -- for JavaScript ここから -------------------------------------------------------------------------------- */

  form#mail_form dl dt span.required,
  form#mail_form dl dt span.optional {
    margin-right: 1em;
    margin-bottom: 2em;
  }

  /* -- for JavaScript ここまで -------------------------------------------------------------------------------- */

  form#mail_form input[type="text"],
  form#mail_form input[type="email"],
  form#mail_form input[type="tel"] {
    margin-top: 0px;
  }
  form#mail_form input#postal + a {
    padding: 6px 15px 5px;
  }
  form#mail_form input#form_submit_button {
    margin-left: 0;
  }
  form#mail_form select {
    font-size: 16px;
    margin-top: 0;
  }
  form#mail_form input#phone,
  form#mail_form input#schedule {
    width: 50%;
  }
}
</style>
