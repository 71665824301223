<template>
  <section id="tc9">
    <h2 class="red">TEXT BOOK<span>必須技能が身につく</span></h2>
    <div class="container feature4">
      <p class="num"><span>BOOK 1/2/3</span></p>
      <h3>中学英語でここまで言える！ 驚きのテキスト<br>日本を再発見できる楽しさも大好評</h3>
      <p class="under">「３つの技能」を効率的に習得するための工夫が満載</p>
      <div class="title">
        <figure class="main"><img v-lazy="'/top2020/img/tc9_04_main.webp'" alt=""></figure>
        <div class="content">
          <div class="txt">
            <p class="top">［画期的な５つの特長］</p>
            <h4>１ 中学英語をフル活用した驚きの英文</h4>
            <p>英文を作成したのは日本語も堪能な３人のネイティブ。中学英語のフル活用でここまで表現できるのかと、驚くような会話文となっています。</p>
            <h4>２ 日本のよさを再発見できる楽しさがある</h4>
            <p>日本の名所や文化、流行がテーマです。ネイティブと日本人のコンビが日本各地を巡りながら、ユーモア溢れる会話を展開します。日本語を読むだけでも楽しいと多くの購入者に大好評です。</p>
            <h4>３ 便利なテンプレ表現が自然に染み込む</h4>
            <p>多くの教材では、重要な表現でも教えるのは原則１度。JJのテキストでは、そんな常識を破り、ネイティブがよく使うテンプレ表現は、その頻度に応じて何度でも登場します。便利だから何度も出てくる。何度も出てくるから重要。気がつくと、その表現がアナタに染み込んでいるのです。</p>
            <h4>４ 普通の会話をリアルに再現した長短の文</h4>
            <p>ほとんどの教材のテキストは、１５ワード程度の短い文だけで構成されています。しかしそれでは、じっさいの会話に必要なリスニング力やスピーキング力は養えません。JJのテキストは、普通の会話同様、長短織り混ざった文で構成されているので、長い会話についていける語順感覚「英語耳」が育ちます。簡単な文法を組み合わせて、複雑な事柄を話せるようにもなります。</p>
            <h4>５ 「言い換えの技術」も学べる</h4>
            <p>英文は意訳となっています。主語や動詞を置き換えたり２文に分けたりしてあります。これは、英会話の上達に必要な「言い換えの技術」を学んでもらうための工夫です。</p>
          </div>
          <figure><img v-lazy="'/top2020/img/tc9_04_01.webp'" alt=""></figure>
        </div>
      </div>
      <div class="cover inner01">
        <div class="d1 lazyload">
          <h4>実際のテキストはこうなっています！</h4>
          <p class="under">
            メインテキストは、１つのレッスンが４ページで構成されています。３冊で全120レッスン<br>
            英会話の習得に必要な目安量となる「ラジオ講座」10カ月分以上を収録
          </p>
          <div class="flexb box_cover">
            <div class="box">
              <figure class="f1"><img v-lazy="'/top2020/img/tc9_04_02.webp'" alt=""></figure>
              <p class="p1">
                最初の見開きは、日本語の原文とメインの会話英文を掲載。少し難しい表現には訳と文法の解説付き。<br>
                <img class="icon" v-lazy="'/top2020/img/trick2.webp'" alt=""><br>
                <span class="sub">
                  ・使い回しの効くテンプレ表現がいくつも挿入されています。<br>
                  ・便利な表現ほど何度も登場。 ・ユーモアも身につく。<br>
                </span>
                <span class="blue sub">［対応ソフト］……動く字幕付き動画／聞き流し用音声</span>
              </p>
              <figure class="m1" @click="isModal1Appear = true"><img v-lazy="'/top2020/img/tc9_04_03.webp'" alt=""></figure>
              <p class="p2">↑クリックして拡大する</p>
              <transition>
                <Modal v-if="isModal1Appear" @close="isModal1Appear = false">
                  <figure><img v-lazy="'/top2020/img/tc9_04_03b.webp'" alt=""></figure>
                </Modal>
              </transition>
            </div>
            <div class="box">
              <figure class="f1"><img v-lazy="'/top2020/img/tc9_04_04.webp'" alt=""></figure>
              <p class="p1">
                次の見開きでは、前ページの最重要表現（テンプレ表現中心）の応用例文を数多く掲載しています。<br>
                <img class="icon" v-lazy="'/top2020/img/trick2.webp'" alt=""><br>
                <span class="sub">
                  ・先生の「レクチャー動画」を図式でフォロー解説<br>
                  ・日本独特の物を英語で言う単語集「これ何ていうの？」付き。<br>
                </span>
                <span class="blue sub">［対応ソフト］……先生のレクチャー動画／生声２段階スピード音声</span>
              </p>
              <figure class="m2" @click="isModal2Appear = true"><img v-lazy="'/top2020/img/tc9_04_05.webp'" alt=""></figure>
              <p class="p2">↑クリックして拡大する</p>
              <transition>
                <Modal v-if="isModal2Appear" @close="isModal2Appear = false">
                  <figure><img v-lazy="'/top2020/img/tc9_04_05b.webp'" alt=""></figure>
                </Modal>
              </transition>
            </div>
          </div>
          <div class="flexb example lazyload">
            <div class="txt">
              <h5>日本を題材としたテーマの一例</h5>
              <dl>
                <dt>日本の世界遺産と観光名所</dt>
                <dd>浅草・富士山・清水寺・秋葉原・嚴島神社・伏見稲荷大社・白川郷と五箇山・谷根千・原宿・新宿ゴールデン街（他全40レッスン）</dd>
              </dl>
              <dl>
                <dt>人気の日本グルメ</dt>
                <dd>焼き鳥・天ぷら・江戸前寿司・すき焼き・刺身・しゃぶしゃぶ・おでん・そば・焼き肉・餃子（他全40レッスン）</dd>
              </dl>
              <dl>
                <dt>日本のポップカルチャー</dt>
                <dd>ドン・キホーテ・ラーメンの聖地・メイドカフェ・家電量販店・マンガ喫茶・100円ショップ・食品サンプル・アメ横・東京スカイツリー・回転寿司（他全40レッスン）</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container feature5">
      <p class="num"><span>BOOK 4</span></p>
      <h3>外国人お助け英会話</h3>
      <p class="under">海外旅行にも応用できる、バッチリ役立つ実用会話テキスト<br>
        <img class="icon" v-lazy="'/top2020/img/7_exercise.webp'" alt="">
      </p>
      <div class="title">
        <figure class="main"><img v-lazy="'/top2020/img/tc9_05_main.webp'" alt=""></figure>
        <div class="txt">
          <p>
            テキストの4冊目となるBOOK４には、空港や駅、観光名所、ショッピングなど、シチュエーション別の「外国人お助け英会話」を収録しています。<span class="marker2">おもてなし英語として使えるのはもちろん、自身が海外旅行へ行く際にもバッチリ役立つ英会話集です。</span><br>
            <span class="blue">★パワー音読などでマスターします。</span>
          </p>
          <h4>外国人お助け英会話のテーマの例</h4>
          <p>羽田空港にて／駅の改札口で／タクシーに乗る／抹茶と和菓子をいただく／温泉で／お寺で／神社で／お祭りで／写真を撮る／様々な場面でのあいさつ／ホテルで／レストランで／買い物／道案内（他全40レッスン）</p>
        </div>
      </div>
      <div class="cover inner01">
        <figure class="f1"><img v-lazy="'/top2020/img/tc9_05_01.webp'" alt="" class="pc_only"><img v-lazy="'/top2020/img/sp_tc9_05_01.webp'" alt="" class="sp_only"></figure>
        <p class="under">空港、駅などシチュエーション別の会話例文集です</p>
        <figure class="f2" @click="isModal3Appear = true"><img v-lazy="'/top2020/img/tc9_05_02.webp'" alt=""></figure>
        <p class="p1">↑クリックして拡大する</p>
        <p class="p2"><span class="blue sub">［対応ソフト］……安河内先生の解説動画／音読＆聞き流し用音声</span></p>
        <transition>
          <Modal v-if="isModal3Appear" @close="isModal3Appear = false">
            <figure><img v-lazy="'/top2020/img/tc9_05_02b.webp'" alt=""></figure>
          </Modal>
        </transition>
      </div>
    </div>
    <div class="container feature6">
      <p class="num"><span>ANOTHER BOOK</span></p>
      <h3>「発音のツボ」エクササイズ</h3>
      <p class="under">音声変化や発音の急所を練習できる<br>
        <img class="icon" v-lazy="'/top2020/img/7_exercise.webp'" alt="">
      </p>
      <div class="title">
        <figure class="main"><img v-lazy="'/top2020/img/tc9_06_main.webp'" alt=""></figure>
        <div class="txt">
          <p>日本人が不得意とする発音とリスニング。その最大の原因は、英語特有の「音の脱落」や「連結」などの音声変化を学んでいないからです。Good morning→グッモーニン（消える音＝脱落） や。for a moment→ファウラ（繋がる音＝連結）など、音声変化のルールを知って、実際に口に出す練習を積み重ねます。<span class="marker2">自分で言える音は必ず聞き取れます。逆もまた同様。</span>音の脱落や連結をマスターできると、自分の英語が流暢になるだけでなく、早口の英語が劇的に聞き取れるようになります。聞けるから言える。<span class="marker2">そんな相乗効果で上達が一気に加速していくのです。</span></p>
          <p>
            JJ ENGLISHエクササイズでは、付属のマニュアル冊子（「A to Z」）に特別に発音レクチャーのページを設け、<span class="marker2">音の脱落や連結など、最短距離で正しい発音に近づくための５つの秘訣と、重点的に練習したい子音の発音などをまとめています。</span>それらを見ながら、ネイティブが話す実際の音声を聞いてエクササイズします。<br>
            <span class="blue">★すべてのエクササイズに活用します。</span>
          </p>
          <p class="kome">※ネイティブの音声には、「いろいろな英語」に慣れるように、教材全体を通して、アメリカ東部、アメリカ南部、オーストラリア、イギリス（一部）出身者の英語が収録されています。</p>
        </div>
      </div>
      <div class="cover inner01">
        <figure class="f1"><img v-lazy="'/top2020/img/tc9_06_01.webp'" alt="" class="pc_only"><img v-lazy="'/top2020/img/sp_tc9_06_01.webp'" alt="" class="sp_only"></figure>
        <div class="float">
          <figure class="f2"><img v-lazy="'/top2020/img/tc9_06_02.webp'" alt=""></figure>
          <div class="txt">
            <h4>カタカナ表記と生音声を真似します</h4>
            <p>
              ・「繋がる音」をマスターする！<br>
              <ul class="words">
                <li>at school<br>アッスクーl</li>
                <li>on it<br><span class="red">オ</span>ニt</li>
                <li>in an hour<br>イン<span class="red">ナ</span>ンナウァ</li>
              </ul>
              <br>
              ・「消える音（脱落する音）」を知る！<br>
              <ul class="words">
                <li>Goo<span class="red">d</span> mornin<span class="red">g</span>!<br>グッモーニン</li>
                <li>ba<span class="red">d</span> condition<br>バッコンディション</li>
              </ul>
              <br>
              ・「短縮形の砕けた音」を知る！<br>
              <ul class="words">
                <li><span class="red">gonna</span>（going to）ガナ</li>
                <li><span class="red">kinda</span>（kind of）カインダ</li>
              </ul>
              <br>
              <span class="blue">（テキストより、ごく一部を紹介）</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <slot name="bt_purchase" />
  </section>
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
  name: 'Tc9',
  components: {
    Modal,
  },
  data() {
    return {
      isModal1Appear: false,
      isModal2Appear: false,
      isModal3Appear: false,
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";
@include fade_animation;

#tc9 {
  padding-top: 120px;
}
#tc9 p.num {
  margin-bottom: 20px;
}
#tc9 p.num span {
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fe3340;
  font-weight: 700;
  font-size: 32px;
  font-family: 'Roboto Condensed', sans-serif;
  height: 100%;
  box-sizing: border-box;
}
#tc9 h3 {
  text-align: center;
  font-size: 42px;
  font-weight: 700;
  color: #fe3340;
  margin-bottom: 40px;
}
#tc9 .container {
  padding-bottom: 120px;
}
#tc9 .container p.under {
  margin-bottom: 70px;
	font-size:26px;
	line-height: 40px;
	font-weight: 700;
}
#tc9 .title {
  position: relative;
}
#tc9 .title figure.main {
  padding: 0 50px;
}
#tc9 .title figure.sub {
  width: 30%;
  position: absolute;
  left: calc(50% - 500px);
  top: 39vw;
}
#tc9 .title .txt {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 70px;
  width: 500px;
  margin: -400px calc(50% - 500px) 40px auto;
  position: relative;
  border-radius: 10px;
}
#tc9 .feature1 .title .txt {
  padding-bottom: 140px;
}
#tc9 .feature5 .title .txt {
  margin: -250px calc(50% - 500px) 40px auto;
}
#tc9 .title .txt p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
#tc9 .title .txt .blue {
  color: #0a74d7;
}
#tc9 .title .txt p.kome {
  font-size: 12px;
  line-height: 16px;
  &.red {
    color: #fe3340;
  }
}
#tc9 .title .txt p:last-child {
  margin-bottom: 0;
}
#tc9 figure.icon {
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
  margin-bottom: 70px;
}
#tc9 img.icon {
  height: 30px !important;
  width: auto;
  margin-top: 10px;
}
@media only screen and (max-width: 768px) {
  #tc9 {
      padding-top: 70px;
  }
  #tc9 p.num {
    padding: 3px;
    width: 50px;
    height: 50px;
    margin: 0 auto 20px auto;
  }
  #tc9 p.num span {
    font-size: 19px;
  }
  #tc9 h3 {
    font-size: 23px;
    margin-bottom: 20px;
  }
  #tc9 .title {
    margin-bottom: 30px;
  }
  #tc9 .title figure.main {
    padding: 0 20px;
    margin-bottom: 110px;
  }
  #tc9 .title figure.sub {
    width: 35%;
    left: 0;
    right: 0;
    top: 30vw;
    margin: 0 auto;
  }
  #tc9 .title .txt {
    padding: 0 20px;
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
  #tc9 .feature5 .title .txt {
    margin: 0;
  }
  #tc9 .title .txt p {
    font-size: 14px;
    line-height: 24px;
  }
  #tc9 .container{
    padding-bottom: 50px;
  }
}
/*-----------------------------------------------------------*/
/*--------------------------feature1-------------------------*/
/*-----------------------------------------------------------*/
#tc9 .feature1 .d1 {
  position: relative;
  margin-bottom: 50px;
}
#tc9 .feature1 .d1 figure {
  width: 370px;
  position: absolute;
  top: -40px;
  left: 0;
}
#tc9 .feature1 .d1 figure.float {
  width: auto;
  max-width: 500px;
  position: static;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}
#tc9 .feature1 .d1 .catch {
  text-align: center;
  margin-bottom: 20px;
}
#tc9 .feature1 .d1 .catch p.speech {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  color: #fe3340;
  line-height: 1.5;
  position: relative;
}
#tc9 .feature1 .d1 .catch p.speech::before {
  content: '';
  display: inline-block;
  width: 24px;
  height: 50px;
  background: url("/top2020/img/fukidashi_left.webp") no-repeat;
  background-size: contain;
  position: absolute;
  left: -40px;
  bottom: -5px;
}
#tc9 .feature1 .d1 .catch p.speech::after {
  content: '';
  display: inline-block;
  width: 24px;
  height: 50px;
  background: url("/top2020/img/fukidashi_right.webp") no-repeat;
  background-size: contain;
  position: absolute;
  right: -40px;
  bottom: -5px;
}
#tc9 .feature1 .d1 .txt {
  &.lazyloaded {
    background-image: url("/top2020/img/tc9_01_bg1.webp");
  }
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  margin: 0 0 0 85px;
  padding: 60px 70px 70px 340px;
}
#tc9 .feature1 .d1 .txt h4 {
  font-size: 20px;
  line-height: 36px;
  font-weight: 700;
  color: #fe3340;
  margin-bottom: 40px;
}
#tc9 .feature1 .d1 .txt p {
  font-size: 16px;
  line-height: 24px;
}
#tc9 .feature1 .d2 {
  margin-bottom: 60px;
}
#tc9 .feature1 .d2 h4 {
  height: 70px;
  border: 2px solid #fe3340;
  color: white;
  background-color: #fe3340;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 100px;
  margin-bottom: 30px;
}
#tc9 .feature1 .d2 .under {
  font-size: 16px;
  line-height: 24px;
}
#tc9 .feature1 .d2 .flexb figure {
  width: 24%;
}
#tc9 .feature1 .d2 .flexb .kome {
  padding-left: 15%;
  font-size: 12px;
}
#tc9 .feature1 .d3 {
  padding: 70px 85px;
  &.lazyloaded {
    background-image: url("/top2020/img/tc9_01_bg2.webp");
  }
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
#tc9 .feature1 .d3 h4 {
  font-size: 24px;
  text-align: center;
  line-height: 40px;
  border-top: 2px dotted;
  border-bottom: 2px dotted;
  margin-bottom: 50px;
}
#tc9 .feature1 .d3 strong {
  font-size: 36px;
  line-height: 50px;
  color: #fe3340;
  text-align: center;
  display: block;
  margin-bottom: 40px;
}
#tc9 .feature1 .d3 .flexb .txt {
  width: 60%;
}
#tc9 .feature1 .d3 .flexb .txt p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
#tc9 .feature1 .d3 .flexb .txt p:last-child {
  margin-bottom: 0;
}
#tc9 .feature1 .d3 .flexb .img {
  width: 37%;
}
#tc9 .feature1 .d3 .flexb .img figure {
  width: 270px;
  margin: 0 auto 40px auto;
}
#tc9 .feature1 .d3 .flexb .img p {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  #tc9 .feature1 .d1 {
    border-top: 1px solid transparent;
  }
  #tc9 .feature1 .d1 figure {
    width: 180px;
    top: 0px;
    right: 0;
    margin: 0 auto;
  }
  #tc9 .feature1 .d1 .catch p.speech {
    font-size: 14px;
  }
  #tc9 .feature1 .d1 figure.float {
    max-width: 300px;
  }
  #tc9 .feature1 .d1 .txt {
    &.lazyloaded {
      background-image: url("/top2020/img/sp_tc9_01_bg1.webp");
    }
    margin: 60px 0 0 0;
    padding: 140px 20px 40px 20px;
  }
  #tc9 .feature1 .d1 .txt h4 {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  #tc9 .feature1 .d1 .txt p {
    font-size: 14px;
    line-height: 24px;
  }
  #tc9 .feature1 .d2 {
    margin-bottom: 20px;
  }
  #tc9 .feature1 .d2 h4 {
    height: 35px;
    font-size: 16px;
    border-radius: 50px;
    margin-bottom: 20px;
  }
  #tc9 .feature1 .d2 .under {
    margin-bottom: 20px;
  }
  #tc9 .feature1 .d2 .flexb figure {
    width: 48%;
    margin-bottom: 20px;
  }
  #tc9 .feature1 .d3 {
    padding: 30px 20px;
    &.lazyloaded {
      background-image: url("/top2020/img/sp_tc9_01_bg2.webp");
    }
  }
  #tc9 .feature1 .d3 h4 {
    font-size: 15px;
    line-height: 24px;
    padding: 10px 0;
    margin-bottom: 10px;
  }
  #tc9 .feature1 .d3 strong {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 30px;
  }
  #tc9 .feature1 .d3 .flexb .txt {
    width: 100%;
  }
  #tc9 .feature1 .d3 .flexb .img {
    width: 100%;
    order: -1;
    margin-bottom: 25px;
  }
  #tc9 .feature1 .d3 .flexb .img figure {
    width: 200px;
    margin: 0 auto 20px auto;
  }
  #tc9 .feature1 .d3 .flexb .txt p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
/*-----------------------------------------------------------*/
/*--------------------------feature2-------------------------*/
/*-----------------------------------------------------------*/
#tc9 .feature2 {
  padding-bottom: 200px;
}
#tc9 .feature2 .txt .red {
  color: #fe3340;
  font-weight: 700;
}
@media only screen and (max-width: 768px) {
  #tc9 .feature2 {
    padding-bottom: 50px;
  }
}
/*-----------------------------------------------------------*/
/*--------------------------feature3-------------------------*/
/*-----------------------------------------------------------*/
#tc9 .feature3 .d1 {
  padding: 60px 85px;
  &.lazyloaded {
    background-image: url("/top2020/img/tc9_03_bg.webp");
  }
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
#tc9 .feature3 .d1 h4 {
  height: 70px;
  background-color: #fe3340;
  color: #fff;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 100px;
  margin-bottom: 30px;
}
#tc9 .feature3 .d1 .under {
  font-size: 16px;
  line-height: 24px;
}
#tc9 .feature3 .d1 .box {
  margin-bottom: 90px;
}
#tc9 .feature3 .d1 .box .txt {
  width: 45%;
}
#tc9 .feature3 .d1 .box .txt .example {
  border: solid 1px black;
  padding: 10px;
  margin-top: 10px;
}
#tc9 .feature3 .d1 .box .txt h5 {
  font-size: 24px;
  line-height: 30px;
  color: #fe3340;
  font-weight: 700;
  margin-bottom: 30px;
}
#tc9 .feature3 .d1 .box .txt h5 span {
  display: block;
  font-size: 16px;
}
#tc9 .feature3 .d1 .box .txt p {
  font-size: 16px;
  line-height: 24px;
}
#tc9 .feature3 .d1 .box .figure-wrapper {
  width: 50%;
  figure {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 768px) {
  #tc9 .feature3 .d1 {
    padding: 20px;
    &.lazyloaded {
      background-image: url("/top2020/img/sp_tc9_03_bg.webp");
    }
  }
  #tc9 .feature3 .d1 h4 {
    height: 65px;
    font-size: 15px;
    display: flex;
    border-radius: 50px;
    margin-bottom: 20px;
  }
  #tc9 .feature3 .d1 .box {
    padding: 0;
    margin-bottom: 40px;
  }
  #tc9 .feature3 .d1 .box .txt {
    width: 100%;
    margin-bottom: 20px;
  }
  #tc9 .feature3 .d1 .box .figure-wrapper {
    width: 100%;
  }
}
/*-----------------------------------------------------------*/
/*--------------------------feature4-------------------------*/
/*-----------------------------------------------------------*/
#tc9 .feature4 .cover > figure {
  margin-bottom: 40px;
}
#tc9 .feature4 .title {
  .content {
    display: flex;
    justify-content: space-between;
    flex-flow: row-reverse nowrap;
    figure {
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 80px;
      margin-bottom: 80px;
      text-align: center;
      img {
        width: 90%;
      }
    }
  }
}
#tc9 .feature4 .title .txt {
  flex-shrink: 0;
}
#tc9 .feature4 .title .txt .top {
  font-weight: bold;
  text-align: center;
}
#tc9 .feature4 .title .txt h4 {
  color: #fe3340;
}
#tc9 .feature4 .d1 {
  padding: 60px 40px;
  &.lazyloaded {
    background-image: url("/top2020/img/tc9_03_bg.webp");
  }
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
#tc9 .feature4 .d1 h4 {
  height: 70px;
  border: 2px solid #fe3340;
  color: #fe3340;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 100px;
  margin-bottom: 30px;
}
#tc9 .feature4 .d1 .under {
  font-size: 16px;
  line-height: 24px;
}
#tc9 .feature4 .box_cover {
  margin-bottom: 75px;
}
#tc9 .feature4 .box {
  width: 48%;
}
#tc9 .feature4 .box .f1 {
  margin-bottom: 20px;
}
#tc9 .feature4 .box .p1 {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}
#tc9 .feature4 .box .p2 {
  font-size: 16px;
  line-height: 24px;
  text-align: right;
}
#tc9 .feature4 .box .p1 .sub {
  font-size: 14px;
}
#tc9 .feature4 .box .p1 .sub.blue {
  color: #0a74d7;
}
#tc9 .feature4 .example {
  &.lazyloaded {
    background-image: url("/top2020/img/tc9_04_07.webp");
  }
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  padding-left: 25%;
  border-radius: 10px;
}
#tc9 .feature4 .example .txt {
  padding: 50px 75px;
  background-color: rgba(255, 255, 255, 0.9);
}
#tc9 .feature4 .example .txt h5 {
  font-size: 24px;
  text-align: center;
  line-height: 60px;
  border-top: 2px dotted;
  border-bottom: 2px dotted;
  margin-bottom: 50px;
}
#tc9 .feature4 .example .txt dl {
  margin-bottom: 30px;
}
#tc9 .feature4 .example .txt dl dt {
  font-size: 16px;
  line-height: 24px;
  color: #fe3340;
  font-weight: 700;
}
#tc9 .feature4 .example .txt dl dd {
  font-size: 16px;
  line-height: 24px;
}
#tc9 .feature4 img.icon {
  height: 28px !important;
  margin-top: 6px;
  margin-bottom: 2px;
}
@media only screen and (max-width: 768px) {
  #tc9 .feature4 .title {
    .content {
      display: block;
    }
  }
  #tc9 .feature4 .d1 {
    background: none;
    padding: 0;
  }
  #tc9 .feature4 .d1 h4 {
    height: 35px;
    font-size: 16px;
    border-radius: 50px;
    margin-bottom: 10px;
  }
  #tc9 .feature4 .box {
    width: 100%;
    margin-bottom: 20px;
  }
  #tc9 .feature4 .example {
    &.lazyloaded {
      background-image: url("/top2020/img/tc9_04_07.webp");
    }
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    padding-left: 0;
    padding-top: 160px;
    border-radius: 10px;
  }
  #tc9 .feature4 .example .txt {
    padding: 20px 20px;
  }
  #tc9 .feature4 .example .txt h5 {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 20px;
    padding: 10px 0;
  }
}
/*-----------------------------------------------------------*/
/*--------------------------feature5-------------------------*/
/*-----------------------------------------------------------*/
#tc9 .feature5 figure.f1 {
  margin-bottom: 30px;
}
#tc9 .feature5 .under {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
#tc9 .feature5 figure.f2 {
  width: 70%;
  margin: 0 auto 10px auto;
}
#tc9 .feature5 .txt h4 {
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  margin-bottom: 20px;
}
#tc9 .feature5 .p1 {
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  width: 70%;
  margin: 0 auto;
}
#tc9 .feature5 .p2 {
  line-height: 24px;
  width: 70%;
  margin: 0 auto;
}
#tc9 .feature5 .p2 .sub {
  font-size: 14px;
}
#tc9 .feature5 .p2 .sub.blue {
  color: #0a74d7;
}
@media only screen and (max-width: 768px) {
  #tc9 .feature5 .txt h4 {
    font-size: 14px;
    line-height: 24px;
    text-decoration: underline;
    margin-bottom: 10px;
  }
  #tc9 .container p.under {
    font-size: 14px;
    line-height: 24px;
    padding: 0;
    margin-bottom: 20px;
  }
  #tc9 .feature5 figure.f1 {
    margin-bottom: 10px;
  }
  #tc9 .feature5 figure.f2 {
    width: 100%;
  }
  #tc9 .feature5 .p1,
  #tc9 .feature5 .p2 {
    width: 100%;
    font-size: 13px;
    line-height: 20px;
  }
}
/*-----------------------------------------------------------*/
/*--------------------------feature6-------------------------*/
/*-----------------------------------------------------------*/
#tc9 .feature6 figure.f1 {
  margin-bottom: 30px;
}
#tc9 .feature6 figure.f2 {
  width: 70%;
  margin: 0 auto 10px auto;
}
#tc9 .feature6 .float {
  overflow: hidden;
}
#tc9 .feature6 .float h4 {
  margin-bottom: 10px;
}
#tc9 .feature6 .float p {
  font-size: 16px;
  overflow: hidden;
}
#tc9 .feature6 .float p .red {
  color: #fe3340;
}
#tc9 .feature6 .float p .blue {
  color: #0a74d7;
}
#tc9 .feature6 .float figure.f2 {
  width: 50%;
  float: left;
  margin-right: 30px;
}
#tc9 .feature6 .words {
  overflow: hidden;
  li {
    float: left;
    font-size: 16px;
    margin-right: 30px;
  }
}
@media only screen and (max-width: 768px) {
  #tc9 .feature6 .float figure.f2 {
    width: 100%;
  }
  #tc9 .feature6 .float figure.f2 {
    float: none;
  }
}
::v-deep .purchase-button {
  margin-bottom: 120px;
}
</style>
