<template>
  <div class="u-mt20 c-linkblock">
    <div class="u-c-a">
      <h3 class="u-mb20">英語学習に関するお役立ち情報</h3>
      <ol class="c-pagelink">
        <li><a :href="`${basePath}column/recommended-selftaught-englishconversation`">独学で英会話を学ぶメリット おすすめの販売教材を紹介 まずは問い合わせを</a></li>
        <li><a :href="`${basePath}column/application-selftaught-englishconversation`">独学で英会話を習得するコツとは？ 教材のサンプル視聴や申し込みはお気軽に</a></li>
        <li><a :href="`${basePath}column/popular-englishteachingmaterials-selfenlightenment`">英語教材で自己啓発を目指そう！シニアにも人気の英語学習 英語教材のメリットをランキング形式で紹介</a></li>
        <li><a :href="`${basePath}column/reputation-englishteachingmaterials-selfenlightenment`">自己啓発に英語教材の利用がおすすめ！口コミや評判から最適な教材を選ぼう</a></li>
        <li><a :href="`${basePath}column/smartphone-englishlearning-handtool`">英語学習は手元ツール（スマホなどのモバイル端末）で見られる動画が便利 ゴールを設定して学習を</a></li>
        <li><a :href="`${basePath}column/download-englishlearning-handtool`">英語学習は手元ツールを使って効率アップ！スマホ対応教材をダウンロードしよう</a></li>
        <li><a :href="`${basePath}column/englishspoken-englishteachingmaterials-video`">英語教材の動画で飽きない方法とは？エクササイズでネイティブに通じる英語を学ぼう</a></li>
        <li><a :href="`${basePath}column/englishskills-englishteachingmaterials-video`">英語教材動画に飽きないためには目標設定が重要！英語力を鍛えるには空き時間の有効活用を</a></li>
        <li><a :href="`${basePath}column/famousinstructor-englishconversation-readingaloud`">英会話の音読用教材の選び方とは？有名講師の返金保証ありレッスンなら</a></li>
        <li><a :href="`${basePath}column/listening-englishconversation-readingaloud`">英会話に音読学習が役立つ理由とは？音読はリスニング力やスピーキング力の向上にも効果的</a></li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColumnLinkBlock',
  computed: {
    basePath() {
      return this.$store.state.basePath
    }
  }
}
</script>
