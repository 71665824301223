<template>
  <section id="tc5" class="lazyload">
    <figure class="f1"><img v-lazy="'/top2020/img/tc5_01.webp'" alt=""></figure>
    <h2 id="review" class="red">REVIEW<span>実践者の体験談</span></h2>
    <strong class="black_red"><span class="red">崖っぷち</span>からの<span class="red">大逆転</span><br>そんな感激の声が続々！</strong>
    <p class="under">30日の実践で効果を感じた人 97.9％</p>
    <div class="container inner01">
      <div class="box">
        <figure><img v-lazy="'/top2020/img/tc5_04.webp'" alt=""></figure>
        <div class="txt" :class="{ expanded: expanded_review_3 }">
          <p class="p1">
            もっと早く出会いたかった！<br>
            自分でも感動の展開でした。
          </p>
          <h3>M・Tさん<span>神奈川県・30代・女性</span></h3>
          <p class="p2">これまで、聞き流す教材や音声変化を学べる教材をやったのですが、まったく効果なし。でも、中学英語で大丈夫というこの教材を知って、今度こその気持ちで挑戦しました。</p>
          <p class="p2">根がマジメなので（笑）、推奨のエクササイズを忠実に続けていたところ、<span class="marker2">２週間目くらいに、ドラマのセリフが部分的に聞き取れてドキドキ</span>。</p>
          <p class="p2">１カ月たったころ、<span class="marker2">いくつかのフレーズを独り言のように口で言ってる自分を発見（アンビリーバブル！）。</span>通じるかどうか試したくなって、以前まったく講師の英語がチンプンカンプンだったオンライン英会話に再挑戦。するとまたまたびっくり！ ８割くらい聞き取れた上に、質問にもけっこう答えられたんです。</p>
          <p class="p2"><span class="marker2">たどたどしくても自分のナマの英語が見知らぬ遠い国の人に通じた感動、楽しさは自分の想像以上でした。</span></p>
          <p class="p2">必要なのは、しっかり口や耳を使うエクササイズだったんです。<br>私のように、ムリかもとあきらめかけている人に、ぜひ試してほしい教材です！</p>
          <div class="mask" v-show="!expanded_review_3"></div>
          <div class="mask2" v-show="!expanded_review_3"></div>
        </div>
        <div class="more-wrapper" v-show="!expanded_review_3">
          <div class="more"><a href="#" @click.prevent="expanded_review_3 = true">続きを読む</a></div>
        </div>
        <p class="after">※感想はユーザーの主観に基づくもので、学習効果には個人差があります。</p>
      </div>
      <div class="box">
        <figure><img v-lazy="'/top2020/img/tc5_07.webp'" alt=""></figure>
        <div class="txt" :class="{ expanded: expanded_review_5 }">
          <p class="p1">
            オンライン英会話＋<br>
            「JJ」が大正解でした。
          </p>
          <h3>Y・Nさん<span>福岡県・30代・女性</span></h3>
          <p class="p2">オンライン英会話を始めて半年。上達できなくて悩んでいました。このままだとすべてがムダになると思い、32,450円を追加投入！（汗）。<span class="red">※現在は期間限定価格26,950円となっています。</span></p>
          <p class="p2">するとあらまあビックリ！ 始めて２週間くらいで、オンライン英会話でけっこう話せたのです。講師も「今日は調子がいいね」とビックリです（笑）。</p>
          <p class="p2">私的には、<span class="marker2">オンライン英会話は「練習試合」。JJは「日々の練習」という意識で両方を続けました。</span>それからというもの、それまでの半年がウソのように自分の英語が伸びていくのがわかりました。</p>
          <p class="p2">いま振り返ると、25分のレッスンが週に２回。私が英語で話しているのはせいぜい10分程度。<span class="marker2">それでは上達できないのが当然でした。</span></p>
          <p class="p2">先日、外国の方に英語で道を聞かれたのですが、ビビらないで教えてあげられました。すごく自信になりました。思わずニヤつくほどワクワクしました。</p>
          <div class="mask" v-show="!expanded_review_5"></div>
          <div class="mask2" v-show="!expanded_review_5"></div>
        </div>
        <div class="more-wrapper" v-show="!expanded_review_5">
          <div class="more"><a href="#" @click.prevent="expanded_review_5 = true">続きを読む</a></div>
        </div>
        <p class="after">※感想はユーザーの主観に基づくもので、学習効果には個人差があります。</p>
      </div>
      <div class="box">
        <figure><img v-lazy="'/top2020/img/tc5_02.webp'" alt=""></figure>
        <div class="txt" :class="{ expanded: expanded_review_6 }">
          <p class="p1">
            中学英語の「使い方」が<br>
            イヤでも刷り込まれます。
          </p>
          <h3>ツトムさん<span>東京都・40代・男性</span></h3>
          <p class="p2">「いま初めて知りました」って英語でどう言えばいいかわかりますか？ なんと、I didn’t know that.と言えばいいんですね。過去形にそんな使い方があるなんて、僕は知りませんでした。</p>
          <p class="p2">とにかくレッスンを進めるたびに目からウロコの連続です。学校で習った英語は使えないとよくいいますが、<span class="marker2">じつは「使い方」を習っていなかっただけ</span>だとしみじみ思いました。</p>
          <p class="p2">この教材では、先生たちのレクチャ−動画や音読などのエクササイズで、中学英語の文法や表現の<span class="marker2">「使い方」や「使う意識」がイヤでも刷り込まれますよ。</span></p>
          <div class="mask" v-show="!expanded_review_6"></div>
          <div class="mask2" v-show="!expanded_review_6"></div>
        </div>
        <div class="more-wrapper" v-show="!expanded_review_6">
          <div class="more"><a href="#" @click.prevent="expanded_review_6 = true">続きを読む</a></div>
        </div>
        <p class="after">※感想はユーザーの主観に基づくもので、学習効果には個人差があります。</p>
      </div>
      <div class="box-wrapper" v-show="expanded">
        <div class="box">
          <figure><img v-lazy="'/top2020/img/tc5_03.webp'" alt=""></figure>
          <div class="txt" :class="{ expanded: expanded_review_1 }">
            <p class="p1">外国の友だち3人と<br>シンプル英語で交流中！</p>
            <h3>ruruさん<span>兵庫県・20代・女性</span></h3>
            <p class="p2">パワー音読がきつく感じたのは最初の1週間だけでした。それからは、最初よりスラスラ読めるのがうれしくて感情音読だけで10回とか読んでいます。<span class="marker2">始めて約1カ月たちましたが、聞き取りがずいぶん楽になってきた</span>ような気がします。</p>
            <p class="p2">英語の文が、以前よりスラスラ思いつけるようにもなってきました。日本語を直訳的に英作するのではなく、<span class="marker2">「こんな風に言えば同じこと言えるんじゃない？」と思いつくようになってきたんです。</span></p>
            <p class="p2">☆☆その後ruruさんから追伸メールが届きました。</p>
            <p class="p2">レッスン開始から３カ月目に外国人が集まるサークルに参加。いまでは、マレーシア人、カナダ人、ロシア人の友だちができて交流を楽しんでいるとのこと。<span class="marker2">「使っている英語はもちろん全員超シンプル英語です（笑）」</span>とも。</p>
            <div class="mask" v-show="!expanded_review_1"></div>
            <div class="mask2" v-show="!expanded_review_1"></div>
          </div>
          <div class="more-wrapper" v-show="!expanded_review_1">
            <div class="more"><a href="#" @click.prevent="expanded_review_1 = true">続きを読む</a></div>
          </div>
          <p class="after">※感想はユーザーの主観に基づくもので、学習効果には個人差があります。</p>
        </div>
        <div class="box">
          <figure>
            <img v-lazy="'/top2020/img/tc5_06.webp'" alt="">
            <p class="p2 sub">ご本人の写真がNGです。代わりにS・Tさんの愛猫が登場です♡</p>
          </figure>
          <div class="txt" :class="{ expanded: expanded_review_2 }">
            <p class="p1">
              念願の外資に転職<br>
              中学英語で国際デビュー
            </p>
            <h3>S・Tさん（2020年4月ご購入）<span>埼玉県・30代・男性</span></h3>
            <p class="p2">
              学生時代にTOEICを800点以上取っていましたが、英語面接で希望の外資はすべて落ちました。スクールにも通いましたが成長できず、自分にはリスニングとスピーキングの才能が欠如しているのではないかとあきらめかけていたところ、最後の挑戦と思いこちらを購入しました。
            </p>
            <p class="p2">
              １ヵ月くらいたったある日、何十年も聞き取れなかった英語の歌や映画のセリフが、文字のようにアタマに入ってきてびっくり。<span class="marker2">「こんな簡単な英語が聞き取れていなかったのか！」</span>という不思議な気持ちにもなりました。
            </p>
            <p class="p2">
              リモート勤務をきっかけに学習を始めて７ヵ月。おかげさまで、希望していた外資系商社に転職ができました。<br>
              「中学英語を磨くのが話せるまでの最短距離」というのは本当でした。<span class="marker2">TOEICのために覚えたむずかしい単語は仕事でもたまにしか使いません。</span>先日、オンラインですけど海外の取引先と英語でしっかり打ち合わせもできました。<br>
              英語で人生が変わりました！
            </p>
            <div class="mask" v-show="!expanded_review_2"></div>
            <div class="mask2" v-show="!expanded_review_2"></div>
          </div>
          <div class="more-wrapper" v-show="!expanded_review_2">
            <div class="more"><a href="#" @click.prevent="expanded_review_2 = true">続きを読む</a></div>
          </div>
          <p class="after">※感想はユーザーの主観に基づくもので、学習効果には個人差があります。</p>
        </div>
        <div class="box">
          <figure><img v-lazy="'/top2020/img/tc5_05.webp'" alt=""></figure>
          <div class="txt" :class="{ expanded: expanded_review_4 }">
            <p class="p1">
              「パワー音読」の効果に驚愕。<br>
              テキストも計算されています。
            </p>
            <h3>K・Sさん<span>東京都・50代・男性</span></h3>
            <p class="p2"><span class="marker2">「パワー音読を採り入れたら生徒の英語力が急速に上がった」</span>という英語教師をやっている友人のススメで始めました。</p>
            <p class="p2">暗記しろとは書いてないので、とにかくスムーズに読めるようにという点だけ意識しました。Lesson20くらいまで進んだところで、<span class="marker2">いくつかのフレーズはしつこいくらい出てくるので、頭というより口が覚えてしまいます。テキストもすごく計算されているんですね。</span></p>
            <p class="p2">覚えた英語を使ってみたくなりオンライン英会話で試してみたら、普通に通じてとても自信になりました。相手の言うこともほぼ聞き取れて、終了後に思わずガッツポーズしてしまいました（笑）</p>
            <p class="p2">パワー音読の効果は驚愕ものです。<span class="marker2">リスニングもスピーキングも同時に上達します。</span>身体で覚えるのですから知識量はあまり関係ありません。難しい受験英語を学ぶより、このメソッドを学校の授業に取り入れれば、日本人はもっと英語が話せるようになると思います。</p>
            <div class="mask" v-show="!expanded_review_4"></div>
            <div class="mask2" v-show="!expanded_review_4"></div>
          </div>
          <div class="more-wrapper" v-show="!expanded_review_4">
            <div class="more"><a href="#" @click.prevent="expanded_review_4 = true">続きを読む</a></div>
          </div>
          <p class="after">※感想はユーザーの主観に基づくもので、学習効果には個人差があります。</p>
        </div>
      </div>
      <div class="more-wrapper all" v-show="!expanded">
        <div class="more"><a href="#" @click.prevent="expanded = true">体験談をもっと読む</a></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Tc5',
  data() {
    return {
      expanded: false,
      expanded_review_1: true,
      expanded_review_2: true,
      expanded_review_3: true,
      expanded_review_4: true,
      expanded_review_5: true,
      expanded_review_6: true,
      expanded_review_7: true,
    }
  }
}
</script>

<style scoped lang="scss">
#tc5 {
  margin-top: -70px;
  &.lazyloaded {
    background-image: url("/top2020/img/tc5_bg.webp");
  }
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: bottom;
  padding-bottom: 80px;
  position: relative;
  .black_red {
    font-size: 66px;
  }
}
#tc5 .f1 {
  width: 525px;
  margin: 0 auto 80px auto;
  position: relative;
  z-index: 3;
}
#tc5 strong {
  margin-bottom: 20px;
}
#tc5 .under {
  margin-bottom: 90px;
}
#tc5 .box {
  position: relative;
  margin-bottom: 120px
}
#tc5 .box:after {
  position: absolute;
  right: 20px;
  top: -10px;
  content: "Review";
  background-color: #fe3340;
  color: #fff;
  font-size: 12px;
  padding: 10px 25px;
}
#tc5 .box > figure {
  position: absolute;
  top: 80px;
  width: 210px;
  z-index: 1;
}
#tc5 .box {
  > .txt {
    position: relative;
    max-height: 610px;
    overflow: hidden;
    transition: all 0.5s;
    margin-left: 170px;
    width: 83%;
    padding: 83px;
    background-color: #fff;
    box-shadow: 0px 4px 4px #eeeeee;
    &.expanded {
      max-height: 5000px;
    }
    .mask {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 80px;
      height: 60px;
      background: linear-gradient(transparent, white);
    }
    .mask2 {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 80px;
      background-color: white;
    }
    .red {
      color: #fe3340;
    }
  }
}
.more-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
  margin-left: 170px;
  .more {
    text-align: center;
    height: 30px;
    padding-left: 170px;
    padding-right: 83px;
    margin: 0 0 0 auto;
    a {
      display: inline-block;
      color: white;
      font-size: 16px;
      font-weight: bold;
      line-height: 30px;
      height: 30px;
      border-radius: 15px;
      padding-left: 30px;
      padding-right: 30px;
      background-color: #fe3340;
    }
  }
  &.all {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    height: 60px;
    a {
      font-size: 22px;
      line-height: 60px;
      height: 60px;
      border-radius: 30px;
    }
  }
}
#tc5 .box > .txt .p1 {
  font-size: 38px;
  line-height: 58px;
  font-weight: 700;
  color: #fe3340;
  margin-bottom: 30px;
}
#tc5 .box > figure .p2 {
  font-size: 14px;
  line-height: 28px;
  margin-top: 40px;
}
#tc5 .box > .txt .p2 {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 30px;
  &.sub {
    font-size: 14px;
  }
}
#tc5 .box > .txt .p2:laft-child {
  margin-bottom: 0px;
}
#tc5 .box > .txt h3 {
  font-size: 22px;
  line-height: 24px;
  position: relative;
  padding-left: 60px;
  margin-bottom: 25px;
}
#tc5 .box > .txt h3:before {
  width: 50px;
  height: 1px;
  background-color: #000;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 15px;
}
#tc5 .box > .txt h3 span {
  font-size: 14px;
  line-height: 24px;
  display: block;
  font-weight: 400;
}
#tc5 .after {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-top: 40px;
}
@media only screen and (max-width: 768px) {
  #tc5 {
    margin-top: -30px;
    &.lazyloaded {
      background-image: url("/top2020/img/sp_tc5_bg.webp");
    }
    padding-bottom: 100px;
    .black_red {
      font-size: 20px;
    }
  }
  #tc5 .f1 {
    width: 260px;
    margin: 0 auto 40px auto;
  }
  #tc5 .box {
    border-top: 1px solid transparent;
    margin-bottom: 50px;
  }
  #tc5 .box > figure {
    position: relative;
    top: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 45%;
  }
  #tc5 .box {
    > .txt {
      max-height: 386px;
      margin-left: 0;
      margin-top: 0;
      padding: 40px 20px 40px 20px;
      width: 100%;
    }
    .more-wrapper {
      margin-left: 0;
      .more {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  #tc5 .box > .txt .p1 {
    font-size: 19px;
    line-height: 29px;
    margin-bottom: 10px;
  }
  #tc5 .box > .txt .p2 {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  #tc5 .box > .txt h3 {
    font-size: 15px;
    line-height: 24px;
    position: relative;
    padding-left: 40px;
    margin-bottom: 25px;
  }
  #tc5 .box > .txt h3:before {
    width: 30px;
    height: 1px;
    background-color: #000;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 10px;
  }
  #tc5 .box > .txt h3 span {
    font-size: 15px;
    line-height: 24px;
  }
  #tc5 .box:after {
    display: none;
  }
  #tc5 .after {
    font-size: 13px;
    line-height: 25px;
    padding: 0 20px;
  }
}
</style>
