<template>
  <section id="tc13">
    <slot name="product" />
    <slot name="bt_purchase" />
    <ShoppingGuideGold v-if="basePath === '/gold/'" id="shopping-guide" />
    <ShoppingGuide v-else id="shopping-guide" />
  </section>
</template>

<script>
import ShoppingGuide from './ShoppingGuide.vue';
import ShoppingGuideGold from './ShoppingGuideGold.vue';

export default {
  name: 'Tc13',
  components: { ShoppingGuide, ShoppingGuideGold },
  computed: {
    basePath() {
      return this.$store.state.basePath
    }
  }
}
</script>

<style scoped lang="scss">
#tc13 {
  margin-top: 80px;
  padding-bottom: 140px;
}
@media only screen and (max-width: 768px) {
  #tc13 {
    padding-bottom: 70px;
  }
}
</style>
