<template>
  <div>
    <TopLayout>
      <template #header>
        <TopHeader />
      </template>
      <template #contents>
        <div class="about">
          <figure>
            <img class="balloon" v-lazy="'/top2020/img/tc7_balloon.webp'">
            <img v-lazy="'/top2020/img/img_method03_pc.webp?date=20240107'" class="pc_only">
            <img v-lazy="'/top2020/img/img_method03_sp.webp?date=20240107'" class="sp_only">
          </figure>
          <div class="about_inner">
            <div class="block block2">
              <figure><img v-lazy="'/top2020/img/img_method05_pc.webp?date=20240107'" class="pc_only"><img v-lazy="'/top2020/img/img_method05_sp.webp?date=20240107'" class="sp_only"></figure>
              <p>
                ネイティブはもちろん、話せる人の英語を聞いていると、I think that～（～だと思うんだよね）、It seems that～（～みたいだね）、instead of～（～の代わりに）など、いくつかの中学レベルの表現が多用されることに気づきます。<br>
                このようによく使われるフレーズは、<span class="marker2">一部の単語や「〜」の部分を入れ替えると、別の意味もどんどん言える便利なテンプレ表現です。</span><br>
                <br>
                入門者が話せるようになるためには、<span class="marker2">そうした「使い回しの効くテンプレ表現」を聞けて話せるようになるのが圧倒的に近道。</span><br>
                <br>
                この教材では、そんなテンプレフレーズを中心に、<span class="marker2">360個の「便利な表現」を厳選。それらを「使いこなす」ノウハウと意識を、身体を使ったエクササイズで刷り込みます。</span><br>
                <br>
                360個も！と心配しないでください。そのほとんどは、みなさんが一度は目にしたことのあるフレーズばかりです。
              </p>
              <div class="block_inner">
                <dl>
                  <dt><img v-lazy="'/top2020/img/img_method05-1.webp'"></dt>
                  <dd>「知識」から「答え」を思い出そうとして、なかなか思い出せない。<br>正しいかどうか考えてなかなか話せない。</dd>
                </dl>
                <p class="arrow"></p>
                <dl>
                  <dt><img v-lazy="'/top2020/img/img_method05-2.webp'"></dt>
                  <dd>お決まりのフレーズがぽんと口から出る。<br>一部を入れ替えたり、それらを組み合わせて<br>テンポよく話せるようになる。</dd>
                </dl>
              </div>
            </div>
            <div class="block block1">
              <figure><img v-lazy="'/top2020/img/img_method04_pc.webp?date=20240107'" class="pc_only"><img v-lazy="'/top2020/img/img_method04_sp.webp?date=20240107'" class="sp_only"></figure>
              <p>
                短いフレーズなら聞き取れても、連続して英語を話されると耳がついていけない。自分で連続しては話せない。これは、多くの日本人がぶつかる大きな壁のひとつです。<br>
                <br>
                その原因には「音」の問題もあります。しかし、もっと根本的には、<span class="marker2">「英語独特の語順」を「知識」として覚えているだけで、耳と口が覚えていないからです。</span><br>
                <br>
                「適切な語順に単語を並べられる」。じつは、それが英語を話せるという力にほかならないのです。そして、ありがたいことに、<span class="marker2">ネイティブの会話で使われる語順や文型も、すべて中学で習ったものの組み合わせでしかないのです。</span><br>
                <br>
                この教材では、長短織り交ざった会話文のテキストを使い、口・耳・目を動かすエクササイズで、<span class="marker2">英語という言語のエッセンスである語順や基本文型を身体で覚え直します。</span><br>
                短い例文をいくら丸暗記しても、それだけでは、じっさいの会話では歯が立ちません。
              </p>
              <div class="block_inner">
                <dl>
                  <dt><img v-lazy="'/top2020/img/img_method04-1.webp'"></dt>
                  <dd>相手の言っていることが、<br>部分的にしか聞き取れない。</dd>
                </dl>
                <p class="arrow"></p>
                <dl>
                  <dt><img v-lazy="'/top2020/img/img_method04-2.webp'"></dt>
                  <dd>相手の言っていることが頭から理解できて、<br>ずっと聞き取っていける。<br>自分でも、次々と話していける。</dd>
                </dl>
              </div>
            </div>
            <div class="block block3">
              <figure><img v-lazy="'/top2020/img/img_method06_pc.webp?date=20240107'" class="pc_only"><img v-lazy="'/top2020/img/img_method06_sp.webp?date=20240107'" class="sp_only"></figure>
              <p>
                「ワザッ」「オニッ」の意味がわかりますか？ もちろん、「技」でも「鬼」でもありません。正解は、What’s that? (それは何？)と on it（それの上に）という中学１年生レベルの英語です。日本人が英語を聞き取れない大きな理由のひとつは、こうした英語特有の省略や音の脱落・連結を理解していないから。<br>
                <br>
                でも安心してください。この教材には、日本人の苦手な「発音の急所」を改善するエクササイズが用意されています。<span class="marker2">そこでつかんだスキルを、日々の音読エクササイズにしっかり反映させると、驚くほどリスニング力がつき、発話も流暢になっていきます。</span>
              </p>
              <div class="block_inner">
                <dl>
                  <dt><img v-lazy="'/top2020/img/img_method06-1.webp'"></dt>
                  <dd>ネイティブの早い会話が聞き取れない。<br>違う言葉に聞こえてしまう。</dd>
                </dl>
                <p class="arrow"></p>
                <dl>
                  <dt><img v-lazy="'/top2020/img/img_method06-2.webp'"></dt>
                  <dd>聞き取れなかったのが不思議に思えるほど、<br>早い会話でも楽に聞き取れるようになる。</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <TopFooter />
      </template>
    </TopLayout>
  </div>
</template>

<script>
import TopLayout from '@/components/TopLayout.vue'
import TopHeader from '@/components/TopHeader.vue'
import TopFooter from '@/components/TopFooter.vue'

export default {
  name: 'Tc20d',
  components: {
    TopLayout,
    TopHeader,
    TopFooter,
  },
}
</script>

<style scoped lang="scss">
.about{
  width: 100%;
  max-width: 1016px;
  margin: 0 auto 80px;
  padding: 0 50px;
  box-sizing: border-box;
  padding-top: 240px;
  > figure {
    position: relative;
    img.balloon {
      position: absolute;
      width: 30%;
      top: -40%;
      left: 10%;
    }
  }
}
.about .about_inner{
  background: url("/top2020/img/bg_method.webp") repeat;
  padding: 60px 12% 60px;
}
.about .block{
  margin-bottom: 50px;
  .blue {
    color: #0a74d7;
  }
}
.about .block:last-child{
  margin-bottom: 0;
}
.about .block > figure{
  margin-bottom: 30px;
}
.about .block > p{
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 54px;
}
.about .block_inner{
  width: 92%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.about .block_inner dl{
  width: 45%;
}
.about .block_inner dt{
  margin-bottom: 12px;
}
.about .block_inner dt img{
  width: 100%;
}
.about .block_inner dd{
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  letter-spacing: -0.05em;
}
.about .block_inner .arrow{
  height: 34px;
  display: inline-block;
  vertical-align: middle;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 24px solid #fe3340;
  margin-top: 16%;
}
@media only screen and (max-width: 768px) {
  .about{
    max-width: none;
    margin: 0 auto 36px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 140px;
  }
  .about .about_inner{
    padding: 30px 20px 30px;
  }
  .about .block{
    margin-bottom: 36px;
  }
  .about .block > figure{
    margin-bottom: 16px;
  }
  .about .block > p{
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 26px;
  }
  .about .block_inner{
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .about .block_inner dl{
    width: 100%;
  }
  .about .block_inner dt{
    width: 100%;
    margin-bottom: 12px;
  }
  .about .block_inner dt img{
    width: 100%;
  }
  .about .block_inner dd{
    font-size: 14px;
  }
  .about .block_inner .arrow{
    height: 18px;
    width: 26px;
    text-align: center;
    border-top: 18px solid #fe3340;
    border-right: 14px solid transparent;
    border-left: 14px solid transparent;
    margin-top: 18px;
    margin-bottom: 6px;
  }
}
</style>
