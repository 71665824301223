<template>
  <!-- eslint-disable no-irregular-whitespace -->
  <div>
    <Layout>
      <template #header>
        <Header />
      </template>
      <template #contents>
        <section class="column_sp2_pc2 imp_sec">
          <h2 class="imp_h2">FAQ</h2>

          <div class="imp_subpage_text">
            <h3><span class="imp_title_q">Q.</span> JJ ENGLISHエクササイズの「JJ」って何か意味がありますか？</h3>
            <span class="imp_title_a">A.</span> <span class="faq_answer_text">はい。JJには２つのメッセージが込められています。</span>
            <br><br>
            ひとつは、<span class="marker">「純ジャパ（Jun-Japa）」がつくった「純ジャパ」のための英会話教材だから。</span>純ジャパとは、日本の中学や高校でしか英語の勉強をしたことない人を指すキャンパス言葉です。国籍などは関係ありません。この教材は、そんな純ジャパの私たちが最短距離で英語を話せるようになるために開発された教材です。監修者も全員、純ジャパとして英語をものにした先生ばかり。その経験とノウハウがこの教材にはぎっしり詰まっています。<br>
            <br>
            もうひとつは、<span class="marker">「Joyful Japan」＝「楽しさに溢れた日本」を再発見、そして発信！</span>という願いが込められています。テキストのメイン会話文は、日本という国の魅力や外国人から見た日本人の姿などを再発見する楽しさを感じてもらえるはずです。そうした日本の魅力や等身大の日本人の姿をみなさんの英語で世界に発信してほしい。そんな願いが込められています。
          </div>

          <div class="imp_subpage_text">
            <h3><span class="imp_title_q">Q.</span> １日のエクササイズ時間はどのくらいですか？</h3>
            <span class="imp_title_a">A.</span> <span class="faq_answer_text">推奨しているのは、平日40分程度。週末に1時間程度です。</span>
            <br><br>
            その内訳は、３人の先生たちの「レクチャー動画」視聴（10分程度）／メイン会話文の「ワイプリスニング」（５分以上）／メイン会話文の「パワー音読」２（20分以上）です。<br>
            週末は、その他の４つのエクササイズを合計1時間程度がおススメです。<br>
            <br>
            英語のエクササイズは、毎日コツコツが基本です。仕事が忙しい時なども、「レクチャー動画」や「ワイプリスニング」だけでもやる、など、毎日５分でも10分でも、休まず継続することのが早く上達する秘訣です。<br>
            <br>
            <h4>推奨の１週間プラン</h4>
            <img src="/img/fig_12.png"><br>
            <br>
            <h4>ゆるめのプランA</h4>
            <img src="/img/plan-1.png"><br>
            <br>
            <h4>ゆるめのプランB</h4>
            <img src="/img/plan-2.png"><br>
            <br>
            <h4>お急ぎプラン</h4>
            <img src="/img/plan-3.png">
          </div>

          <div class="imp_subpage_text">
            <h3><span class="imp_title_q">Q.</span> ７つのエクササイズをすべてやらなければダメですか？</h3>
            <span class="imp_title_a">A.</span> <span class="faq_answer_text">できれば、すべてのエクササイズをオススメします。とくに「パワー音読」は必須です。</span>
            <br><br>
            英語をマスターするのに、一番大事なのは「楽しく継続すること」です。「動画レクチャーだけ」を見る。「発音レクチャー」を繰り返す。「パワー音読」のうち感情音読だけにする、などなど、<span class="marker">自分がラクで楽しいと思えるプランで学習いただいても効果はあります。途中でやめてしまうより100倍もよいことです。</span><br>
            <br>
            また、重要例文を書いて暗記する。ロールプレイングをやる。など、専用テキストを使って、自分なりのエクササイズをしていただくこともまったく問題ありません。
          </div>

          <div class="imp_subpage_text">
            <h3><span class="imp_title_q">Q.</span> 58日間全額返金保証は、テキストに書き込みなどしてあっても大丈ですか？</h3>
            <span class="imp_title_a">A.</span> はい。この返金保証は、「20Lesson以上、当教材推奨のエクササイズを実践されてもまったく効果が感じられない」という方向けのサービスとなりますので、書き込みなどがあってもご対応いたします。ただし、テキストやディスクの一部もしくは全部が紛失したという場合は返金不可とさせていただきます。
          </div>

          <div class="imp_subpage_text">
            <h3><span class="imp_title_q">Q.</span> 返品方法について教えてください</h3>
            <span class="imp_title_a">A.</span>
            返金希望の方はinfo@jjenglish.co.jpまで、件名に「返金希望」と明記の上、メール本文に下記の内容を記載し、お送りください。<br>
            ・お名前<br>
            ・メールアドレス<br>
            ・注文番号<br>
            ・返品理由（未開封や実践の形跡が認められない場合、返金をお断りする場合があります）<br>
            ・振込先の金融機関名・支店名・口座種別・口座番号・名義<br>
            <span v-if="basePath === '/gold/'" class="blue">※領収書を発行してのご購入の場合は返金できません。ご注意ください。</span>
            <span v-else class="blue">※ポイントサイトをご利用してのご購入、領収書を発行してのご購入の場合は返金できません。ご注意ください。</span>
            <br>
            ※弊社にて教材の返品を確認後、15営業日以内にお客様の口座に全額返金いたします。<br>
            ※購入時のセットが揃っている方に限ります。返品時の送料・振込手数料はお客様のご負担となります。<br>
            ※58日間の保証はご注文日からの起算となります。
          </div>
        </section>

        <section class="column_sp2_pc2 imp_sec" id="monitoring">
          <h2 class="imp_h2">「JJ ENGLISHエクササイズ」モニタリング・リポート</h2>
          <p class="sub">「JJ ENGLISHエクササイズ」の発売にあたり実施したモニタリングキャンペーンの結果を一部公開いたします。</p>

          <h3>【モニター属性】</h3>
          <p>
            ・   人数……50人（男女比２５：２５）（10代４人／20代10人／30代10人／40代10／50代10人／60代６人）<br>
            ※   インターネットを通じて募集　　※英語力については無作為抽出
          </p>

          <h3>【実施時期】</h3>
          <p>
            ・   学習期間……2019年5月1日〜2020年3月31日（内各自2〜10ヵ月学習／平均6ヵ月）<br>
            ・   結果テスト・ヒアリング日……4月10・11・17・18日<br>
            　※2020年12月1日～別途ユーザーアンケート実施
          </p>

          <h3>【実施者】</h3>
          <p>
            JJイングリッシュ社／講談社／ゼリグ　協力・セブ日本人会／マレーシア邦人会／在留リトアニア人のみなさん／立教大学・東洋大学への留学生のみなさん
          </p>

          <h3>【実施方法】</h3>
          <p>
            ・   学習については、教材を全モニターに無償配布し、なるべく推奨のエクササイズ方法や学習時間などを守って実践してもらうように依頼。各自６ヵ月のエクササイズを実践してもらった。<br>
            ・   ４月の結果確認については、メールによるアンケートの他、外国人との実際の会話を体験してもらった。<br>
            　※   各モニターに、ネイティブ（アメリカ人、オーストラリア人）と非ネイティブ（フィリピン人、マレーシア人、リトアニア人）と20分ずつ、自己紹介、雑談を行ってもらった。（※新型コロナウイルスの影響のため、オンラインを通じてのテスト、アンケートとした）<br>
            　※   コミュニケーション・テストについて、は以下5段階で２人の英語スピーカーが疎通度を採点。平均で3以上の獲得者を「コミュニケーションが取れた人」と判断した。<br>
            　　１点‥‥まったく通じなかった<br>
            　　２点‥‥あまり通じなかった<br>
            　　３点‥‥コミュニケーションがとれた<br>
            　　４点‥‥大いに通じた<br>
            　　５点‥‥まったく問題なく通じた
          </p>

          <h3>【結果】</h3>
          <p>
            得点分布と学習平均時間（週）/ 週１回以上英語を実際に使う機会をもった人の割合<br>
            ◎5点（満点）     6人‥‥‥235分（67％）<br>
            ◎4以上5点未満　12人‥‥‥222分（68％）<br>
            ◎3以上4点未満　23人‥‥‥172分（55％）<br>
            ◎2以上3点未満　 ７人‥‥‥121分（28％）<br>
            ◎1以上2点未満　 ２人‥‥‥ 78分（０％）<br>
            （3点以上＝82％）<br>
            <br>
            上達を実感したか？（本人回答／カッコ内は12月に実施したユーザーアンケート結果）<br>
            ◎   とても上達した　18人（147人）<br>
            ◎   上達した　24人（118人）<br>
            ◎   少し上達した　5人（15人）<br>
            ◎   まったく上達しなかった　0人（2人）<br>
            ◎   わからない　3人（5人）<br>
            （上達を実感した人＝97.9％）<br>
            <br>
            エクササイズは楽しかったか？（本人回答・テスト前アンケート）<br>
            ◎   とても楽しかった　17人<br>
            ◎   楽しかった　13人<br>
            ◎   続けるうちに楽しくなった　12人<br>
            ◎   あまり楽しくなかった　０人<br>
            ◎   少しきつかった　５人<br>
            ◎   きつかった　１人<br>
            ◎   途中でやめた　1人<br>
            ◎   わからない　1人
          </p>

          <h3>【３点以上のモニターに多く聞かれた感想・目立った感想】</h3>
          <p>
            ・   英語でジョークまで言えるようになった。<br>
            ・   相手の言っていることはほぼ完璧に聞き取れるようになった。<br>
            ・   海外ニュースのコメントも大体聞き取れるようになった。<br>
            ・   複雑なことを言おうとするとつまるが、相手に待ってもらい、別の言い方ができるようになった。<br>
            ・   最初の1週間、「パワー音読」がきつく感じたが、しだいに慣れて楽しくなった。<br>
            ・   最初にリスニング能力が上がり、その後にスピーキングが上達した。<br>
            ・   「My Englishバンキング」のエクササイズが予想以上に効果があった。<br>
            ・   移動の間、「ワイプリスニング」をなるべく多くやるようにした。<br>
            ・   60レッスンをこなしたあたりで、急に話せるようになってきた。<br>
            ・   100レッスンしかこなせなかったが、3点以上がとれた。<br>
            ・   安河内先生が動画の中で何度も励ましてくれたことがありがたかった。<br>
            ・   「発音レクチャー」の教えとおり、イントネーションに気をつけるようになってから急に通じるようになった。<br>
            ・   安河内先生と横手先生のアドバイスどおり、「大きな声で話す」ことが大事だと思った。<br>
            ・   ネイティブも非ネイティブも思った以上に簡単な英語をつかっていることがあらためてわかった。（以前は聞き取れなかったので気がついていなかった）<br>
            ・   お助け英会話が役に立っている。持ち歩いている。<br>
            ・   今後も続けていきたい。
          </p>

          <h3>【３点未満のモニターに多く聞かれた感想・目立った感想】</h3>
          <p>
            ・   仕事が忙しくなり、途中から学習がほとんどできなくなった。<br>
            ・   最初から完璧を目指しすぎて息切れした。<br>
            ・   じっさいに英語を使う機会がもてなかった。<br>
            ・   ３人の先生のレクチャーがたいへん参考になった。<br>
            ・   初めて英語の勉強が楽しいと思った。<br>
            ・   学習参考書などで文法の復習が大切だと思った。<br>
            ・   「恐怖の文法シアター」が参考になった。<br>
            ・   今後も続けていきたい。
          </p>
        </section>
      </template>
      <template #footer>
        <Footer />
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from '@/components/Layout.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Faq',
  components: {
    Layout,
    Header,
    Footer,
  },
  computed: {
    basePath() {
      return this.$store.state.basePath
    }
  },
  head: {
    script: [
      {
        type: 'text/javascript',
        inner: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5HP4P8JK');
        `,
      }
    ],
  },
}
</script>

<style scoped lang="scss">
.faq_answer_text {
  color: blue;
}
section {
  .blue {
    color: blue;
  }
}
#monitoring {
  background-color: rgb(230, 230, 230);
  margin-top: -60px;
}
</style>
