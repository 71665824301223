<template>
  <section id="gap">
    <slot name="bt_purchase" />
    <div class="container lazyload">
      <p><span>JJ ENGLISH エクササイズは<br>日本の学校でしか英語を学んだことがない…</span>でも、本気で話したい人に向けて開発された<br>
        特別な英会話教材です。</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Gap',
}
</script>

<style scoped lang="scss">
#gap .cv {
  margin: 100px auto;
}
#gap .container {
  height: 450px;
  &.lazyloaded {
    background-image: url("/top2020/img/gap_bg.webp");
  }
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
}
#gap .container p {
  font-size: 50px;
  line-height: 75px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}
#gap .container p span {
  font-size: 30px;
  display: block;
}
@media only screen and (max-width: 768px) {
  #gap .cv {
      margin: 30px auto 60px auto;
  }
  #gap .container {
    height: 260px;
  }
  #gap .container p {
    font-size: 25px;
    line-height: 32px;
  }
  #gap .container p span {
    font-size: 15px;
    display: block;
  }
}
</style>
