<template>
  <footer>
    <div class="inner01 flexb">
      <figure class="f_logo"><img src="/top2020/img/logo03.webp" alt=""></figure>
      <p class="p1">本気で話したいなら！<br>JJ ENGLISH エクササイズ<br>中学英語を使える英語に！</p>
      <ul class="f_nav">
        <li><a :href="`${basePath}inquiry`">お問い合わせ</a></li>
        <li><a :href="`${basePath}low`">特定取引に関する法律に基づく表示</a></li>
        <li><a :href="`${basePath}privacy`">プライバシーポリシー</a></li>
        <li><a :href="`${basePath}company`">会社情報</a></li>
        <li><a :href="`${basePath}faq`">FAQ</a></li>
      </ul>
      <div class="right">
        <ul class="flexb sns">
          <li><a href="https://www.youtube.com/channel/UCqwPqZr2YFDXpITqFhILgEA" target="_blank"><img src="top2020/img/youtube_bt.svg" alt=""></a></li>
          <li><a href="https://twitter.com/JJENGLISH14" target="_blank"><img src="top2020/img/twitter_bt.svg" alt=""></a></li>
          <li><a href="https://www.facebook.com/jjenglish2019/?modal=admin_todo_tour" target="_blank"><img src="top2020/img/facebook_bt.svg" alt=""></a></li>
        </ul>
        <p class="p1">電話でのお問い合わせ</p>
        <p class="p2">0120-197-917</p>
        <p class="p3">10:00〜18:00（土日祝休）</p>
      </div>
    </div>
    <p class="copy">独学で英会話を学べる教材なら「JJ ENGLISHエクササイズ」</p>
  </footer>
</template>

<script>
export default {
  name: 'TopFooter',
  computed: {
    basePath() {
      return this.$store.state.basePath
    }
  }
}
</script>

<style scoped lang="scss">
footer {
  background-color: #fe3340;
  padding: 60px;
}
footer .flexb > p.p1 {
  width: 170px;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
}
footer ul.f_nav li a {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
}
footer .right {
  width: 170px;
}
footer .right .sns{
	margin-bottom: 10px;
}
footer .right p.p1, footer .right p.p3 {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
}
footer .right p.p2 {
  font-size: 22px;
  line-height: 24px;
  color: #fff;
}
footer .copy {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  margin-top: 50px;
}
@media only screen and (max-width: 768px) {
  footer {
    padding: 30px;
  }
  footer .flexb {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  footer .f_logo {
    text-align: center;
    width: 100px;
    margin-bottom: 20px;
  }
  footer .flexb > p.p1 {
    margin-bottom: 30px;
  }
  footer .f_nav {
    width: 100%;
    margin-bottom: 30px;
  }
  footer .f_nav li {
    width: 100%;
  }
  footer .f_nav li a {
    text-align: center;
    display: inherit;
  }
  footer p {
    width: 100%;
    text-align: center;
  }
  footer .right {
    width: 100%;
    text-align: center;
  }
  footer .right .sns{
    display: none;
  }
  footer .right p {
    text-align: center;
  }
  footer .copy{
    font-size:11px;
  }
}
</style>
