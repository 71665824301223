<template>
  <div>
    <TopLayout>
      <template #header>
        <TopHeader />
      </template>
      <template #contents>
        <div class="container feature6">
          <h3>「発音のツボ」エクササイズ</h3>
          <p class="under">音声変化や発音の急所を練習できる<br>
            <img class="icon" v-lazy="'/top2020/img/7_exercise.webp'" alt="">
          </p>
          <div class="title">
            <figure class="main"><img v-lazy="'/top2020/img/tc9_06_main.webp'" alt=""></figure>
            <div class="txt">
              <p>日本人が不得意とする発音とリスニング。その最大の原因は、英語特有の「音の脱落」や「連結」などの音声変化を学んでいないからです。Good morning→グッモーニン（消える音＝脱落） や。for a moment→ファウラ（繋がる音＝連結）など、音声変化のルールを知って、実際に口に出す練習を積み重ねます。<span class="marker2">自分で言える音は必ず聞き取れます。逆もまた同様。</span>音の脱落や連結をマスターできると、自分の英語が流暢になるだけでなく、早口の英語が劇的に聞き取れるようになります。聞けるから言える。<span class="marker2">そんな相乗効果で上達が一気に加速していくのです。</span></p>
              <p>
                JJ ENGLISHエクササイズでは、付属のマニュアル冊子（「A to Z」）に特別に発音レクチャーのページを設け、<span class="marker2">音の脱落や連結など、最短距離で正しい発音に近づくための５つの秘訣と、重点的に練習したい子音の発音などをまとめています。</span>それらを見ながら、ネイティブが話す実際の音声を聞いてエクササイズします。<br>
                <span class="blue">★すべてのエクササイズに活用します。</span>
              </p>
              <p class="kome">※ネイティブの音声には、「いろいろな英語」に慣れるように、教材全体を通して、アメリカ東部、アメリカ南部、オーストラリア、イギリス（一部）出身者の英語が収録されています。</p>
            </div>
          </div>
          <div class="cover inner01">
            <figure class="f1"><img v-lazy="'/top2020/img/tc9_06_01.webp'" alt="" class="pc_only"><img v-lazy="'/top2020/img/sp_tc9_06_01.webp'" alt="" class="sp_only"></figure>
            <div class="float">
              <figure class="f2"><img v-lazy="'/top2020/img/tc9_06_02.webp'" alt=""></figure>
              <div class="txt">
                <h4>カタカナ表記と生音声を真似します</h4>
                <p>
                  ・「繋がる音」をマスターする！<br>
                  <ul class="words">
                    <li>at school<br>アッスクーl</li>
                    <li>on it<br><span class="red">オ</span>ニt</li>
                    <li>in an hour<br>イン<span class="red">ナ</span>ンナウァ</li>
                  </ul>
                  <br>
                  ・「消える音（脱落する音）」を知る！<br>
                  <ul class="words">
                    <li>Goo<span class="red">d</span> mornin<span class="red">g</span>!<br>グッモーニン</li>
                    <li>ba<span class="red">d</span> condition<br>バッコンディション</li>
                  </ul>
                  <br>
                  ・「短縮形の砕けた音」を知る！<br>
                  <ul class="words">
                    <li><span class="red">gonna</span>（going to）ガナ</li>
                    <li><span class="red">kinda</span>（kind of）カインダ</li>
                  </ul>
                  <br>
                  <span class="blue">（テキストより、ごく一部を紹介）</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <PurchaseButton />
      </template>
      <template #footer>
        <TopFooter />
      </template>
    </TopLayout>
  </div>
</template>

<script>
import TopLayout from '@/components/TopLayout.vue'
import TopHeader from '@/components/TopHeader.vue'
import TopFooter from '@/components/TopFooter.vue'
import PurchaseButton from '@/components/PurchaseButton.vue'

export default {
  name: 'Tc20c',
  components: {
    TopLayout,
    TopHeader,
    TopFooter,
    PurchaseButton,
  },
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";
@include fade_animation;

h3 {
  text-align: center;
  font-size: 42px;
  font-weight: 700;
  color: #fe3340;
  margin-bottom: 40px;
}
.container {
  padding-top: 120px;
  padding-bottom: 120px;
}
.container p.under {
  margin-bottom: 70px;
	font-size:26px;
	line-height: 40px;
	font-weight: 700;
  text-align: center;
}
.title {
  position: relative;
}
.title figure.main {
  padding: 0 50px;
}
.title figure.sub {
  width: 30%;
  position: absolute;
  left: calc(50% - 500px);
  top: 39vw;
}
.title .txt {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 70px;
  width: 500px;
  margin: -400px calc(50% - 500px) 40px auto;
  position: relative;
  border-radius: 10px;
}
.title .txt p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.title .txt .blue {
  color: #0a74d7;
}
.title .txt p.kome {
  font-size: 12px;
  line-height: 16px;
  &.red {
    color: #fe3340;
  }
}
.title .txt p:last-child {
  margin-bottom: 0;
}
figure.icon {
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
  margin-bottom: 70px;
}
img.icon {
  height: 30px !important;
  width: auto;
  margin-top: 10px;
}
@media only screen and (max-width: 768px) {
  h3 {
    font-size: 23px;
    margin-bottom: 20px;
  }
  .title {
    margin-bottom: 30px;
  }
  .title figure.main {
    padding: 0 20px;
    margin-bottom: 110px;
  }
  .title figure.sub {
    width: 35%;
    left: 0;
    right: 0;
    top: 30vw;
    margin: 0 auto;
  }
  .title .txt {
    padding: 0 20px;
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
  .feature5 .title .txt {
    margin: 0;
  }
  .title .txt p {
    font-size: 14px;
    line-height: 24px;
  }
  .container{
    padding-top: 70px;
    padding-bottom: 50px;
  }
}
/*-----------------------------------------------------------*/
/*--------------------------feature6-------------------------*/
/*-----------------------------------------------------------*/
.feature6 figure.f1 {
  margin-bottom: 30px;
}
.feature6 figure.f2 {
  width: 70%;
  margin: 0 auto 10px auto;
}
.feature6 .float {
  overflow: hidden;
}
.feature6 .float h4 {
  margin-bottom: 10px;
}
.feature6 .float p {
  font-size: 16px;
  overflow: hidden;
}
.feature6 .float p .red {
  color: #fe3340;
}
.feature6 .float p .blue {
  color: #0a74d7;
}
.feature6 .float figure.f2 {
  width: 50%;
  float: left;
  margin-right: 30px;
}
.feature6 .words {
  overflow: hidden;
  li {
    float: left;
    font-size: 16px;
    margin-right: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .feature6 .float figure.f2 {
    width: 100%;
  }
  .feature6 .float figure.f2 {
    float: none;
  }
}
::v-deep .purchase-button {
  margin-bottom: 120px;
}
</style>
