<template>
  <section id="fv">
    <div class="container">
      <figure class="bg_img"><img src="/top2020/img/fv.webp" alt="" class="pc_only"><img src="/top2020/img/sp_fv.webp" alt="" class="sp_only"></figure>
      <div class="txt">
        <figure class="f1"><img src="/top2020/img/hiragino_kagenasi.webp" alt=""></figure>
        <p class="p1">独習型 英会話学習教材</p>
        <figure class="f2"><img src="/top2020/img/logo02.webp" alt=""></figure>
        <p class="p2">
          今度こそ絶対に<br>
          「話せる人」になりたい！<br>
          そんな人たちに選ばれています。
        </p>
      </div>
      <figure class="item inner01"><img src="/top2020/img/fv_item.webp" alt="" class="pc_only"><img src="/top2020/img/sp_fv_item.webp" alt="" class="sp_only"><img class="icon-dvd" src="/top2020/img/icon-dvd.webp" alt=""></figure>
      <div class="rank inner01">
        <p>
          開発協力<br>
          <span class="large">講談社</span>
        </p>
        <p>
          Amazon「英会話ソフト部門<br>
          ランキング」第1位獲得！<br>
          <span class="sub">※2020年1月〜 1位獲得回数170回以上</span>
          <br>
          プロ英会話講師が<br>
          使いたい教材 <span class="middle">No.1</span><br>
          <span class="sub">2020年12月 講談社調べ（プロ英会話講師570名に<br>インターネットアンケート調査。全29教材中）</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FirstView',
}
</script>

<style scoped lang="scss">
#fv .container {
  position: relative;
}
#fv .container .bg_img img {
  width: 97%;
  margin: 0 auto;
}
#fv .container .txt {
  position: absolute;
  top: 190px;
  left: 11%;
  width: 40%;
}
#fv .container .txt .f1 {
  width: 122%;
  margin-bottom: 60px;
}
#fv .container .txt .p1,
#fv .container .txt .p2 {
  width: 300px;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  color: #fff;
}
#fv .container .txt .p2 {
  width: 520px;
  font-size: 24px;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
}
#fv .container .txt .p1 {
  border: 2px solid #fff;
  border-radius: 50px;
  padding: 5px;
  margin-bottom: 20px;
}
#fv .container .txt .p2 {
  font-family: 'source-han-serif-japanese', sans-serif;
  margin-top: 20px;
}
#fv .container .txt .f2 {
  width: 75%;
}
#fv .item {
  position: absolute;
  bottom: 6px;
  margin: 0 auto;
  left: 0;
  right: 0;
}
#fv .item img {
  width: 90%;
  margin: 0 auto;
}
#fv .item .icon-dvd {
  position: absolute;
  height: 46px !important;
  width: auto;
  right: 29%;
  bottom: 5%;
}
#fv .rank {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  position: relative;
  top: 50px;
}
#fv .rank p {
  text-align: center;
}
#fv .rank p .large {
  font-size: 56px;
}
#fv .rank p .middle {
  font-size: 20px;
}
#fv .rank p {
  font-size: 18px;
}
#fv .rank p .sub {
  display: block;
  font-size: 12px;
  line-height: 1.3em;
  margin-top: 10px;
}
@media only screen and (max-width: 768px) {
  #fv .container .txt {
    top: 100px;
    left: 7%;
    width: 65%;
  }
  #fv .container .txt .f1 {
    margin-bottom: 30px;
  }
  #fv .container .txt .p1 {
    width: 170px;
    padding: 2px;
    font-size: 13px;
    margin-bottom: 10px;
  }
  #fv .item {
    bottom: 40px;
  }
  #fv .item img {
    width: 90%;
  }
  #fv .item .icon-dvd {
    height: 30px !important;
    bottom: 0%;
  }
  #fv .container .txt .f2 {
    width: 100%;
  }
  #fv .rank {
    margin-top: 80px;
    font-size: 14px;
    top: 160px;
  }
  #fv .rank p {
    font-size: 14px;
  }
  #fv .rank p .sub {
    font-size: 10px;
  }
  #fv .rank p .large {
    font-size: 30px;
  }
}
</style>
