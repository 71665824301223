<template>
  <div>
    <Layout>
      <template #header>
        <Header />
      </template>
      <template #contents>
        <section class="column_sp2_pc2 imp_sec">
          <h2 class="imp_h2">独学で英会話を習得するコツとは？ 教材のサンプル視聴や申し込みはお気軽に</h2>
          <ColumnShareButtons v-if="basePath === '/'" :url="`https://jjenglish.co.jp${basePath}column/application-selftaught-englishconversation`" />
          <div class="imp_subpage_text">
            <div class="u-pb30 l-container">

              <div id="breadcrumbs" itemscope itemtype="http://schema.org/BreadcrumbList">
                <span itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" :href="`${basePath}`"><span itemprop="name">ホーム</span></a><meta itemprop="position" content="1" />
                </span> &raquo;
                <span itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" :href="`${basePath}column/`"><span itemprop="name">コラム一覧</span></a><meta itemprop="position" content="2" />
                </span> &raquo;
                <span itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" :href="`${basePath}column/application-selftaught-englishconversation`"><span itemprop="name">独学で英会話を習得するコツとは？ 教材のサンプル視聴や申し込みはお気軽に</span></a><meta itemprop="position" content="3" />
                </span>
              </div>

              <div class="l-section">
                <div class="u-mb30 c-block">
                  <p class="u-m0 u-pb1em">
                    独学で英会話を習得する場合、直接指導してくれる人がいないからこそ、自分自身で英語学習のコツを押さえて、実践していくことが大切になります。独学での勉強は自分中心で行われるからこそ、コツを理解できていないとスムーズに進めるのが難しくなってしまうのです。</p>
                  <p class="u-m0 u-pb1em">
                    ときにはコツを知らないがゆえについサボってしまったり、本当に勉強の効果が出ているのか不安になってモチベーションが下がったりすることもあるでしょう。こちらでは、英会話学習を独学で行うためのコツと、そのために役立つ教材をご紹介します。独学でスムーズな学習ができるか不安なときには、以下を参考に環境を整えてみてください。
                  </p>
                </div>

                <h3 class="u-mb20 l-heading-secondary">独学で英会話を習得する3つのコツ！サンプルを活用して自分にあった教材を見つけよう</h3>
                <div class="u-mb30 c-block">
                  <div class="img-right-s">
                    <img alt="独学で英会話を習得するコツをご紹介" src="/column/images/man-reading-book.jpg">
                  </div>
                  <p class="u-m0 u-pb1em">
                    独学で英会話を習得する場合には、以下の3つのコツが特に重要となります。
                  </p>
                  <div class="c-s-block">
                    <h4 class="u-mb10 u-mt30 l-tertiary">英会話を習慣化する</h4>
                    <p class="u-m0 u-pb1em">
                      英会話の学習は日常の中で習慣化して、定期的に見直すことが必要です。
                    </p>
                    <p class="u-m0 u-pb1em">
                      学んだ知識を定着させられるように、「毎日〇〇時間は机に向かう」「祝日はテストのつもりでこれまでの学習内容を振り返る」といったルールづくりをすることがおすすめです。決まった時間や曜日に英会話学習を行う癖を体に覚えさせれば、継続して独学を続けられるでしょう。
                    </p>
                  </div>
                  <div class="c-s-block">
                    <h4 class="u-mb10 u-mt30 l-tertiary">常に英語に触れる機会を持つ</h4>
                    <p class="u-m0 u-pb1em">
                      常に英語に触れる機会を持つことも、独学で英会話を勉強する際のコツです。
                    </p>
                    <p class="u-m0 u-pb1em">
                      例えば英語が表示されるアプリを使ってニュースを読んだり、普段使うパソコンの表示設定を英語にしたりするといった方法があります。
                    </p>
                    <p class="u-m0 u-pb1em">
                      身の回りに自然と英語をちりばめておけば、「英会話の勉強をしなくちゃ」という気持ちを呼び起こしやすいです。日常のシーンから英語を知るきっかけにもなるので、英語に触れられる機会をなるべく増やすように心がけましょう。
                    </p>
                  </div>
                  <div class="c-s-block">
                    <h4 class="u-mb10 u-mt30 l-tertiary">サンプルなどから自分に合う教材を見つける</h4>
                    <p class="u-m0 u-pb1em">
                      サンプルなどを参考に自分に合った教材を選ぶのも、独学で英会話を学ぶ際には重要なポイントです。
                    </p>
                    <p class="u-m0 u-pb1em">
                      自分の学習レベルや好みに合わない教材は、むしろ適切な学習を妨げるものになります。実際に利用する前にサンプルを確認し、独学に向いているかをチェックすることを忘れないようにしましょう。
                    </p>
                    <div class="c-btn u-pt20">
                      <a :href="`${basePath}trial`" target="_blank" rel="noopener" class="c-btn-anchor">独学におすすめの英会話教材<br>サンプル視聴はこちら</a>
                    </div>
                  </div>
                </div>

                <h3 class="u-mb20 l-heading-secondary">英会話は独学でも身につくの？</h3>
                <div class="u-mb30 c-block">
                  <div class="img-right-s">
                    <img alt="英会話は独学でも身につくのか解説" src="/column/images/english-alphabet.jpg">
                  </div>
                  <p class="u-m0 u-pb1em">
                    独学での英語学習に対して、「本当に身につくの？」「難しいから挫折しやすい」という疑問やイメージを持つ人も多いでしょう。
                  </p>
                  <p class="u-m0 u-pb1em">
                    しかし、独学に役立つきちんとした教材を利用できれば、英会話の能力はしっかりと高めていけます。逆に言えば、教材選びを間違えるとどれだけ意欲があっても効率的な独学は難しくなると言えるでしょう。教材選びに力を入れることが、独学を成功させる基本になるのです。
                  </p>
                  <p class="u-m0 u-pb1em">
                    例えばJJ ENGLISHエクササイズのような、動画での学習や使い回しの効く構文や熟語を使った教材は、独学を行う人に多くのメリットを与えます。
                  </p>
                  <p class="u-m0 u-pb1em">
                    JJ ENGLISHエクササイズの教材は、ITから医療・国際関係まで幅広い分野で活動する同時通訳者・横山カズ先生が開発された「パワー音読」という独自の音読法を採用しており、こちらは「感情」「スピード」「反復」「集中」という4つの力（パワー）を利用して、音読の成果を最大限に高めるメソッドです。
                  </p>
                  <p class="u-m0 u-pb1em">
                    例文を「ワンフレーズごと」「意味のカタマリごと」でリピーティングする「チャンク音読」、感情を込めて読む「感情音読」、声を出さずに息だけでささやくように読む「ささやき音読」、決められた時間内にたくさんの回数を読む「タイムアタック音読」という4つのステップで構成されています。
                  </p>
                  <p class="u-m0 u-pb1em">
                    JJ ENGLISHエクササイズには独学の英会話学習に必要とされる「わかりやすさ」が備わっているため、これまで独学を行ったことがない人でも、安心して英会話の勉強を始められるでしょう。無料で視聴できるサンプルもあるので、事前に教材の特色を試すことが可能です。独学で英会話を学んでいく意志があるのなら、ぜひお申し込みをご検討ください。
                  </p>
                </div>

                <h3 class="u-mb20 l-heading-secondary">独学で英会話を習得するならJJ ENGLISHエクササイズに申し込みを！教材のサンプル視聴も可能</h3>
                <div class="u-mb30 c-block">
                  <p class="u-m0 u-pb1em">
                    独学で英会話を学ぶためには、上述したようなコツの理解が重要となります。「独学ではイマイチ結果が出ない」「どうしてもやる気が続かない」というときには、コツを参考に学習環境を見直してみてはいかがでしょうか。
                  </p>
                  <p class="u-m0 u-pb1em">
                    JJ ENGLISHエクササイズのような教材であれば、独学でも英会話の実力を確実に身につけていけます。まずはサンプルを視聴したうえで、この機会にJJ ENGLISHエクササイズへの申し込みを検討してみてください。
                  </p>
                </div>
              </div>

              <ColumnShareButtons v-if="basePath === '/'" :url="`https://jjenglish.co.jp${basePath}column/application-selftaught-englishconversation`" />

              <div class="c-btn u-pt20">
                <a :href="`${basePath}inquiry`" target="_blank" rel="noopener" class="c-btn-anchor">独学におすすめの英会話教材<br>お問い合わせはこちら</a>
              </div>

              <ColumnLinkBlock class="u-mt50" />
              <ColumnAboout title="独学におすすめの英会話教材 JJ ENGLISHエクササイズ" />

              <div class="u-mt30 u-mb30 c-block">
                <h3 class="u-mb20 l-heading-secondary">独学で英会話力が身につくおすすめの教材を販売！サンプルもご用意！</h3>
                <p class="u-m0 u-pb1em">
                  JJ ENGLISHエクササイズは、独学で英会話力を身につけたい方におすすめの教材です。目・耳・口をしっかりと動かす「パワー音読」をメインとする7つのエクササイズを通して、世界に通じる英会話力を身につけます。教材サンプルもご用意しております。お申し込みの前にぜひご視聴ください。独学で英会話を習得したい方のお問い合わせ、お待ちしております。
                </p>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template #footer>
        <Footer />
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from '@/components/Layout.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ColumnShareButtons from '@/components/ColumnShareButtons.vue'
import ColumnLinkBlock from '@/components/ColumnLinkBlock.vue'
import ColumnAboout from '@/components/ColumnAboout.vue'

export default {
  name: 'Column02',
  components: {
    Layout,
    Header,
    Footer,
    ColumnShareButtons,
    ColumnLinkBlock,
    ColumnAboout,
  },
  computed: {
    basePath() {
      return this.$store.state.basePath
    }
  },
  head: {
    script: [
      {
        type: 'text/javascript',
        inner: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5HP4P8JK');
        `,
      }
    ],
  },
}
</script>
