import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    basePath: '/member/',
    shopUrl: 'https://jjenglishmember.shop-pro.jp/',
  },
  getters: {
    isAuthenticated() {
      //return sessionStorage.getItem('member-auth');
      return true;
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
