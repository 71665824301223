<template>
  <figure class="purchase-button cv">
    <a href="#" class="bt_purchase cf" @click.prevent="onClick">
      <img v-if="type === 'mypace'" v-lazy="'/top2020/img/cv_mypace.webp'" alt="" class="pc_only">
      <img v-else v-lazy="'/top2020/img/cv.webp'" alt="" class="pc_only">
      <img v-if="type === 'mypace'" v-lazy="'/top2020/img/sp_cv_mypace.webp'" alt="" class="sp_only">
      <img v-else v-lazy="'/top2020/img/sp_cv.webp'" alt="" class="sp_only">
      <span class="text">58日間返金保証</span>
    </a>
  </figure>
</template>

<script>
export default {
  name: 'PurchaseButton',
  props: {
    type: {
      type: String,
      default: '',
    }
  },
  methods: {
    onClick() {
      // const form = document.querySelector('.cartjs_box form')
      // form.submit()
      location.href = this.$store.state.shopUrl
    }
  }
}
</script>

<style scoped lang="scss">
.purchase-button a {
  display: inline-block;
}
.purchase-button a:hover {
  opacity: 0.8;
}
.purchase-button .text {
  display: block;
  text-align: center;
  font-weight: bold;
  margin-top: 12px;
  color: #219627;
  font-size: 25px;
}
</style>
