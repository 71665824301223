<template>
  <header>
    <div class="seoarea cf">
      <h1 class="fleft cf">
        <a :href="basePath">
          本気で話したいなら！<br><img src="/img/jjenglish_logo1.svg" alt="JJ ENGLISH エクササイズ"/>
        </a>
      </h1>
      <nav class="cf">
        <ul>
          <li><p class="phone"><span class="freedial-icon"></span>0120-197917<br><span class="sub">10:00〜18:00（土日祝休）</span></p></li>
          <li><a href="#" class="bt_purchase cf" @click.prevent="onClick">注文する</a></li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  methods: {
    onClick() {
      // const form = document.querySelector('.cartjs_box form')
      // form.submit()
      location.href = this.$store.state.shopUrl
    }
  },
  computed: {
    basePath() {
      return this.$store.state.basePath
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.freedial-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  top: -2px;
  width: 30px;
  height: 30px;
  background-image: url("/img/freedial.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  &.black {
    background-image: url("/img/freedial_b.png");
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  margin: 0 auto 0;
  text-align: center;
  width: 100%;
  height: 90px;
  background-color: $color_red;
  .seoarea {
    margin:0 auto;
    max-width: 1080px;
    width: 90%;
    padding: 0;
    margin: 18px auto;
    h1 {
      position: absolute;
      top: 0px;
      left: $default_side_margin;
      padding-left: 0;
      max-width: 540px;
      color: $color_white;
      line-height: 1;
      margin: 0;
      text-align: left;
      font-size: 18px;
      height: 100%;
      padding-top: 12px;
      a {
        color: $color_white;
        text-decoration: none;
      }
      img {
        height: 50px;
      }
    }
    .phone {
      color: $color_white;
      clear: both;
      font-size: 18px;
      margin-bottom: 0;
      text-align: left;
    }
    nav {
      overflow: hidden;
      float: right;
      form {
        display: none;
      }
      li {
        float:left;
        margin: 0;
        padding-left: 20px;
        a {
          display: block;
          color: $color_red;
          font-size: 18px;
          font-weight: bold;
          padding: 0 1.75rem;
          height: 48px;
          width: 190px;
          line-height: 48px;
          border-radius: 24px;
          border: 0;
          background-color: $color_white;
          /*0.5秒かけて要素を変化させる*/
          -webkit-transition: all 1s ease;
          -moz-transition: all 1s ease;
          -o-transition: all 1s ease;
          transition:all 1s ease;
          &:link {
            color: $color_red;
          }
          &:visited {
            color: $color_red;
          }
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {

  header {
    .seoarea {
      h1 {
        font-size: 16px;
        left: $default_side_margin;
        padding-top: 15px;
        img {
          height: 46px;
        }
      }
      .phone {
        font-size: 14px;
      }
    }
  }

}

@media screen and (max-width: 800px) {

  header {
    .seoarea {
      h1 {
        font-size: 14px;
        left: $default_side_margin_sp;
        padding-top: 21px;
        img {
          height: 36px;
        }
      }
      .phone {
        font-size: 12px;
        .sub {
          font-size: 12px;
        }
      }
      nav {
        li {
          padding-left: 10px;
          a {
            font-size: 16px;
            width: 126px;
            padding: 0 1rem;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 600px) {

  header {
    .seoarea {
      h1 {
        font-size: 14px;
        left: $default_side_margin_sp;
        padding-top: 24px;
        img {
          height: 30px;
        }
      }
      nav {
        li {
          padding-left: 10px;
        }
      }
    }
  }

}

@media screen and (max-width: 560px) {

  header {
    height: 50px;
    .seoarea {
      width: 100%;
      padding-left: 10px;
      margin-top: 0;
      margin-bottom: 10px;
      .phone {
        padding-right: 10px;
        font-size: 10px;
        .sub {
          font-size: 10px;
        }
      }
      h1 {
        font-size: 12px;
        left: 10px;
        padding-top: 5px;
        img {
          height: 28px;
        }
      }
      nav {
        li {
          padding-left: 0;
          a {
            font-size: 12px;
            width: 80px;
            border-radius: 0;
            background-color: rgba(255, 255, 255, 0.8);
            &.bt_purchase {
              color: $color_white;
              background-color: $color_red;
            }
          }
        }
      }
    }
  }

}

@media screen and (max-width: 420px) {

  header {
    .seoarea {
      .phone {
        font-size: 10px;
        padding-right: 0;
        .sub {
          font-size: 10px;
        }
      }
      h1 {
        font-size: 12px;
        left: 10px;
        padding-top: 8px;
        img {
          height: 24px;
        }
      }
    }
  }

}

@media screen and (max-width: 380px) {

  header {
    .seoarea {
      .phone {
        position: relative;
        left: 20px;
      }
      h1 {
        font-size: 12px;
        left: 10px;
        padding-top: 8px;
        img {
          height: 24px;
        }
      }
    }
  }

}
</style>
