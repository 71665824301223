<template>
  <div>
    <slot name="header" />
    <div id="contents">
      <slot name="contents" />
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
export default {
  name: 'Layout',
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

#contents {
  padding-top: 100px;
  @media screen and (max-width: 560px) {
    padding-top: 80px;
  }
}

::v-deep {

  section {
    width: 100%;
    max-width: 960px;
    text-align: left;
    margin: 0 auto;
    padding: 0;
    margin-bottom: 60px;
    @include basic_layout;
    .impact-block {
      @include basic_layout;
    }
    h3 {
      margin-bottom: 16px;
      &.large-margin {
        margin-bottom: $default_bottom_margin;
      }
    }
    h4 {
      margin-bottom: 10px;
    }
  }

  p, li {
    font-size: 18px;
    .sub, &.sub {
      font-size: 14px;
      &.right {
        text-align: right;
      }
    }
  }

  h2 {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
  }


  h3, .closing {
    font-size: 27px;
    font-weight: bold;
    text-align: center;
    .strong {
      font-size: 36px;
    }
    .sub {
      font-size: 20px;
      font-weight: normal;
    }
  }

  .max_closing {
    text-align: center;
    font-weight: bold;
    margin-top: 40px !important;
    margin-bottom: 0 !important;
    .strong {
      font-size: 48px;
      @media screen and (max-width: 600px) {
        font-size: 30px;
      }
    }
  }

  h4 {
    font-size: 18px;
    font-weight: bold;
    .sub {
      font-size: 14px;
      font-weight: normal;
    }
  }

  .ondoku-title {
    font-size: 24px;
    font-weight: bold;
    color: $color_red;
  }

  @media screen and (max-width: 800px) {
    h3, .closing {
      font-size: 20px;
      .strong {
        font-size: 30px;
      }
      .sub {
        font-size: 14px;
      }
    }
    .ondoku-title {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 600px) {
    section {
      > p, > h3, > h4 {
        margin-left: $default_side_margin_sp;
        margin-right: $default_side_margin_sp;
      }
    }
  }

  .marker {
    background: linear-gradient(transparent 0%, yellow 35%);
  }

  h2 + p {
    margin-top: 40px;
  }
  .column_sp2_pc2 {
    padding-left: $default_side_margin;
    padding-right: $default_side_margin;
    @media screen and (max-width: 600px) {
      padding-left: $default_side_margin_sp;
      padding-right: $default_side_margin_sp;
    }
    font-size: 16px;
    position: relative;
    margin-left:auto;
    margin-right:auto;
    text-align:left;
    max-width:960px;
    padding-bottom: 66px;
    h3 {
      font-size: 20px;
      text-align: left;
      color: $color_red;
    }
    p, li {
      font-size: 16px;
    }
    ol {
      list-style: decimal;
    }
  }
}

</style>
