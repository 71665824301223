<template>
  <div>
    <Layout>
      <template #header>
        <Header />
      </template>
      <template #contents>
        <section class="column_sp2_pc2 imp_sec">
          <h2 class="imp_h2">英語学習は手元ツール（スマホなどのモバイル端末）で見られる動画が便利 ゴールを設定して学習を</h2>
          <ColumnShareButtons v-if="basePath === '/'" :url="`https://jjenglish.co.jp${basePath}column/smartphone-englishlearning-handtool`" />
          <div class="imp_subpage_text">
            <div class="u-pb30 l-container">

              <div id="breadcrumbs" itemscope itemtype="http://schema.org/BreadcrumbList">
                <span itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" :href="`${basePath}`"><span itemprop="name">ホーム</span></a><meta itemprop="position" content="1" />
                </span> &raquo;
                <span itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" :href="`${basePath}column/`"><span itemprop="name">コラム一覧</span></a><meta itemprop="position" content="2" />
                </span> &raquo;
                <span itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" :href="`${basePath}column/smartphone-englishlearning-handtool`"><span itemprop="name">英語学習は手元ツール（スマホなどのモバイル端末）で見られる動画が便利 ゴールを設定して学習を</span></a><meta itemprop="position" content="3" />
                </span>
              </div>

              <div class="l-section">
                <div class="u-mb30 c-block">
                  <p class="u-m0 u-pb1em">
                    英語学習の際に、スマホやタブレットなどの手元ツールを活用することは一般的なことになっています。 モバイル端末の手軽さや携帯性を活かした学習は、隙間時間の有効利用や学習モチベーションの向上につながるメリットがあるでしょう。</p>
                  <p class="u-m0 u-pb1em">
                  しかし、手元ツールをただ用意して使い始めるだけでは、そのメリットを完全に引き出すことはできません。ときには「ながら見」の状態になってしまい、せっかくの内容が頭に入らないということにもなりかねないでしょう。</p>
                  <p class="u-m0 u-pb1em">
                  そこでこちらでは、英語学習で手元ツールを使う際に確認しておきたいポイントをご紹介します。スマホなどのモバイル端末で効率良く英語学習をしたいと考えるのなら、以下のコツをチェックしてみてください。
                  </p>
                </div>

                <h3 class="u-mb20 l-heading-secondary">モバイル端末を使う前に確認！英語学習を行うには自分の英語レベルの把握が大切</h3>
                <div class="u-mb30 c-block">
                  <div class="img-right-s">
                    <img alt="置かれた教材" src="/column/images/educational-materials-placed.jpg">
                  </div>
                  <p class="u-m0 u-pb1em">
                    手元ツールを使って英語学習を進める際には、まず自分の英語レベルを正しく把握する必要があります。わからない範囲から始めても理解することは難しくなり、モチベーションも下がりやすくなるでしょう。
                  </p>
                  <p class="u-m0 u-pb1em">
                    どんな内容から学習すべきかを見定めるためにも、自分の英語レベルの確認を行ってください。
                  </p>
                  <p class="u-m0 u-pb1em">
                    英語レベルは、TOEICの点数に基づいて確認することができます。例えば200〜300点台の場合は中学英語も難しい、400〜500点台なら中学英語は理解できる、600〜800点台は高校英語やビジネス英語を理解できる、そして900点台が英語を問題なく使いこなせるといった形で判断可能です。
                  </p>
                  <p class="u-m0 u-pb1em">
                    上記を参考に自分のTOEICの点数をチェックして、どんな学習内容を優先して進めるべきなのかを把握してみましょう。
                  </p>
                </div>

                <h3 class="u-mb20 l-heading-secondary">英語学習のためにはゴールを設定して手元ツールの有効活用を目指そう！</h3>
                <div class="u-mb30 c-block">
                  <div class="img-right-s">
                    <img alt="手元ツールとノートとペン" src="/column/images/hand-tool-note-pen.jpg">
                  </div>
                  <p class="u-m0 u-pb1em">
                    英語学習は明確な終わりを示してはくれないので、自分でゴールを設定するのがポイントです。ゴールが決まればそのために勉強をする意欲が高まり、今の自分の足りない部分もわかりやすくなります。
                  </p>
                  <p class="u-m0 u-pb1em">
                    特にスマホなどの手元ツールで動画を見るのが通常のスタイルなので、自然と受け身になってしまい、積極的に学ぶ姿勢が失われる可能性があるため、ゴールの設定は重要です。簡単に使える手元ツールだからこそ、その使い方には工夫が必要となるでしょう。
                  </p>
                  <p class="u-m0 u-pb1em">
                    例えば「海外の人と不自由なくコミュニケーションが取れるようになる」「中学校レベルの英語でも会話が成り立つようになる」「本や映画を英語のまま楽しめるようになる」といったものが具体的なゴール設定に挙げられます。ゴールの設定にあった手元ツールを活用することが、英語学習を続ける基本になるでしょう。
                  </p>
                </div>

                <h3 class="u-mb20 l-heading-secondary">英語学習ではゴールまでの小さな目標設定も肝心</h3>
                <div class="u-mb30 c-block">
                  <p class="u-m0 u-pb1em">
                    ゴールを決めた後は、続いてそこにつながっていく小さな目標を設定するのも英語学習のポイントになります。
                  </p>
                  <p class="u-m0 u-pb1em">
                    いきなりゴールを目指してもスムーズな学習は難しく、挫折の原因にもなるでしょう。 スポーツと同じようにゴールを狙うためには、小さな目標へのパスを積み重ねて、確実にゴールに近づいていく過程が大切になるのです。
                  </p>
                  <p class="u-m0 u-pb1em">
                    例えば「海外の人とコミュニケーションを取れるようになる」がゴールの場合、「月に○回、海外の人と交流する」「海外の人が話している動画を見る」といったものが目標に設定できます。目標達成を継続していければ自然と英語力が身につき、ゴールに近づいていくことができるでしょう。
                  </p>
                  <p class="u-m0 u-pb1em">
                    スマホなどのモバイル端末は簡易性に長けているので、細かな目標達成に役立ちます。ストリーミング再生や端末へのダウンロードが行える英語教材を活用して、設定した目標を達成できるように備えておきましょう。
                  </p>
                </div>

                <h3 class="u-mb20 l-heading-secondary">スマホなどの手元ツールで英語学習をするならJJ ENGLISHエクササイズを テキスト＋動画で学べる</h3>
                <div class="u-mb30 c-block">
                  <p class="u-m0 u-pb1em">
                    スマホなどの手元ツールで簡単に学べる環境をどう活かすかで、英語学習の効率は大きく変わります。これまで紙のテキストだけで勉強してきたという人も、この機会にモバイル端末を活用した動画での学習に切り替えてみてはいかがでしょうか。
                  </p>
                  <p class="u-m0 u-pb1em">
                    JJ ENGLISHエクササイズのようにテキスト＋動画で学べる教材なら、手元ツールを有効に活用できます。動画（MP4ファイル）と音声（MP3ファイル）はスマホやPCにダウンロードしたり、ストリーミング再生したりできるので、学習の場所を選びません。
                  </p>
                  <p class="u-m0 u-pb1em">
                    中学英語を軸にした本格的な英会話には多くの魅力があるので、手元ツールで勉強できる英語教材を探している人はチェックしてみてください。
                  </p>
                </div>
              </div>

              <ColumnShareButtons v-if="basePath === '/'" :url="`https://jjenglish.co.jp${basePath}column/smartphone-englishlearning-handtool`" />

              <div class="c-btn u-pt20">
                <a :href="`${basePath}trial`" target="_blank" rel="noopener" class="c-btn-anchor">サンプル視聴はこちら</a>
              </div>

              <ColumnLinkBlock class="u-mt50" />
              <ColumnAboout title="スマホなどの手元ツールで英語学習をお考えの方はJJ ENGLISHエクササイズへ" />
            </div>
          </div>
        </section>
      </template>
      <template #footer>
        <Footer />
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from '@/components/Layout.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ColumnShareButtons from '@/components/ColumnShareButtons.vue'
import ColumnLinkBlock from '@/components/ColumnLinkBlock.vue'
import ColumnAboout from '@/components/ColumnAboout.vue'

export default {
  name: 'Column05',
  components: {
    Layout,
    Header,
    Footer,
    ColumnShareButtons,
    ColumnLinkBlock,
    ColumnAboout,
  },
  computed: {
    basePath() {
      return this.$store.state.basePath
    }
  },
  head: {
    script: [
      {
        type: 'text/javascript',
        inner: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5HP4P8JK');
        `,
      }
    ],
  },
}
</script>
