<template>
  <section id="tc3" class="lazyload">
    <h2 class="red">INSTRUCTORS<span>監修＆コーチ</span></h2>
    <strong class="black_red">私たちも<span class="red">同じ道</span>を<span class="red">進み</span>ました！</strong>
    <p class="under">
      だからレッスンもメソッドも圧倒的な信頼度<br>
      全員“純ジャパ”で、日本で英会話を習得した先生ばかりです
    </p>
    <div class="container inner01">
      <div class="box flexb b1">
        <div class="img">
          <figure><img v-lazy="'/top2020/img/yasukochi2.webp'" alt=""></figure>
        </div>
        <div class="txt">
          <p class="p1">”今の自分の英語”を使いながら上達しよう！</p>
          <p class="p2">東進ハイスクール講師<br class="pc_only">受験英語の神</p>
          <h3>安河内 哲也</h3>
          <p class="p3">子どもから大人まで、難しい用語を使わずに英語を楽しく教えることで人気。予備校や中学･高校での講演の他、大学での特別講義や企業の研修などでも講師を務めている。東進ハイスクール・東進ビジネススクール英語科講師。上智大学外国語学部英語学科卒。全国通訳案内士。<span class="marker2">著書の累計発行部数は400万部以上。NHKテキスト『もっと伝わる! 即レス英会話』に連載中。</span></p>
        </div>
      </div>
      <div class="box flexb b2">
        <div class="img">
          <figure><img v-lazy="'/top2020/img/yokoyama2.webp'" alt=""></figure>
        </div>
        <div class="txt">
          <p class="p1">僕にもできました。アナタにもできます！</p>
          <p class="p2">画期的メソッド「パワー音読🄬」を開発</p>
          <h3>横山 カズ</h3>
          <p class="p3">独学で英語を習得し同時通訳者となる。医療・環境・ITなど多彩な分野の国際会議、記者会見で同時通訳を担当。翻訳家としても活躍中。従来の音読を発展・進化させた「パワー音読」を開発。関西外国語大学外国語学部卒。<span class="marker2">スタディサプリ講師。NHK『ラジオ英会話タイムトライアル』連載中。</span></p>
        </div>
      </div>
      <div class="box flexb b3">
        <div class="img">
          <figure><img v-lazy="'/top2020/img/mizuno2.webp'" alt=""></figure>
        </div>
        <div class="txt">
          <p class="p1">通じるだけを目標にせず、より美しく。</p>
          <p class="p2">オックスフォード仕込みの英語を伝授</p>
          <h3>水野 稚</h3>
          <p class="p3">イギリス英語にも堪能。世界とわたり合える日本人を増やしたいとの思いから、英語教育のエキスパートへ。Primus Edge代表取締役/プリムスアカデミー代表。<span class="marker2">スタディサプリ講師。</span>東京大学大学院博士課程在籍中にオックスフォード大学大学院に留学、修士号を取得。</p>
        </div>
      </div>
      <div class="box flexb b4">
        <div class="img">
          <figure><img v-lazy="'/top2020/img/yokote2.webp'" alt=""></figure>
        </div>
        <div class="txt">
          <p class="p1">シンプルな英語。それも”おもてなし”です。</p>
          <p class="p2">元国際線CAの経験から接客英語を指導</p>
          <h3>横手 尚子</h3>
          <p class="p3">元日本航空国際線客室乗務員として<span class="marker2">英語での接客に経験豊富。</span>後進の指導に当たるほか、接客・マナー講師として活躍中。明るい授業が人気で、国内外の学生、社会人に接客英語と発音などを指導。学習院大学卒。武蔵野学院大学准教授。英語発音指導士。</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Tc3',
}
</script>

<style scoped lang="scss">
#tc3 {
  &.lazyloaded {
    background-image: url("/top2020/img/tc3_bg.webp");
  }
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  margin-top: -220px;
  padding-top: 320px;
  padding-bottom: 180px;
}
#tc3 .under {
  font-size: 24px;
  line-height: 1.4em;
  margin-bottom: 200px;
}
#tc3 .box {
  background-color: #fff;
  padding: 70px 0 110px 0;
  margin-bottom: 80px;
  position: relative;
}
#tc3 strong.black_red {
  margin-bottom: 20px;
  &.small {
    font-size: 60px;
  }
}
#tc3 .box:after {
  position: absolute;
  right: 20px;
  top: -10px;
  content: "INSTRUCTOR";
  font-family: 'Roboto Condensed', sans-serif;
  background-color: #fe3340;
  color: #fff;
  font-size: 12px;
  padding: 10px 25px;
}
#tc3 .box .img {
  position: relative;
  width: 50%;
  z-index: 1;
}
#tc3 .box.b1 .img figure {
  width: 350px;
  position: absolute;
  bottom: -50px;
  left: 100px;
}
#tc3 .box.b2 .img figure {
  width: 350px;
  position: absolute;
  bottom: -110px;
  left: 70px;
}
#tc3 .box.b3 .img figure {
  width: 350px;
  position: absolute;
  bottom: -40px;
  left: 60px;
}
#tc3 .box.b4 .img figure {
  width: 350px;
  position: absolute;
  bottom: -100px;
  left: 100px;
}
#tc3 .box .txt {
  width: 49%;
  z-index: 2;
}
#tc3 .box .txt .p1 {
  max-width: 420px;
  font-size: 19px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 10px;
}
#tc3 .box .txt .p2 {
  max-width: 400px;
  font-size: 26px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #fe3340;
}
#tc3 .box .txt .p3 {
  max-width: 400px;
  font-size: 16px;
  line-height: 24px;
}
#tc3 .box .txt h3 {
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 30px;
}
@media only screen and (max-width: 768px) {
  #tc3 {
    &.lazyloaded {
      background-image: url("/top2020/img/sp_tc3_bg.webp");
    }
    margin-top: -120px;
    padding-top: 180px;
    padding-bottom: 10px;
  }
  #tc3 .under {
    font-size: 16px;
    margin-bottom: 120px;
  }
  #tc3 .box {
    position: static;
    padding: 0 0px 40px 0px;
  }
  #tc3 strong.black_red {
    &.small {
      font-size: 27px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  #tc3 .box .img {
    width: 100%;
    position: static;
  }
  #tc3 .box .img figure {
    position: relative !important;
    bottom: 0 !important;
    left: 0 !important;
  }
  #tc3 .box.b1 .img figure {
    width: 70%;
    margin: -60px auto 20px auto;
  }
  #tc3 .box.b2 .img figure {
    width: 70%;
    margin: -50px auto 20px auto;
  }
  #tc3 .box.b3 .img figure {
    width: 80%;
    margin: -50px auto 20px auto;
  }
  #tc3 .box.b4 .img figure {
    width: 70%;
    margin: -50px auto 20px auto;
  }
  #tc3 .box .txt {
    width: 100%;
  }
  #tc3 .box .txt .p1 {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0px;
  }
  #tc3 .box .txt .p2 {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  #tc3 .box .txt .p3 {
    max-width: 400px;
    font-size: 14px;
    line-height: 30px;
  }
  #tc3 .box .txt h3 {
    font-size: 25px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  #tc3 .box:after {
    display: none;
  }
}
</style>
