<template>
  <section id="tc2" class="lazyload">
    <h2>
      <span class="title"><span class="large">この教材で身につく英語は…</span></span><br>
      国際基準「CEFR」の A1/A2～B1レベルに相当<br>
      <span class="under">「CEFR（セファール）」とは、外国語習得の段階を示す国際的な共通基準です。</span>
    </h2>
    <h2 class="white">
      <span class="small">その英語は、話せない人や今のあなたからすれば</span><br>
      「あの人、英語が話せるんだ」<br>
      <span class="small">という憧れの的になるレベルです。</span><br>
      <span class="tiny">（話せない人からは「ペラペラですね」と言われるかもしれません）</span>
    </h2>
    <p>
    </p>
    <h3>
      ネイティブ並みの英語でなくても<br>
      <span class="large">話せるヨロコビは無限大！</span>
    </h3>
    <div class="container01 inner02 flexb">
      <dl class="flex">
        <dt><span>世界が住まいに。<br>
          大好きな国や土地で<br>
          最高の人生を！</span></dt>
        <dd><img v-lazy="'/top2020/img/tc2_06_2.webp'" alt=""></dd>
      </dl>
      <dl class="flex">
        <dt><span>滞在中の外国人と<br>
          いきなり交流！<br>
          盛り上がれます</span></dt>
        <dd><img v-lazy="'/top2020/img/tc2_02_2.webp'" alt=""></dd>
      </dl>
      <dl class="flex">
        <dt><span>部署を異動して<br>
          やりがいも<br>
          給料もアップ</span></dt>
        <dd><img v-lazy="'/top2020/img/tc2_03.webp'" alt=""></dd>
      </dl>
      <dl class="flex">
        <dt><span>海外旅行で<br>
          不自由がなくなり<br>
          10倍楽しめる</span></dt>
        <dd><img v-lazy="'/top2020/img/tc2_04_2.webp'" alt=""></dd>
      </dl>
      <dl class="flex">
        <dt><span>遠い異国の人が<br>
          恋人になって<br>
          しまうかも！</span></dt>
        <dd><img v-lazy="'/top2020/img/tc2_08.webp'" alt=""></dd>
      </dl>
      <dl class="flex">
        <dt><span>英語で日本を案内！<br>
          困った外国人を<br>
          助けてあげられる</span></dt>
        <dd><img v-lazy="'/top2020/img/tc2_01_2.webp'" alt=""></dd>
      </dl>
      <dl class="flex">
      </dl>
    </div>
    <div class="container02 inner01 flexb">
      <figure><img v-lazy="'/top2020/img/tc2_07.webp'" alt=""></figure>
      <div class="txt">
        <p>
          国際基準CEFRの初級A１／A２から中級者のB１レベルの英語とは、旅行先はもちろん、ほとんどの日常場面で、どんな国の人を相手にしても、<br>
          <br>
          ○自分の考えや気持ちを伝えられる。<br>
          ○<span class="marker2">物事について簡潔な説明ができる。</span><br>
          （さらに必要な単語を覚えると）<br>
          ○<span class="marker2">趣味・文化・社会的な話題について会話ができる。</span><br>
          ○<span class="marker2">仕事の現場でもしっかりコミュニケーションがとれる。</span><br>
          <br>
          というレベルです。
        </p>
        <dl>
          <dt>CEFR A1/A2～B1レベル</dt>
          <dd>
            ＝英検2級～準1級に相当<br>
            ＝TOEIC 550～785点に相当<br>
            <span class="kome">※スピーキング、リスニングでの対照です。</span>
          </dd>
        </dl>
      </div>
    </div>
    <div class="container02 inner01 center">
      <h3><span class="border">驚き！ こんな会話が英語で言えるようになる！！</span></h3>
      <p>
        <span class="bold">例</span>：「絵画や文学のテーマにもなってきた富士山は、日本人の心のよりどころなんだ」<br>
        <span class="en">Mt.Fuji has been the subject of art and literature. It’s a spiritual home for the Japanese people.</span><br>
        <span class="bold">例</span>：「とんでもない！ ラーメンは、１つの丼で肉と野菜とスープと麺を楽しめるフルコース料理なのよ」<br>
        <span class="en">No, it’s not at all. With ramen you get meat, vegetables, soup and noodles all in the one bowl. They say that it’s a full-course meal.</span><br>
        <span class="bold">例</span>：「外国人に優しい、それがドン・キホーテの特徴なのよ！」<br>
        <span class="en">It is kind to foreigners; that’s Don Quijote!</span><br>
        <span class="bold">（JJ ENGLISHエクササイズのテキストより）</span>
      </p>
      <h3 class="top-margin">
        いま、「少しむずかしいかも…」と思ったあなたでも、<br>
        適切なエクササイズを続ければ、口から出る日が必ず来ます。<br>
        <br>
        <br>
        そして……、<br>
        <span class="large">
          「ネイティブ並みの英語」は、<br>
          このレベルの先にだけあるのです。
        </span>
      </h3>
    </div>
    <!-- <div class="container04 inner01">
      <h3>
        <span class="round">この教材は、こんな人たちに向いています</span>
      </h3>
      <div class="flex-center">
        <ul>
          <li><span class="blue">●</span>どんな学習法や教材がいいかわからない。</li>
          <li><span class="blue">●</span>中学英語ならなんとか思い出しながらやれそうだ。</li>
          <li><span class="blue">●</span>今度こそ、絶対に「話せる人」になりたい！</li>
          <li><span class="blue">●</span>ほかの教材や方法で失敗した。</li>
          <li><span class="blue">●</span>スクールに通ったけど話せるようにならなかった。</li>
          <li class="margin"><span class="blue">●</span>オンライン英会話の成果が感じられない。</li>
          <li><span class="blue">●</span>TOEIC のリスニング対策をしたい。</li>
          <li><span class="blue">●</span>英検のリスニング＆面接対策をしたい。</li>
          <li><span class="blue">●</span>TOEIC が高得点なのに話せない。</li>
        </ul>
      </div>
      <h3 class="under">
        上達できない<span class="marker">悩み</span>や<span class="marker">不安</span>はもう不要です！
      </h3>
    </div> -->
  </section>
</template>

<script>
export default {
  name: 'Tc2',
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";
.flex-center {
  @include flex-center;
}
.pc {
  display: inline-block;
}
.sp {
  display: none;
}
#tc2 {
  &.lazyloaded {
    background-image: url("/top2020/img/tc2_bg.webp");
  }
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  background-position: bottom;
  position: relative;
}
#tc2 {
  padding-bottom: 60px;
}
#tc2 h2 {
  padding: 40px;
  background-color: #fe3340;
  color: #fff;
  font-size: 40px;
  line-height: 1.2em;
  font-weight: 700;
  margin-bottom: 40px;
  text-align: center;
  .small {
    font-size: 26px;
  }
  .tiny {
    font-size: 18px;
    font-weight: normal;
  }
  &.white {
    font-size: 50px;
    background-color: white;
    color: #fe3340;
    .small {
      font-size: 30px;
    }
  }
}
#tc2 h2 .title {
  font-size: 26px;
  display: inline-block;
  margin-bottom: 10px;
  background-color: white;
  color: $color_red;
  height: 64px;
  line-height: 64px;
  border-radius: 32px;
  padding-left: 32px;
  padding-right: 32px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  .large {
    font-size: 40px;
  }
}
#tc2 h3 {
  font-size: 26px;
  text-align: center;
  margin-bottom: 40px;
  .large {
    font-size: 40px;
  }
  .border {
    font-size: 30px;
    display: inline-block;
    border: 1px solid black;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: 10px;
    margin-right: 10px;
  }
  &.top-margin {
    margin-top: 40px;
  }
}
#tc2 .under {
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 40px;
  color: black;
}
#tc2 .container01.flexb {
  align-items: flex-start;
}
#tc2 .container01 dl {
  width: 30%;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
}
#tc2 .container01 dl dt {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  span {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    text-align: left;
    display: inline-block;
  }
}
#tc2 .container01 dl dd {
  margin-bottom: 10px;
  order: -1;
}
#tc2 .container02 {
  margin-bottom: 50px;
}
#tc2 .container02 .en {
  display: inline-block;
  padding-left: 46px;
  margin-bottom: 10px;
}
#tc2 .container02.center {
  text-align: center;
}
#tc2 .container02.center p {
  width: 880px;
  margin-left: auto;
  margin-right: auto;
  .bold {
    font-weight: bold;
  }
}
#tc2 .container02 figure {
  width: 45%;
}
#tc2 .container02 .txt {
  padding-top: 10px;
  width: 50%;
  font-size: 16px;
  line-height: 24px;
}
#tc2 .container02 dl {
  margin-top: 25px;
}
#tc2 .container02 dl dt {
  float: left;
  color: #0a74d7;
}
#tc2 .container02 dl dd {
  overflow: hidden;
  color: #0a74d7;
}
#tc2 .container02 dl dd .kome {
  font-size: 15px;
}
#tc2 .container04 {
  max-width: none;
  text-align: center;
}
#tc2 .container04 h3 {
  width: 800px;
  margin: 0 auto;
  position: relative;
  color: #fe3340;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.3px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 80px;
  &.under {
    margin-top: 0;
    margin-bottom: 120px;
  }
  .round {
    display: block;
    color: #fff;
    border-radius: 80px;
    font-size: 26px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto 30px auto;
    font-weight: 700;
    background-color: #fe3340;
    width: 90%;
    margin-bottom: 20px;
  }
}
#tc2 .container04 ul {
  margin-bottom: 40px;
}
#tc2 .container04 ul li {
  font-size: 19px;
  font-weight: 700;
  margin-top: 4px;
  margin-right: 20px;
  &.margin {
    margin-bottom: 20px;
  }
}
#tc2 .container04 ul li .blue {
  color: #0a74d7;
}
@media only screen and (max-width: 768px) {
  .pc {
    display: none;
  }
  .sp {
    display: inline-block;
  }
  #tc2 {
    padding-bottom: 0;
  }
  #tc2 h2 {
    padding: 20px 0;
    height: auto;
    font-size: 20px;
    margin-bottom: 20px;
    .small {
      font-size: 20px;
    }
    &.white {
      font-size: 24px;
      .small {
        font-size: 16px;
      }
    }
  }
  #tc2 h2 .title {
    font-size: 20px;
    padding-left: 16px;
    padding-right: 16px;
    .large {
      font-size: 20px;
    }
  }
  #tc2 h2 span{
    font-size: 16px;
    margin-bottom: 6px;
  }
  #tc2 h3 {
    font-size: 20px;
    .large {
      font-size: 24px;
    }
    .border {
      font-size: 24px;
    }
  }
  #tc2 .under {
    font-size: 14px;
    margin-bottom: 20px;
  }
  #tc2 .container01 dl {
    width: 45%;
    margin: 0 0 20px 0;
  }
  #tc2 .container01 dl dt {
    font-size: 14px;
    line-height: 24px;
  }
  #tc2 .container02 .txt {
    width: auto;
  }
  #tc2 .container02 figure {
    width: 70%;
    margin: 0 auto;
  }
  #tc2 .container02 p, #tc2 .container02 dt, #tc2 .container02 dd {
    padding-top: 10px;
    font-size: 14px;
    line-height: 24px;
  }
  #tc2 .container02 dl {
    margin-top: 20px;
  }
  #tc2 .container02.center p {
    width: auto;
  }
  #tc2 .container04 h3 {
    width: 100%;
    font-size: 24px;
    .round {
      font-size: 15px;
      height: 50px;
      width: 100%;
      margin: 0 auto 0 auto;
    }
  }
  #tc2 .container04 h3::before {
    width: 20px;
    height: 50px;
    left: -30px;
  }
  #tc2 .container04 h3::after {
    width: 20px;
    height: 50px;
    right: -30px;
  }
  #tc2 .container04 ul {
    width: auto;
  }
  #tc2 .container04 ul li {
    font-size: 14px;
  }
}
</style>
