<template>
  <div>
    <TopLayout>
      <template #header>
        <TopHeader />
      </template>
      <template #contents>
        <div class="container feature3">
          <h3>動画と音声は<br>
            ダウンロード＆ストリーミングが<br>
            自由自在</h3>
          <p class="under">いつでもどこでも学習。スキマ時間も無駄なく活用できる</p>
          <div class="title">
            <figure class="main"><img v-lazy="'/top2020/img/tc9_03_main.webp'" alt=""></figure>
            <figure class="sub"><img v-lazy="'/top2020/img/tc9_03_sub.webp'" alt=""></figure>
            <div class="txt">
              <p>他の教材にはないJJ ENGLISHエクササイズの特長の１つが、動画で学習できること。一般の教材ではテキスト（教本）や音声は当たり前ですが、当教材には、約25時間以上の動画（全311本）が付いています。<span class="marker2">動画の記憶定着率は、文字だけの場合にくらべて２～５倍以上と言われており、学習の効果もぐんと上がります。</span></p>
              <p>また、動画と音声（こちらは約8時間以上、全281本）は、教材に付属する再生・インストール兼用のディスクからパソコンにコピーして、そこからスマホやタブレットに移し替えることも可能。</p>
              <p>さらに購入者専用サイトにも同じ動画と音声が用意されているので、<span class="marker2">ファイルをダウンロードできるのはもちろん、ストリーミング再生にも対応しています。</span>自宅はもちろん、通勤・通学の合間にもエクササイズできるので、自分なりの学習スタイルをフレキシブルに組み立てられます。</p>
              <p class="kome">※DVD再生用のドライブがないパソコンをご使用の方は、専用サイトからのダウンロードかストリーミング再生をご利用ください。</p>
              <p class="kome red">※すべての動画ファイルは、学習時の動作性を優先してやや低画質に調整してあります。</p>
            </div>
          </div>
          <div class="cover inner01">
            <div class="d1 lazyload">
              <h4>個性溢れる3人の先生の動画で<br class="sp_only">楽しく学習！</h4>
              <p class="under">安河内哲也先生を始めとした4人の監修者と3人のネイティブが厳選した360個の頻出表現<br class="pc_only">
                <span class="marker2">（テンプレ表現中心）</span>を動画で学ぶエクササイズです（1本約2〜8分）。バラエティーのような動画なのに、その<span class="marker2">英語を「どう使う」「どんな場面で使う」かが刷り込まれます。</span>発音やジェスチャーの解説も動画ならでは。<br>
                テキストの１レッスンに３本ずつ。３人の先生が担当します。<br>
                <img class="icon" v-lazy="'/top2020/img/7_exercise.webp'" alt="">
              </p>
              <div class="box flexb inner02">
                <div class="txt">
                  <h5>スーパー・センテンス<span>担当：安河内哲也先生</span></h5>
                  <p>使い回しの効く基礎的な文法と構文、便利な言い回し、中学１年で習うhave、get、takeなどの超基本的な動詞（スーパー動詞）を解説します。</p>
                  <p class="example">
                    ・This is～（これは～です）<br>
                    ・It is…that～（～[文]は…だね）<br>
                    ・無生物主語<br>
                    など全120個
                  </p>
                </div>
                <div class="figure-wrapper">
                  <figure>
                    <img v-lazy="'/top2020/img/super1.webp'" alt="">
                  </figure>
                </div>
              </div>
              <div class="box flexb inner02">
                <div class="txt">
                  <h5>コンビニ・フレーズ<span>担当：水野 稚先生</span></h5>
                  <p>英語で頻繁に用いる熟語や構文を水野先生がやさしく解説。丸ごと覚えて使える、便利なフレーズばかりです。水野先生の得意なイギリス英語についても触れます。</p>
                  <p class="example">
                    ・in front of〜（〜の前）<br>
                    ・begin with～（～から始める）<br>
                    ・強調のdo/does<br>
                    など全120個
                  </p>
                </div>
                <div class="figure-wrapper">
                  <figure>
                    <img v-lazy="'/top2020/img/conveni.webp'" alt="">
                  </figure>
                </div>
              </div>
              <div class="box flexb inner02">
                <div class="txt">
                  <h5>クール・イングリッシュ<span>担当：横山カズ先生</span></h5>
                  <p>Well、Soなどの便利な合いの手や、教科書には載ってないけれど常識になっている新語、決まり文句などを解説します。</p>
                  <p class="example">
                    ・How come?（なぜ？）<br>
                    ・Wait a sec.（ちょっと待ってね）<br>
                    ・I see what you mean.（それ、わかる！）<br>
                    など全120個
                  </p>
                </div>
                <div class="figure-wrapper">
                  <figure>
                    <img v-lazy="'/top2020/img/tc9_03_03.webp'" alt="">
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PurchaseButton />
      </template>
      <template #footer>
        <TopFooter />
      </template>
    </TopLayout>
  </div>
</template>

<script>
import TopLayout from '@/components/TopLayout.vue'
import TopHeader from '@/components/TopHeader.vue'
import TopFooter from '@/components/TopFooter.vue'
import PurchaseButton from '@/components/PurchaseButton.vue'

export default {
  name: 'Tc20a',
  components: {
    TopLayout,
    TopHeader,
    TopFooter,
    PurchaseButton,
  },
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";
@include fade_animation;

h3 {
  text-align: center;
  font-size: 42px;
  font-weight: 700;
  color: #fe3340;
  margin-bottom: 40px;
}
.container {
  padding-top: 120px;
  padding-bottom: 120px;
}
.container p.under {
  margin-bottom: 70px;
	font-size:26px;
	line-height: 40px;
	font-weight: 700;
  text-align: center;
}
.title {
  position: relative;
}
.title figure.main {
  padding: 0 50px;
}
.title figure.sub {
  width: 30%;
  position: absolute;
  left: calc(50% - 500px);
  top: 39vw;
}
.title .txt {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 70px;
  width: 500px;
  margin: -400px calc(50% - 500px) 40px auto;
  position: relative;
  border-radius: 10px;
}
.title .txt p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.title .txt .blue {
  color: #0a74d7;
}
.title .txt p.kome {
  font-size: 12px;
  line-height: 16px;
  &.red {
    color: #fe3340;
  }
}
.title .txt p:last-child {
  margin-bottom: 0;
}
figure.icon {
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
  margin-bottom: 70px;
}
img.icon {
  height: 30px !important;
  width: auto;
  margin-top: 10px;
}
@media only screen and (max-width: 768px) {
  h3 {
    font-size: 23px;
    margin-bottom: 20px;
  }
  .title {
    margin-bottom: 30px;
  }
  .title figure.main {
    padding: 0 20px;
    margin-bottom: 110px;
  }
  .title figure.sub {
    width: 35%;
    left: 0;
    right: 0;
    top: 30vw;
    margin: 0 auto;
  }
  .title .txt {
    padding: 0 20px;
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
  .feature5 .title .txt {
    margin: 0;
  }
  .title .txt p {
    font-size: 14px;
    line-height: 24px;
  }
  .container{
    padding-top: 70px;
    padding-bottom: 50px;
  }
}
/*-----------------------------------------------------------*/
/*--------------------------feature3-------------------------*/
/*-----------------------------------------------------------*/
.feature3 .d1 {
  padding: 60px 85px;
  &.lazyloaded {
    background-image: url("/top2020/img/tc9_03_bg.webp");
  }
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
.feature3 .d1 h4 {
  height: 70px;
  background-color: #fe3340;
  color: #fff;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 100px;
  margin-bottom: 30px;
}
.feature3 .d1 .under {
  font-size: 16px;
  line-height: 24px;
}
.feature3 .d1 .box {
  margin-bottom: 90px;
}
.feature3 .d1 .box .txt {
  width: 45%;
}
.feature3 .d1 .box .txt .example {
  border: solid 1px black;
  padding: 10px;
  margin-top: 10px;
}
.feature3 .d1 .box .txt h5 {
  font-size: 24px;
  line-height: 30px;
  color: #fe3340;
  font-weight: 700;
  margin-bottom: 30px;
}
.feature3 .d1 .box .txt h5 span {
  display: block;
  font-size: 16px;
}
.feature3 .d1 .box .txt p {
  font-size: 16px;
  line-height: 24px;
}
.feature3 .d1 .box .figure-wrapper {
  width: 50%;
  figure {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .feature3 .d1 {
    padding: 20px;
    &.lazyloaded {
      background-image: url("/top2020/img/sp_tc9_03_bg.webp");
    }
  }
  .feature3 .d1 h4 {
    height: 65px;
    font-size: 15px;
    display: flex;
    border-radius: 50px;
    margin-bottom: 20px;
  }
  .feature3 .d1 .box {
    padding: 0;
    margin-bottom: 40px;
  }
  .feature3 .d1 .box .txt {
    width: 100%;
    margin-bottom: 20px;
  }
  .feature3 .d1 .box .figure-wrapper {
    width: 100%;
  }
}
::v-deep .purchase-button {
  margin-bottom: 120px;
}
</style>
