<template>
  <div>
    <Layout>
      <template #header>
        <Header />
      </template>
      <template #contents>
        <section class="column_sp2_pc2 imp_sec">
          <h2 class="imp_h2">「JJ ENGLISHエクササイズ」サンプル版</h2>
          <div class="imp_subpage_text imp_text_center">
            <h3>「JJ ENGLISHエクササイズ」のレッスン動画、会話動画、発音動画（ダイジェスト版）、テキストの一部(マニュアルを含む)をストリーミング再生または、ダウンロードして体験いただけます。</h3>
            実際のエクササイズをぜひご体験ください。スマートフォン、タブレットをご使用の方は、ファイルサイズが大きいものもあるため、Wi-Fi接続時でのご利用をおすすめします。
            また、複数のファイルを同時にダウンロードすることはできません。ファイルは１つずつダウンロードしてください。
            <br><br>
            [ダウンロードの方法] <br>
            ●Windows：「再生・ダウンロードボタン」を右クリックして「名前を付けてリンク先を保存」。<br>
            ●Mac：「control」キーを押しながら「再生・ダウンロードボタン」をクリック、またはトラックパッドを使って2本指でクリックまたはタップ。<br>マウスの設定によっても右クリックが使えます。<br>
            ●プレーヤーから：プレーヤーの右下にダウンロードのボタンがあります。
          </div>
          <div class="imp_subpage_text flex">
            <div class="cmn_l imp_trial">
              <h3>レクチャー動画サンプル（約78MB）</h3>
              <a href="https://vyqqhb44.user.webaccel.jp/trial_data/lecB1L19.mp4" target="_blank"><p>再生・ダウンロード <i class="fas fa-arrow-circle-right"></i></p></a>
            </div>
            <div class="cmn_r imp_trial_text">
              <h3>●レクチャー動画</h3>
              <p>
                3人の先生がバラエティー感覚でレクチャー。文法や熟語を「使う」意識が刷り込まれます。<br>
                各Lessonごとに、「スーパー・センテンス」「コンビニ・フレーズ」「クール・イングリッシュ」「これ何ていうの」（一部）が1本になっています。
              </p>
            </div>
            <div class="cmn_l imp_trial">
              <h3>会話動画サンプル（約10MB）</h3>
              <a href="https://vyqqhb44.user.webaccel.jp/trial_data/kaiwaB1L19.mp4" target="_blank"><p>再生・ダウンロード <i class="fas fa-arrow-circle-right"></i></p></a>
            </div>
            <div class="cmn_r imp_trial_text">
              <h3>●会話動画</h3>
              <p>
                画面の字幕を追いながら視聴する「ワイプリスニング」の動画です。英語特有の語順（文型や修飾の語順）が自然と耳で覚えられ、リスニング能力が向上します。紙のテキストの代わりに、パワー音読の｢チャンク音読｣｢感情音読」に使っても便利。
              </p>
            </div>
            <div class="cmn_l imp_trial">
              <h3>レクチャー音声サンプル（約4.2MB）</h3>
              <a href="https://vyqqhb44.user.webaccel.jp/trial_data/lecB1L19.mp3" target="_blank"><p>再生・ダウンロード <i class="fas fa-arrow-circle-right"></i></p></a>
            </div>
            <div class="cmn_r imp_trial_text">
              <h3>●レクチャー音声</h3>
              <p>
                「スーパー・センテンス」「コンビニ・フレーズ」「クール・イングリッシュ」の例文のネイティブによる音声です。ナチュラル＆スローが繰り返されます。機械式ではありません。この音声を使ってパワー音読。
              </p>
            </div>
            <div class="cmn_l imp_trial">
              <h3>会話音声サンプル（約1.8MB）</h3>
              <a href="https://vyqqhb44.user.webaccel.jp/trial_data/kaiwaB1L19.mp3" target="_blank"><p>再生・ダウンロード <i class="fas fa-arrow-circle-right"></i></p></a>
            </div>
            <div class="cmn_r imp_trial_text">
              <h3>●会話音声</h3>
              <p>
                ディクテーションや「タイムアタック音読」で使えます。
              </p>
            </div>
            <div class="cmn_l imp_trial bg_orange">
              <h3>テキストブックのサンプル（PDF）（約2.3MB）</h3>
              <a href="https://vyqqhb44.user.webaccel.jp/trial_data/JJEnglish-txt-sample00202000.pdf" target="_blank" ><p>ダウンロード <i class="fas fa-arrow-circle-right"></i></p></a>
            </div>
            <div class="cmn_r imp_trial_text">
              <h3>●テキストのサンプル</h3>
              <p>
                サンプル版で体験いただけるレクチャー動画、会話動画、レクチャー音声、会話音声のテキストです。各動画、音声を視聴するときには一緒にご覧ください。
              </p>
            </div>
            <div class="cmn_l imp_trial">
              <h3>パワー音読ガイドサンプル（約340MB）</h3>
              <a href="https://vyqqhb44.user.webaccel.jp/trial_data/ondoku-short-sample.mp4" target="_blank"><p>再生・ダウンロード <i class="fas fa-arrow-circle-right"></i></p></a>
            </div>
            <div class="cmn_r imp_trial_text">
              <h3>●パワー音読ガイドサンプル</h3>
              <p>
                パワー音読の練習法を動画で解説しています。チャンク音読、感情音読、ささやき音読、タイムアタック音読という、パワー音読の４つの練習法をご覧いただけます。
              </p>
            </div>
          </div>
        </section>
      </template>
      <template #footer>
        <Footer />
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from '@/components/Layout.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Trial',
  components: {
    Layout,
    Header,
    Footer,
  },
}
</script>

<style scoped lang="scss">
#contents .column_sp2_pc2 .imp_trial h3 {
  font-size: 16px;
  text-align: center;
  margin: 0;
}
.bg_orange {
  background-color: #FE7D0B !important;
}
</style>
