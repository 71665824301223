<template>
  <section id="tc21">
    <div class="container03 inner02">
      <h3>継続さえしていけば、やがてゴールが来ます</h3>
      <p class="p1">シンプル英語を習得するまでの時間は</p>
      <p class="large">４ヵ月の人もいれば、10ヵ月の人もいます。</p>
      <p class="image">
        <img class="pc" v-lazy="'/top2020/img/roadmap_1.webp'">
        <img class="sp" v-lazy="'/top2020/img/roadmap_1_sp.webp'">
      </p>
      <p class="large">
        自分のペースで、<br>
        <span class="marker">話せるようになるまで</span><br>
        「継続」すればいいだけ！
      </p>
      <p class="p4">英語を話すのに「才能」や「センス」は関係ありません。<br>
        ただし、上達のスピードには個人差があります。割ける学習時間も違うでしょう。ですから、<span class="marker2">上達のスピードをほかの人とくらべるのは、まったく無意味です。</span>
      </p>
      <p class="p4">
        <span class="marker2">この教材は身体を使う「エクササイズ主義」の教材です。</span>だから、さか上がりや自転車の練習と同じように、あせらないで継続さえしていけば、<span class="marker2">目標達成の日がやがてやって来ます。</span>
      </p>
      <p class="large">
        話せるようになるまで<br>
        追加料金はかかりません。
      </p>
    </div>
    <slot name="bt_purchase" />
    <div class="container03 inner02">
      <p class="p3 text">一番大事なのは、<br>ゴール到達まで続けることです！</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Tc21',
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.flex-center {
  @include flex-center;
}
.pc {
  display: inline-block;
}
.sp {
  display: none;
}
#tc21 {
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  background-position: bottom;
  position: relative;
  padding-bottom: 40px;
}
#tc21 h3 {
  font-size: 26px;
  text-align: center;
  margin-bottom: 40px;
  .large {
    font-size: 40px;
  }
  .border {
    font-size: 30px;
    display: inline-block;
    border: 1px solid black;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: 10px;
    margin-right: 10px;
  }
  &.top-margin {
    margin-top: 40px;
  }
}
#tc21 .container03 h3{
  color: #fff;
  border-radius: 80px;
  font-size: 26px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto 30px auto;
  font-weight: 700;
  background-color: #fe3340;
	width: 90%;
}
#tc21 .container03 p.p1{
  font-size: 24px;
	line-height: 49px;
	font-weight: 700;
	color:#fe3340;
	text-align: center;
	margin-bottom: 20px;
}
#tc21 .container03 .p2p3{
	justify-content: center;
	margin-bottom: 30px;
	align-items: baseline;
}
#tc21 .container03 p.p2{
  font-size: 28px;
	line-height: 38px;
	font-weight: 700;
}
#tc21 .container03 p.p2:after{
	content:"→";
	margin:0px 3px;
}
#tc21 .container03 p.p3{
  font-size: 40px;
	line-height: 38px;
	font-weight: 700;
	color:#fe3340;
  &.text {
    font-size: 40px;
    line-height: 1.4em;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  &.img {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
#tc21 .container03 p.p3 span{
	font-size: 60px;
}
#tc21 .container03.inner02 {
  max-width: 800px;
}
#tc21 p.p4{
  font-size: 16px;
	line-height: 24px;
	text-align: center;
	margin-bottom: 30px;
}
#tc21 .container03.inner02 {
  @include flex-center;
  flex-flow: column nowrap;
  p {
    text-align: left;
    &.large {
      font-size: 38px;
      font-weight: bold;
      margin-bottom: 40px;
    }
    &.image {
      margin-bottom: 20px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .pc {
    display: none;
  }
  .sp {
    display: inline-block;
  }
  #tc21 h3 {
    font-size: 20px;
    .large {
      font-size: 24px;
    }
    .border {
      font-size: 24px;
    }
  }
  #tc21 .container03{
    padding:0;
  }
  #tc21 .container03 h3{
    font-size: 15px;
    height: 50px;
    margin: 0 auto 20px auto;
  }
  #tc21 .container03 p.p1{
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  #tc21 .container03 .p2p3{
    justify-content: center;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center;
  }
  #tc21 .container03 p.p2{
    font-size: 21px;
    line-height: 28px;
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  #tc21 .container03 p.p2:after{
    content:"↓";
    margin:5px 0;
  }
  #tc21 .container03 p.p3{
    font-size: 32px;
    line-height: 31px;
    &.text {
      font-size: 27px;
    }
  }
  #tc21 .container03 p.p3 span{
    font-size: 45px;
  }
  #tc21 p.p4{
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
  #tc21 .container03.inner02 {
    p {
      &.large {
        font-size: 20px;
      }
    }
  }
}
</style>
