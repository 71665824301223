<template>
  <footer class="cf">
    <div class="footerarea cf">
      <ul class="fNav cf">
        <li><a :href="`${basePath}faq`">FAQ</a></li>
        <li><a :href="`${basePath}company`">会社情報</a></li>
        <li><a :href="`${basePath}privacy`">プライバシーポリシー</a></li>
        <li><a :href="`${basePath}low`">特定取引に関する法律に基づく表示</a></li>
        <li><a :href="`${basePath}inquiry`">お問い合わせ</a></li>
      </ul>
      <ul class="sns_area cf">
        <li><a href="https://www.facebook.com/JJ-English%E3%82%A8%E3%82%AF%E3%82%B5%E3%82%B5%E3%82%A4%E3%82%BA-109313670436348/?modal=admin_todo_tour" target="_blank"><img src="/img/facebook_bt.svg" alt="Facebook"></a></li>
        <li><a href="https://twitter.com/JJENGLISH14" target="_blank"><img src="/img/twitter_bt.svg" alt="Twitter"></a></li>
        <li><a href="https://www.youtube.com/channel/UCqwPqZr2YFDXpITqFhILgEA" target="_blank"><img src="/img/youtube_bt.svg" alt="Youtube"></a></li>
      </ul>
      <div class="inquiry_area cf">
        電話でのお問い合わせ<br>
        <span class="phone">0120-197917</span><br>
        10:00〜18:00（土日祝休）
      </div>
      <p>本気で話したいなら！</p>
      <h2><a :href="basePath"><img src="/img/jjenglish_logo2.svg" alt="JJ ENGLISH エクササイズ"></a></h2>
      <p>中学英語を使える英語に！</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    basePath() {
      return this.$store.state.basePath
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

footer {
  width: 100%;
  background-color: $color_red;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  color: $color_white;
  overflow: hidden;
  p {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    line-height: 1;
    color: $color_white;
    font-size: 1.5rem;
  }
  .footerarea {
    max-width: 1200px;
    margin: 0 auto;
  }
  .sns_area, .inquiry_area {
    margin: 22px auto;
    text-align: center;
    max-width:1080px;
  }
  .sns_area {
    margin-right: 16px;
    li {
      float: right;
      padding: 0;
      margin: 0 8px 0 8px;
    }
  }
  .inquiry_area {
    width: 216px;
    margin: 22px 0 22px auto;
    max-width:1080px;
    .phone {
      font-size: 20px;
    }
  }
  .fNav {
    float: left;
    margin-top: 22px;
    margin-left: 5%;
    padding-top: 0rem;
    text-align: center;
    width: 70%;
    ul {
      margin: 0;
      padding: 0 0 10px 0;
    }
    li {
      float: right;
      font-size: 17px;
      margin-left: 8px;
      margin-right: 8px;
      padding: 0 0 0 0;
      padding-left: 0;
      text-align: center;
      a {
        display: block;
        color: $color_white;
        width: 100%;
        padding-top: 10px;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        min-height: 24px;
        border: 0;
        background-color: $color_red;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition:all 1s ease;
        &:link {
          color: $color_white;
        }
        &:visited {
          color: $color_white;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {

  footer {
    .sns_area {
      width: 300px;
      margin-right: auto;
      li {
        float: left;
        margin: 0 27px 0 27px;
      }
    }
    .inquiry_area {
      width: 100%;
    }
    .fNav {
      float: none;
      display: inline-block;
      margin-top: 0.5rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
      width: auto;
    }
  }

}

@media screen and (max-width: 600px) {

  footer {
    .fNav {
      display: block;
      border-bottom: 15px solid $color_white;
      margin-top: 0;
      li {
        float: none;
        font-size: 1.333rem;
        margin-left: 0;
        margin-right: 0;
        a {
          border-bottom: 1px solid #fff;
          padding-bottom: 10px;
          &:hover {
            color: $color_red;
            background-color: $color_white;
            text-shadow: 0 0 10px #FEBABF;
            text-decoration: none;
          }
        }
      }
    }
  }

}
</style>
