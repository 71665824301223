<template>
  <div class="shopping-guide">
    <h2 class="red">INFORMATION<span><b v-if="basePath === '/'">通常版</b>お買い物ガイド</span></h2>
    <p class="shop" v-if="basePath === '/'">（DL版は専用ショップをご参照ください）</p>
    <div class="container01 inner01 flexb">
      <div class="left">
        <h3>【お支払い方法について】</h3>
        <p>クレジットカード決済、ID決済、NP後払い、代金引換がご利用いただけます。</p>
        <h4>●クレジットカード決済</h4>
        <p>JCB、VISA、Master Card、Diners Club、American Expressがご利用いただけます。</p>
        <figure class="f1"><img v-lazy="'/top2020/img/tc16_01.webp'" alt=""></figure>
        <p>※分割払い、リボ払いの際の分割手数料はお客様の<br>ご負担となります。</p>
        <template v-if="basePath === '/' || basePath === '/member/'">
          <h4>●ID決済</h4>
          <p>Amazon Payがご利用いただけます。</p>
          <figure class="f2"><img v-lazy="'/top2020/img/tc16_02.webp'" alt=""></figure>
        </template>
        <h4>●NP後払い</h4>
        <p>○このお支払方法の詳細<br>商品の到着を確認してから、「コンビニ」「郵便局」「銀行」「LINE Pay」で後払いできる安心・簡単な決済方法です。請求書は、商品とは別に郵送されますので、発行から14日以内にお支払いをお願いします。<br>○ご注意<br>後払い手数料：無料<br>後払いのご注文には、<a href="https://www.netprotections.com/" target="_blank">株式会社ネットプロテクションズ</a>の提供するNP後払いサービスが適用され、サービスの範囲内で個人情報を提供し、代金債権を譲渡します。<br>ご利用限度額は累計残高で55,000円（税込）迄です。<br>詳細はバナーをクリックしてご確認下さい。<br>ご利用者が未成年の場合、法定代理人の利用同意を得てご利用ください。</p>
        <figure class="f3"><img v-lazy="'/top2020/img/tc16_03.webp'" alt=""></figure>
        <h4>●代金引換</h4>
        <p>宅急便コレクトがご利用いただけます。</p>
        <figure class="f5"><img v-lazy="'/top2020/img/tc16_05.webp'" alt=""></figure>
        <p>※代引き手数料は無料です。</p>
      </div>
      <div class="right">
        <h3>【配送について】</h3>
        <p>・送料無料にてお届けいたします。<br>・配送はヤマト運輸（宅急便）でお届けいたします。<br>・14時までのご注文で当日発送いたします。<br>・定休日：土日祝・年末年始。</p>
        <h3>【58日間全額返金保証について】</h3>
        <p>
          20Lesson以上、当教材推奨のエクササイズを実践されても学習の成果がまったく得られないと感じられた場合、58日間の返金期間を設けております。<br>
          返金希望の方は info@jjenglish.co.jpまで、件名に「返金希望」と明記の上、メール本文に下記の内容を記載しお送りください。<br>
          ・お名前<br>
          ・返品理由（未開封や実践の形跡が認められない場合、返金をお断りする場合があります）<br>
          ・メールアドレス<br>
          ・注文番号<br>
          ・振込先の金融機関名・支店名・口座種別・口座番号・名義<br>
          ※弊社にて教材の返品を確認後、15営業日以内にお客様の口座に全額返金いたします。<br>
          ※購入時のセットが揃っている方に限ります。返品時の送料・振込手数料はお客様のご負担となります。<br>
          <span class="blue">※ポイントサイトをご利用してのご購入、領収書を発行してのご購入の場合は返金できません。ご注意ください。</span><br>
          ※58日間の保証はご注文日からの起算となります。
        </p>
        <figure class="f5 large-margin"><img v-lazy="'/top2020/img/tc16_06.webp'" alt=""></figure>
        <h3>JJ ENGLISHエクササイズはフィリピン・セブ島のボランティア団体の活動を支援しています。</h3>
        <p>NPO法人DAREDEMO HERO（誰でもヒーロー）は、フィリピン・セブ島で活動するボランティア団体です。貧困ゆえに学習ができない地元の子どもたちを支援し、将来、フィリピンの貧困問題を解決するリーダーを育てることを目的としています。JJイングリッシュ社では、教材の研究・制作過程でご縁のあったこの団体に、利益の一部を寄付するなどの支援を行っています。<br>DAREDEMO HERO公式ウエブサイト <a href="https://daredemohero.com" target="_blank" class="link">https://daredemohero.com</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShoppingGuide',
  computed: {
    basePath() {
      return this.$store.state.basePath
    }
  }
}
</script>

<style scoped lang="scss">
.shopping-guide {
  margin-top: 120px;
  .shop {
    text-align: center;
    position: relative;
    top: -60px;
    font-size: 16px;
  }
  .container01 .left {
    width: 49%;
  }
  .container01 .right {
    width: 49%;
  }
  .container01 h3 {
    font-size: 16px;
    line-height: 24px;
    color: #fe3340;
    margin-bottom: 20px;
  }
  .container01 h4 {
    font-size: 16px;
    line-height: 24px;
  }
  .container01 p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    .blue {
      color: blue;
    }
  }
  .container01 p a{
    color:#000;
    text-decoration: underline;
  }
  .container01 figure {
    margin: 10px 0;
  }
  .container01 figure.f1 {
    width: 100%;
  }
  .container01 figure.f2 {
    width: 50%;
    margin-bottom: 30px;
  }
  .container01 figure.f3 {
    width: 100%;
    margin-bottom: 30px;
  }
  .container01 figure.f4 {
    width: 100%;
    margin-bottom: 30px;
  }
  .container01 figure.f5 {
    width: 30%;
  }
  .container01 figure.f5.large-margin {
    margin-top: 144px;
  }
  @media only screen and (max-width: 768px) {
    .shop {
      text-align: center;
      position: relative;
      top: -30px;
      font-size: 14px;
    }
    .container01 .left {
      width: 100%;
    }
    .container01 .right {
      width: 100%;
    }
    .container01 figure.f5.large-margin {
      margin-top: 40px;
    }
    .container01 h3 {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 10px;
    }
    .container01 p {
      font-size: 14px;
      line-height: 24px;
    }
  }
}
</style>
