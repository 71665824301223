<template>
  <section id="tc1" class="lazyload">
    <p class="app">
      <span>
        スマホやタブレットで<br>
        アプリのようにも学習できる！
      </span>
    </p>
    <div class="covid" v-if="basePath !== '/member/'">
      <p v-if="basePath === '/gold/'" class="welcome">
        法人会員様特価ショップへようこそ
      </p>
      <figure><img v-lazy="'/top2020/img/campaign.png'"></figure>
      <p>
        <span class="note">※海外渡航及び入国条件の変更など諸状況の変化により予告なく終了する場合があります。</span>
      </p>
    </div>
    <h2 v-if="basePath === '/member/'">
      <img v-lazy="'/top2020/img/member_copy_pc.webp'" class="pc"><br>
      <img v-lazy="'/top2020/img/member_copy_sp.webp'" class="sp"><br>
      <span class="blue strong">5月1日〜5月31日までの期間限定</span><br>
      <span class="blue round">
        ※当サイトは Google、yahoo 等では検索できません。<br>
        <span class="strong">今すぐブックマークをお願いします！！</span>
      </span>
    </h2>
    <div class="container2">
      <strong class="black_red"><span class="red">中学英語で世界と話せる！</span></strong>
      <p class="under">
        みなさんご存知でしたか？<br>
        外国人と英語でコミュニケーションするには、中学英語の表現で十分だということを。<br>
        じつはこれ、英語を話せる人たちがみな口をそろえる、揺るぎない事実なのです。
      </p>
    </div>
    <div class="container flexb inner01">
      <figure>
        <img v-lazy="'/top2020/img/tc1_01_2.webp?date=20210508'" alt="">
        <!-- <img v-lazy="'/top2020/img/tc1_01_2_2.webp?date=20210802'" alt=""> -->
      </figure>
      <div class="txt">
        <p class="p1">本気で話したいなら<br class="pc_only">
          <span class="marker">中学英語をフル活用できる</span><br class="pc_only">
          ようになるのが確実で早い</p>
        <p class="p2">なぜって、ネイティブの日常会話だって<br class="pc_only">文法のほとんどは中学英語レベルだから</p>
        <p class="p3">ネイティブの英語をすごく高度な英語だと思っていませんか？</p>
        <p class="p3">私たちの日頃の会話を思い出してください。小学５年生でもわかるレベルの日本語で話しているはずです。英語ネイティブだって事情は同じ。<span class="marker2">日常会話のほとんどは日本の中学英語レベルの文法や動詞を使って話しているのです。</span></p>
        <p class="p3">ましてや、世界人口の80％以上を占める<span class="marker2">非ネィティブの英語は、まさに中学英語のフル活用そのもの。</span>お国訛りも気にしません。</p>
        <p class="p3">そしていまや、そんな彼らのシンプルな英語は、世界の街角だけでなく、ビジネスの現場や国際会議でも堂々と飛び交っているのです。これぞまさしく “グローバルEnglish”！</p>
        <p class="p3">つまり英語を話すために必要なのは、高度な英語知識やネイティブ並みの発音などではなく、学校で習った中学英語の「知識」を「技能」に変える。<span class="marker2">実際の会話で、聞けて、話せて、フル活用できる力を身につけることだけ</span>なのです。</p>
        <p class="p3">それなら私たち“純ジャパ”にも十分可能！ 純ジャパが目指すグローバルEnglishだから“純ジャパEnglish”。さあ、あなたも世界に通じる英語を身につけて、世界を生きるあなたになってください！</p>
      </div>
    </div>

    <div class="inner02">
      <p>
        <span class="small black">JJ ENGLISHエクササイズは<br>
        日本でしか英語を学んだことのない“純ジャパ”の私たちが、</span><br>
        <span class="marker">
          ① 最小限の学習（エクササイズ）で、<br>
          ② 中学英語の「知識」を聞けて話せる「技能」に変えて、<br>
          ③ <span class="blink">最短距離</span>で<span class="blink">着実</span>に話せるようになっていく、
        </span>
        <span class="black">そこに特化した、初〜中級者向け英会話教材です</span><br>
        <br>
        <span class="center">
          <span class="black">合言葉は、</span><br>
          <span class="p1 black">“純ジャパEnglish”でいいじゃない！</span>
        </span>
      </p>
    </div>
    <div class="container inner03">
      <p class="notice">※“純ジャパ”とは、海外生活や長期留学の経験がない人を指すキャンパス言葉です。国籍などは関係ありません。</p>
    </div>
    <div class="flow-outer">
      <div class="container flow inner01">
        <div class="container04 inner01">
          <h3>
            <span class="round">この教材は、こんな方たちに向いています</span>
          </h3>
          <div class="flex-center">
            <ul>
              <li><span class="blue">●</span>どんな学習法や教材が適切なのかわからない。</li>
              <li><span class="blue">●</span>今度こそ、絶対に「話せる人」になりたい！</li>
              <li><span class="blue">●</span>ほかの教材や方法で挫折してきた。</li>
              <li class="margin"><span class="blue">●</span>中学英語ならなんとか思い出せそう。</li>
              <li><span class="blue">●</span>英会話教室に通っているけど成長が感じられない。</li>
              <li><span class="blue">●</span>オンライン英会話を続けているけど成長が感じられない。</li>
              <li class="margin"><span class="blue">●</span>英会話アプリ学習の効果をあまり感じられない。</li>
              <li><span class="blue">●</span>TOEICのリスニング対策をしたい。</li>
              <li class="margin"><span class="blue">●</span>英検のリスング＆面接対策をしたい。</li>
              <li><span class="blue">●</span>TOEICが600点以上なのに話せない。</li>
              <li><span class="blue">●</span>留学したけど話せない。</li>
              <li><span class="blue">●</span>昔はある程度話せたけどすっかり鈍ってしまった。</li>
            </ul>
            <p>
              もどかしい悩みばかりですね。でも、安心してください。<br>
              この教材は、まさにそうした問題を解決するために開発されました。<br>
              <br>
              スポーツや音楽と同じ「エクササイズ主義」のこの教材では、<br>
              上達を実感できないということはあり得ません。
            </p>
          </div>
          <h3 class="under">
            「やってもあまり進歩を感じられない」<br>
            そんな<span class="marker">悩み</span>や<span class="marker">不安</span>には<br>
            さっさとサヨナラしましょう！
          </h3>
          <h3 class="under">
            <span class="small">
              <span class="black">＼ 結果が実力の証 ／</span>
              <span class="black normal">購入者の声を先に読みたい人は<a href="#review">コチラ</a>から</span>
            </span>
          </h3>
        </div>
        <div class="check-list">
          <p class="title">
            あなたは英会話の学習で<br>
            こんな勘違いをしていませんか？
          </p>
          <ul>
            <li>英語が話せないのは⾼度な⽂法や単語⼒が不⾜しているからだと思い込み、それらを新たに“勉強”している。</li>
            <li>シャワーのように英語を聞き続ければ英語が聞き取れるようになると信じて、ひたすら英語を聞き流している。</li>
            <li>初級者なのに「ネイティブに笑われない表現」といった類の表現をたくさん丸暗記しようとしている。</li>
            <li>ネイティブのような発⾳が身についていないから英語が話せないと思っている。</li>
            <li>じつのところ、１年位留学ができれば、誰でも話せるようになると思っている。</li>
          </ul>
          <p class="footer">
            もし、あなたが上記の２つ以上に該当しているのなら、残念ながら、<br>
            あなたの努力はほとんど空回りをしている可能性が大です。<br>
            <br>
            なぜなら、“純ジャパ”の私たちが日本にいながらにして、<br>
            「話せる英語力」を確実かつ効率的に身につけるためには、<br>
            <span class="marker">以下のような学習過程が必須だからです。</span>
          </p>
        </div>
        <h2>
          <!-- JJ ENGLISHエクササイズを継続すると<br>
          <pan class="large">中学英語の<span class="marker">「知識」</span>が 聞けて話せる<br>
          <span class="marker">「技能」</span>に変わる</pan> -->
          <img class="pc" v-lazy="'/top2020/img/tc1_02_1.webp'">
          <img class="sp" v-lazy="'/top2020/img/tc1_02_1_sp.webp'">
        </h2>
        <img class="pc flow-image" v-lazy="'/top2020/img/flow_1.webp'">
        <img class="sp flow-image" v-lazy="'/top2020/img/flow_1_sp.webp'">
        <div class="text2">
          <div class="text-inner">
            <p class="blue center">
              ほんの一例ですけれど‥<br><span class="large">「帰ったらお昼ごはんだよ」と言いたいとき、</span>
            </p>
          </div>
        </div>
        <img class="pc flow-image" v-lazy="'/top2020/img/flow_2.webp'">
        <img class="sp flow-image" v-lazy="'/top2020/img/flow_2_sp.webp'">
        <div class="text2">
          <div class="text-inner">
            <p>
              それだけで<br>
              <span class="large">こんなふうになれるからです。</span><br>
              （こうなるだけで、英語はかなり話せる）<br>
              （「ネイティブ並み」はこのレベルの先にしかない！）
            </p>
          </div>
        </div>
        <div class="text">
          <div class="text-inner">
            <p>
              中学英語の「知識」を聞けて話せる「技能」に変える。
            </p>
            <p>
              この過程を踏まないで、「発音も文法も間違いのない英語」や<br>
              「ネイティブ並み」をめざす学習法は、<span class="marker2">ほぼ100％失敗します。</span>
            </p>
            <p>
              それはまるで、家の土台より先に屋根やカベを造ろうとするようなものだからです。
            </p>
          </div>
          <div class="text-inner">
            <p>
              <em>成果が出ないのは学習者の責任ではありません。<br>
                  間違っているのはそんな「学習法」のほうなのです。</em>
            </p>
          </div>
          <div class="text-inner">
            <p>
              もしあなたが中学英語をおおよそ思い出せそうで、<br>
              その「知識」を会話で使える「技能」に変えるトレーニングを始めたなら
            </p>
          </div>
          <div class="text-inner">
            <p>
              <em>英会話は、やればやるほど着実に上達できます。</em>
            </p>
          </div>
          <div class="text-inner">
            <p class="center">
              ところで、やるって何を？<br>
              <em>はい。<span class="marker">適切な質と内容のテキスト</span>を使った、<br>
              <span class="marker">口や耳を動かす正しいエクササイズ</span>です。</em><br>
            </p>
          </div>
          <div class="text-inner">
            <p class="center">
              JJ ENGLISHエクササイズは、<br>
              <em>それを実践するための専用教材です。</em>
            </p>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: 'Tc1',
  computed: {
    basePath() {
      return this.$store.state.basePath
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";
.sp {
  display: none;
}
.pc {
  display: inline-block;
}
.container2 {
  max-width: 890px;
  margin-left: auto;
  margin-right: auto;
}
section {
  .black_red {
    text-align: left;
    padding: 0 25px;
  }
}
#tc1 {
  &.lazyloaded {
    background-image: url("/top2020/img/tc1_bg.webp");
  }
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  margin-top: -220px;
  padding-top: 246px;
  padding-bottom: 84px;
  .dl_img {
    margin-top: 40px;
  }
  .app {
    text-align: center;
    margin-bottom: 72px;
    @media only screen and (max-width: 768px) {
      margin-bottom: 30px;
    }
    span {
      font-size: 40px;
      color: #fe3340;
      font-weight: 700;
      display: inline-block;
      text-align: left;
    }
  }
  .campaign {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
  }
  h2 {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 80px;
    font-size: 24px;
    .strong {
      font-size: 36px;
    }
    .sub {
      display: inline-block;
      font-size: 18px;
      font-weight: normal;
      margin-top: 30px;
      padding-left: 20px;
      padding-right: 20px;
    }
    .blue {
      color: #0a74d7;
      &.round {
        display: inline-block;
        background-color: white;
        margin-top: 30px;
        padding: 26px;
        border: 2px solid #0a74d7;
        border-radius: 20px;
      }
    }
  }
  .plus {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
  .dl_img {
    margin-bottom: 120px;
    text-align: center;
    img {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    }
  }
}
#tc1 .flow-outer {
  background-color: white;
  padding-bottom: 84px;
  margin-bottom: -130px;
  // padding-top: 200px;
  .check-list {
    margin-top: 40px;
    margin-bottom: 120px;
    @media only screen and (max-width: 768px) {
      margin-top: 20px;
      margin-bottom: 60px;
    }
    .title {
      background-color: #081f5c;
      color: white;
      font-size: 32px;
      font-weight: bold;
      border-radius: 12px;
      line-height: 1.4em;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;
      @media only screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
    ul {
      width: 75%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;
      li {
        display: flex;
        align-items: center;
        color: black;
        font-size: 18px;
        margin-bottom: 20px;
        position: relative;
        @media only screen and (max-width: 768px) {
          font-size: 14px;
        }
        &::before {
          display: block;
          flex-shrink: 0;
          content: "";
          width: 50px;
          height: 50px;
          margin-right: 10px;
          background-image: url("/top2020/img/085115.png");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
    .footer {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      font-size: 23px;
      font-weight: bold;
      text-align: center;
      @media only screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
  .flow {
    padding-top: 40px;
    margin-top: 80px;
    @media only screen and (max-width: 768px) {
      margin-top: 10px;
    }
    h2 {
      font-size: 26px;
      display: block;
      margin-bottom: 10px;
      color: black;
      border-radius: 32px;
      padding-left: 32px;
      padding-right: 32px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
      font-weight: normal;
      .large {
        font-size: 40px;
        .marker {
          font-size: 50px;
        }
      }
    }
    .text, .text2 {
      text-align: center;
      margin-top: 80px;
      .text-inner {
        display: inline-block;
        p {
          margin-bottom: 20px;
          text-align: left;
          em {
            font-size: 38px;
            font-weight: bold;
            font-style: normal;
            margin-top: 20px;
            margin-bottom: 20px;
            display: inline-block;
          }
          &.blue {
            color: #0a74d7;
            margin-top: 60px;
          }
          &.center {
            text-align: center;
          }
          .large {
            font-size: 28px;
          }
        }
      }
      &.text2 {
        margin-top: 0;
        .text-inner {
          p {
            text-align: center;
          }
        }
      }
    }
  }
}
#tc1 .youtube {
  margin-top: 60px;
  // margin-bottom: -200px;
  iframe {
    width: 750px;
    height: 417px;
    max-width: calc(100% - 20px);
    display: block;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: 768px) {
      height: 222px;
    }
  }
  p {
    width: 750px;
    max-width: calc(100% - 20px);
    text-align: left;
    color: #fe3340;
    font-size: 23px;
    font-weight: 700;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    &.black {
      color: black;
    }
  }
}
#tc1 .covid {
  margin-top: 80px;
  margin-bottom: 110px;
  @media only screen and (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .welcome {
    font-size: 40px;
    font-weight: bold;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 40px;
  }
}
#tc1 .covid figure {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
#tc1 .covid p {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  .note {
    font-size: 14px;
  }
}
#tc1 .under {
  margin-bottom: 75px;
  text-align: left;
  padding: 0 25px;
  .red {
    font-size: 68px;
    line-height: 84px;
    font-weight: 700;
    color: $color_red;
  }
}
#tc1 .container figure {
  width: 40%;
}
#tc1 .container .txt {
  width: 50%;
}
#tc1 .container .txt .p1 {
  font-size: 40px;
  line-height: 60px;
  margin-bottom: 10px;
  font-weight: 700;
}
#tc1 .container .txt .p2 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  margin-bottom: 10px;
}
#tc1 .container .txt .p3 {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
  letter-spacing: 0.3px;
}
#tc1 .inner01 {
  margin-bottom: 40px;
}
#tc1 .inner02 {
  max-width: none;
  width: 880px;
  text-align: center;
}
#tc1 .inner02 p{
  display: inline-block;
  margin: 0 auto;
  text-align: left;
  color: #fe3340;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0.3px;
  position: relative;
  .center {
    display: inline-block;
    text-align: center;
    width: 100%;
  }
  .p1 {
    font-size: 48px;
  }
  .small {
    font-size: 23px;
    display: inline-block;
  }
  .black {
    color: black;
  }
  .blink {
    animation: neon_blink 1s infinite alternate;
  }
}
@keyframes neon_blink {
  0% {
    text-shadow: 0px 0px 14px rgba(255, 165, 0, 1.0),  0px 0px 14px rgba(255, 165, 0, 1.0), 0px 0px 14px rgba(255, 165, 0, 1.0);
  }
  100% {
    text-shadow: 0px 0px 14px rgba(255, 165, 0, 0.0),  0px 0px 14px rgba(255, 165, 0, 0.0), 0px 0px 14px rgba(255, 165, 0, 0.0);
  }
}
#tc1 .inner02 .marker {
  position: relative;
  display: block;
}
#tc1 .inner02 .marker::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 100%;
  background: url("/top2020/img/fukidashi2.webp") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -40px;
  bottom: 0;
}
#tc1 .inner02 .marker::after {
  content: '';
  display: inline-block;
  width: 16px;
  height: 100%;
  background: url("/top2020/img/fukidashi2.webp") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: -40px;
  bottom: 0;
}
#tc1 .inner03 {
  p {
    font-size: 14px;
    text-align: center;
  }
  margin-bottom: 40px;
  margin-top: 60px;
}
.flex-center {
  @include flex-center;
  flex-flow: column nowrap;
  margin-bottom: 40px;
}
#tc1 .container04 {
  max-width: none;
  text-align: center;
}
#tc1 .container04 h3 {
  width: 800px;
  padding: 0;
  margin: 0 auto;
  position: relative;
  color: #fe3340;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.3px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 80px;
  &.under {
    margin-top: 0;
    margin-bottom: 40px;
    line-height: 1.4em;
    .black {
      display: block;
      color: black;
    }
    .normal {
      font-weight: normal;
    }
    .small {
      font-size: 19px;
      a {
        text-decoration: underline;
        color: blue;
      }
    }
  }
  .round {
    display: block;
    color: #fff;
    border-radius: 80px;
    font-size: 26px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto 30px auto;
    font-weight: 700;
    background-color: #fe3340;
    width: 90%;
    margin-bottom: 20px;
  }
}
#tc1 .container04 ul {
  margin-bottom: 40px;
}
#tc1 .container04 ul li {
  font-size: 19px;
  font-weight: 700;
  margin-top: 4px;
  margin-right: 20px;
  &.margin {
    margin-bottom: 20px;
  }
}
#tc1 .container04 ul li .blue {
  color: #0a74d7;
}
@media only screen and (max-width: 768px) {
  .sp {
    display: inline-block;
  }
  .pc {
    display: none;
  }
  .container2 {
    max-width: calc(100% - 20px);
  }
  #tc1 {
    &.lazyloaded {
      background-image: url("/top2020/img/sp_tc1_bg.webp");
    }
    margin-top: -150px;
    padding-top: 330px;
    padding-bottom: 50px;
    .app {
      span {
        font-size: 18px;
      }
    }
    .dl_img {
      padding-top: 100px;
      margin-bottom: 60px;
      text-align: center;
      img {
        width: 90%;
      }
    }
    h2 {
      font-size: 15px;
      .strong {
        font-size: 21px;
      }
      .sub {
        font-size: 14px;
      }
    }
  }
  #tc1 .under {
    margin-bottom: 20px;
    padding: 0 25px;
    .red {
      font-size: 27px;
      line-height: 34px;
    }
  }
  #tc1 .container{
    flex-direction: column;
  }

  #tc1 .container figure {
    width: 58%;
    margin: 0 auto 25px auto;
  }
  #tc1 .container .txt {
    width: 100%;
    margin: 0 auto;
  }
  #tc1 .container .txt .p1 {
    font-size: 23px;
    line-height: 30px;
  }
  #tc1 .container .txt .p2 {
    font-size: 15px;
    line-height: 24px;
  }
  #tc1 .container .txt .p3 {
    font-size: 14px;
    line-height: 24px;
  }
  #tc1 .inner01 {
    margin-bottom: 26px;
  }
  #tc1 .inner02 {
    width: auto;
  }
  #tc1 .inner02 p {
    font-size: 18px;
    padding: 0;
    .p1 {
      font-size: 23px;
    }
    .small {
      font-size: 12px;
    }
  }
  #tc1 .inner02 .marker::before {
    width: 7px;
    left: -20px;
    bottom: 0;
  }
  #tc1 .inner02 .marker::after {
    width: 7px;
    right: -20px;
    bottom: 0;
  }
  #tc1 .flow-outer {
    .flow {
      .text {
        .text-inner {
          p {
            em {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
  #tc1 .inner03 {
    p {
      font-size: 12px;
    }
  }
  #tc1 .container04 h3 {
    width: 100%;
    font-size: 24px;
    .round {
      font-size: 15px;
      height: 50px;
      width: 100%;
      margin: 0 auto 0 auto;
    }
  }
  #tc1 .container04 h3::before {
    width: 20px;
    height: 50px;
    left: -30px;
  }
  #tc1 .container04 h3::after {
    width: 20px;
    height: 50px;
    right: -30px;
  }
  #tc1 .container04 ul {
    width: auto;
  }
  #tc1 .container04 ul li {
    font-size: 14px;
  }
}
</style>
