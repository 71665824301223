<template>
  <section id="tc4" class="lazyload">
    <figure class="f1"><img v-lazy="'/top2020/img/tc4_01.webp'" alt=""></figure>
    <p class="p0">「JJ ENGLISHエクササイズ」のベースとなった教材とは？</p>
    <strong class="black_red">延べ45万人以上が購読</strong>
    <p class="p1"><span class="marker">講談社が開発した英会話教材の決定版がベース</span></p>
    <div class="container01 flexb inner01">
      <figure><img v-lazy="'/top2020/img/tc4_02.webp'" alt=""></figure>
      <div class="txt">
        <p>当教材のベースとなったのは、延べ45万人以上が購読した『安河内哲也直伝！ おもてなし純ジャパENGLISH』。発行元の講談社による調査では、<span class="marker2">ユーザーの満足度は93％以上</span>を獲得しています。</p>
        <p>「JJ ENGLISHエクササイズ」では、同書に送られてきたユーザーの声や日本で英語を話せるようになった78名の成功者のアドバイスをもとに、効果的なエクササイズ方法などを大幅にプラスオン。<span class="marker2">伝説的人気教材がさらに大きく進化したスーパーな教材となりました。</span></p>
        <p>当教材にも引き継がれた中級者向けコンテンツ「日本文化おもしろライブラリー」は5ヵ国語に翻訳されてAmazonオーディブルのコンテンツとして世界に向けて有料発信されています。</p>
      </div>
      <p class="jje">
        株式会社JJイングリッシュ社は、株式会社講談社のライセンシー社です。<br>
        <br>
        「JJ ENGLISHエクササイズ」は株式会社JJイングリッシュ社の登録商標です。
      </p>
    </div>
    <div class="container02 inner01">
      <h3>多くのメディアで高い評価を獲得！</h3>
      <p class="under">中学英語の「知識」を「技能」に変えるコンセプトが評価されました。その一部を紹介します。</p>
      <div class="flexb cover">
        <div class="media">
          <figure><img v-lazy="'/top2020/img/tc4_03.webp'" alt=""></figure>
          <p>AERA English<br>
            2020年<br>
            Autumn&Winter号</p>
        </div>
        <div class="media">
          <figure><img v-lazy="'/top2020/img/tc4_04.webp'" alt=""></figure>
          <p>anan<br>
            2020年5月27日発売号</p>
        </div>
        <div class="media">
          <figure><img v-lazy="'/top2020/img/tc4_05.webp'" alt=""></figure>
          <p>Hanako<br>
            2020年6月号</p>
        </div>
        <div class="media">
          <figure><img v-lazy="'/top2020/img/tc4_06.webp'" alt=""></figure>
          <p>令和のベストヒット大賞2020<br>
            2020年9月発行</p>
        </div>
      </div>
    </div>
    <p class="jbr">
      <strong>［ウェブサイトでの掲載実績も多数］</strong><br>
      PRESIDENT Online／東洋経済オンライン／東洋経済education×ICT／ORICON NEWS／ニコニコニュース／時事ドットコム／ウレぴあ総研／FNNプライムオンライン／＠niftyビジネス／読売新聞オンライン／朝日新聞デジタルマガジン＆／BIGLOBEニュース／マピオンニュース／エキサイトニュース／News Cafe／News Picks<span class="more">ほか</span>
      <img v-lazy="'/top2020/img/jbr.jpg'" />
    </p>
  </section>
</template>

<script>
export default {
  name: 'Tc4',
}
</script>

<style scoped lang="scss">
#tc4 {
  margin-top: 60px;
  &.lazyloaded {
    background-image: url("/top2020/img/tc4_bg.webp");
  }
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: bottom;
  padding-bottom: 190px;
  position: relative;
  z-index: 2;
  .jbr {
    text-align: center;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    max-width: 920px;
    @media only screen and (max-width: 768px) {
      width: 100%;
      padding: 0 20px;
      font-size: 14px;
    }
    img {
      display: block;
      max-width: 1000px !important;
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
    .more {
      margin-left: 2em;
    }
  }
}
#tc4 .f1 {
  width: 400px;
  margin: 0 auto 20px auto;
  position: relative;
  height: 120px;
}
#tc4 .f1 img {
  position: absolute;
  bottom: 0;
}
#tc4 .f2 {
  width: 220px;
  margin: 0 auto 20px auto;
}
#tc4 strong {
  margin-bottom: 60px;
}
#tc4 .p0 {
  color: #fe3340;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  margin-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
}
#tc4 .p1 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 50px;
}
#tc4 .container01 {
  margin-bottom: 100px;
}
#tc4 .container01 figure {
  width: 46%;
}
#tc4 .container01 .txt {
  width: 49%;
}
#tc4 .container01 .txt p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
}
#tc4 .container01 .jje {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  padding-bottom: 20px;
}
#tc4 .container02 h3 {
  background-color: #fe3340;
  width: 100%;
  height: 95px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 34px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 30px;
}
#tc4 .container02 .under {
  font-size: 18px;
  margin-bottom: 30px;
}
#tc4 .container02 .media {
  width: 24%;
}
#tc4 .container02 .media figure {
  margin-bottom: 20px;
  text-align: center;
  img {
    width: 85%;
  }
}
#tc4 .container02 .media p {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  #tc4 {
    &.lazyloaded {
      background-image: url("/top2020/img/sp_tc4_bg.webp");
    }
    padding-bottom: 85px;
  }
  #tc4 .f1 {
    width: 200px;
  }
  #tc4 .f2 {
    width: 100px;
  }
  #tc4 strong {
    margin-bottom: 20px;
  }
  #tc4 .p1 {
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
  }
  #tc4 .container01 {
    margin-bottom: 50px;
  }
  #tc4 .container01 figure {
    width: 75%;
    margin: 0 auto 20px auto;
  }
  #tc4 .container01 .txt {
    width: 100%;
  }
  #tc4 .container01 .txt p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  #tc4 .container02 h3 {
    height: 50px;
    border-radius: 50px;
    font-size: 15px;
  }
  #tc4 .container02 .under {
    font-size: 14px;
    line-height: 24px;
    padding: 0;
  }
  #tc4 .container02 .media {
    width: 48%;
    margin-bottom: 20px;
  }
  #tc4 .container02 .media p {
    font-size: 12px;
    line-height: 16px;
  }
  #tc4 .container02 .media figure {
    margin-bottom: 10px;
  }
}
</style>
