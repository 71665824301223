import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    basePath: '/',
    shopUrl: 'https://jjenglish.shop-pro.jp/',
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
