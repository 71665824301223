<template>
  <div class="share-button-wrapper">
    <!-- <a href="https://b.hatena.ne.jp/entry/" class="hatena-bookmark-button" data-hatena-bookmark-layout="basic-label-counter" data-hatena-bookmark-lang="ja" title="このエントリーをはてなブックマークに追加"><img src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png" alt="このエントリーをはてなブックマークに追加" width="20" height="20" style="border: none;" /></a><script type="text/javascript" src="https://b.st-hatena.com/js/bookmark_button.js" charset="utf-8" async="async"></script>
    <iframe :src="`https://www.facebook.com/plugins/share_button.php?href=${encodeURIComponent(url)}&layout=button_count&size=small&appId=710828682812034&width=103&height=20`" width="103" height="20" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
    <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" class="twitter-share-button" data-show-count="false">Tweet</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> -->
  </div>
</template>

<script>
export default {
  name: 'ColumnShareButtons',
  props: {
    url: {
      type: String,
      required: true,
    }
  }
}
</script>
