<template>
  <div>
    <TopLayout>
      <template #header>
        <TopHeader />
      </template>
      <template #contents>
        <div class="container feature2">
          <h3>ワイプリスニング</h3>
          <p class="under">動くテロップを見ながら聞くだけで、驚きの効果。<br>
            動画ならではの画期的エクササイズ!!<br>
            <img class="icon" v-lazy="'/top2020/img/7_exercise.webp'" alt="">
          </p>
          <div class="title">
            <figure class="main"><img v-lazy="'/top2020/img/tc9_02_main.webp'" alt=""></figure>
            <figure class="sub movie up">
              <a href="https://vyqqhb44.user.webaccel.jp/movie/kaiwaB1L02.mp4" target="_blank">
                <img v-lazy="'/top2020/img/tc9_02_sub.webp'" alt="">
              </a>
              <p class="movie-text">↑クリックしてサンプルを見る</p>
            </figure>
            <div class="txt">
              <p>リスニングが苦手な人にうってつけのエクササイズが、JJ ENGLISHエクササイズ独自のワイプリスニングです。</p>
              <p>メイン会話文の動画を再生するとカラオケのように英文の字幕（テロップ）が流れていくので、何度も繰り返し見ている間に、</p>
              <p class="red">
                ・長短の会話文に耳が慣れる<br>
                ・英文と音声が強制的に結びつく<br>
                ・正しい発音や英語特有の音声の脱落・連結、語順の感覚が自然と身につく
              </p>
              <p>
                などの効果が得られ、リスニング力が飛躍的に向上します。<br>
                <span class="blue">★メインテキストの会話文や特典「日本文化おもしろライブラリー」などで実践します。</span>
              </p>
            </div>
          </div>
          <div class="cover inner01"></div>
        </div>
        <PurchaseButton />
      </template>
      <template #footer>
        <TopFooter />
      </template>
    </TopLayout>
  </div>
</template>

<script>
import TopLayout from '@/components/TopLayout.vue'
import TopHeader from '@/components/TopHeader.vue'
import TopFooter from '@/components/TopFooter.vue'
import PurchaseButton from '@/components/PurchaseButton.vue'

export default {
  name: 'Tc20b',
  components: {
    TopLayout,
    TopHeader,
    TopFooter,
    PurchaseButton,
  },
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";
@include fade_animation;

h3 {
  text-align: center;
  font-size: 42px;
  font-weight: 700;
  color: #fe3340;
  margin-bottom: 40px;
}
.container {
  padding-top: 120px;
}
.container p.under {
  margin-bottom: 70px;
	font-size:26px;
	line-height: 40px;
	font-weight: 700;
  text-align: center;
}
.title {
  position: relative;
}
.title figure.main {
  padding: 0 50px;
}
.title figure.sub {
  width: 30%;
  position: absolute;
  left: calc(50% - 500px);
  top: 39vw;
}
.title .txt {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 70px;
  width: 500px;
  margin: -400px calc(50% - 500px) 40px auto;
  position: relative;
  border-radius: 10px;
}
.title .txt p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.title .txt .blue {
  color: #0a74d7;
}
.title .txt p.kome {
  font-size: 12px;
  line-height: 16px;
  &.red {
    color: #fe3340;
  }
}
.title .txt p:last-child {
  margin-bottom: 0;
}
figure.icon {
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
  margin-bottom: 70px;
}
img.icon {
  height: 30px !important;
  width: auto;
  margin-top: 10px;
}
@media only screen and (max-width: 768px) {
  h3 {
    font-size: 23px;
    margin-bottom: 20px;
  }
  .title {
    margin-bottom: 30px;
  }
  .title figure.main {
    padding: 0 20px;
    margin-bottom: 110px;
  }
  .title figure.sub {
    width: 35%;
    left: 0;
    right: 0;
    top: 30vw;
    margin: 0 auto;
  }
  .title .txt {
    padding: 0 20px;
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
  .feature5 .title .txt {
    margin: 0;
  }
  .title .txt p {
    font-size: 14px;
    line-height: 24px;
  }
  .container{
    padding-top: 70px;
    padding-bottom: 50px;
  }
}
/*-----------------------------------------------------------*/
/*--------------------------feature2-------------------------*/
/*-----------------------------------------------------------*/
.feature2 {
  padding-bottom: 120px;
}
.feature2 .txt .red {
  color: #fe3340;
  font-weight: 700;
}
@media only screen and (max-width: 768px) {
  .feature2 {
    padding-bottom: 50px;
  }
}
::v-deep .purchase-button {
  margin-bottom: 120px;
}
</style>
