<template>
<section id="tc14" class="lazyload">
  <div class="container inner01">
    <figure><img v-lazy="'/top2020/img/tc14_01.webp'" alt=""></figure>
    <h2>特別コラボキャンペーン<br>実施中！</h2>
    <p class="p1">好評につき期間延長！2024年9月15日(日)まで</p>
    <p class="p2">
      <span>
      ※購入から2ヵ月以内の方が対象となります。<br>
      ※当キャンペーンは原則期間限定での実施となります。<br>
       コラボレーションの関係上、次回の延長は確約できかねますのでご了承ください。
    </span>
    </p>
    <div class="flexb">
      <figure><img v-lazy="'/top2020/img/tc14_02.webp'" alt=""></figure>
      <div class="txt">
        <p class="p3">当教材購入者の方はもれなく</p>
        <p class="p4">QQEnglishの<br>オンライン英会話レッスン（25分）</p>
        <p class="p5"><span class="marker">18回を無料で受講できます！</span></p>
        <p class="p6">JJ ENGLISHエクササイズで身につけた英語力をリアルな英会話でどんどん磨いていきましょう！教師は全員が国際資格「TESL」を取得したプロ。スカイプによるオンライン・レッスンを提供する英会話学校「QQEnglish」との提携により、 「通常2回」の無料体験を18回受講できます。（通常2回の無料体験＋月16回コースの初月無料）</p>
        <p class="p7">教材に同封の専用URLからお申し込みください！</p>
      </div>
    </div>
  </div>
</section>
</template>

<script>
export default {
  name: 'Tc14',
}
</script>

<style scoped lang="scss">
#tc14 {
  &.lazyloaded {
    background-image: url("/top2020/img/tc14_bg.webp");
  }
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: bottom;
  padding: 200px 0 140px 0;
  position: relative;
}
#tc14 .container > figure {
  width: 480px;
  margin: 0 auto 30px auto;
}
#tc14 .container h2 {
  font-weight: 800;
  font-size: 90px;
  line-height: 110px;
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
}
#tc14 .container p.p1 {
  border: 1px solid #fff;
  color: #fff;
  border-radius: 30px;
  font-size: 30px;
  width: 660px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto 20px auto;
  font-weight: 400;
}
#tc14 .container p.p1 span {
  color: #f6e448;
}
#tc14 .container p.p2 {
  text-align: center;
  margin-bottom: 60px;
}
#tc14 .container p.p2 span {
  display: inline-block;
  color: #fff;
  font-size: 15px;
  text-align: left;
}
#tc14 .container .flexb figure {
  width: 325px;
}
#tc14 .container .flexb .txt {
  width: 660px;
  padding-top: 30px;
}
#tc14 .container p.p3 {
  color: #fff;
  font-size: 30px;
  line-height: 50px;
}
#tc14 .container p.p4 {
  color: #fff;
  font-size: 34px;
  line-height: 55px;
  font-weight: 700;
}
#tc14 .container p.p5 {
  color: #fff;
  font-size: 50px;
  line-height: 70px;
  font-weight: 700;
  margin-bottom: 40px;
}
#tc14 .container p.p6 {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}
#tc14 .container p.p7 {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}
@media only screen and (max-width: 768px) {
  #tc14 {
    &.lazyloaded {
      background-image: url("/top2020/img/sp_tc14_bg.webp");
    }
    padding: 150px 0;
  }
  #tc14 .container > figure {
    width: 70%;
  }
  #tc14 .container h2 {
    font-size: 27px;
    line-height: 40px;
    margin-bottom: 10px;
  }
  #tc14 .container p.p1 {
    font-size: 16px;
    line-height: 22px;
    width: 100%;
  }
  #tc14 .container .flexb figure {
    width: 160px;
    margin: 0 auto;
  }
  #tc14 .container .flexb .txt {
    width: 100%;
    padding-top: 20px;
  }
  #tc14 .container p.p3 {
    font-size: 15px;
    line-height: 25px;
  }
  #tc14 .container p.p4 {
    font-size: 17px;
    line-height: 27px;
  }
  #tc14 .container p.p5 {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 20px;
  }
  #tc14 .container p.p6 {
    font-size: 14px;
    line-height: 24px;
  }
  #tc14 .container p.p7 {
    font-size: 14px;
    line-height: 24px;
  }
}
</style>
