<template>
  <section id="tc10">
    <h2 class="inner02"><span>JJ ENGLISH エクササイズには…</span>学習意欲を高める特典も充実！</h2>
    <div class="container flexb inner01">
      <div class="box">
        <p class="num"><span class="w_border">学習支援<span class="num_inner">01</span></span></p>
        <h3>ネイティブによる<br class="pc_only">「音読」個別診断</h3>
        <ul class="flexc">
          <li>メール</li>
          <li>動画</li>
        </ul>
        <p class="p1">アウトプット練習の不安も解決<br>
          上達が加速していきます！</p>
        <figure><img v-lazy="'/top2020/img/tc10_04.webp'" alt=""></figure>
        <p class="p2">自分の音読がどのくらい上手に読めている？ 通じる発音になっている？ そんなアウトプットの不安に応える学習サポートサービスです。音読した音声か動画をメールでお送りいただくと、専属ネイティブからアドバイスポイントなどを動画でお返しします。月2回✕6カ月の12回までは無料です。（以後は1100円／回）</p>
      </div>
      <div class="box">
        <p class="num"><span class="w_border">学習支援<span class="num_inner">02</span></span></p>
        <h3>２年間無料<br class="pc_only">メールサポート</h3>
        <ul class="flexc">
          <li>メール</li>
        </ul>
        <p class="p1">ネイティブ＆監修者が<br>
          学習の疑問や悩みに対応</p>
        <figure><img v-lazy="'/top2020/img/tc10_05.webp'" alt=""></figure>
        <p class="p2">テキストの内容や学習方法に関する疑問や質問に、ネイティブと日本人の監修者が回答します。とくに学習方法については、現役バリバリ、超一流の「教えるプロ」が、アナタの悩みに答えます。だから安心。だから心強い。受付はもちろん24時間。回数も2年間は無制限です。参考になる質問／回答は専用サイトで共有もできます。</p>
      </div>
      <div class="box">
        <p class="num"><span class="w_border">特典<span class="num_inner">01</span></span></p>
        <h3>恐怖の<br class="pc_only">文法シアター</h3>
        <ul class="flexc">
          <li>動画30本</li>
        </ul>
        <p class="p1">安河内哲也先生と一緒に<br>
          中学英語の文法をもう一度おさらい</p>
        <figure><img v-lazy="'/top2020/img/tc10_02.webp'" alt=""></figure>
        <p class="p2">「学校を卒業してからずっと英語には触れていない」「文法と聞くと鳥肌が立つ」という人に見ていただきたいのが、安河内哲也先生が動画でレクチャーする「恐怖の文法シアター」です。“恐怖”とついていますが、内容はエンタメ感あふれる楽しい講義。中学英語の文法で重要なものからおさらいするので、基礎固めには最適です。<span class="marker2">東進ハイスクールのカリスマ講師の講義をぜひ体験してください。</span></p>
      </div>
      <div class="box">
        <p class="num"><span class="w_border">特典<span class="num_inner">02</span></span></p>
        <h3>日本文化おもしろ<br class="pc_only">ライブラリー</h3>
        <ul class="flexc">
          <li>動画120本</li>
          <li>PDF</li>
        </ul>
        <p class="p1">英語力をさらに磨く<br>
          中級～上級者向けの表現集</p>
        <figure><img v-lazy="'/top2020/img/tc10_03.webp'" alt=""></figure>
        <p class="p2">
          当教材のベースとなった『週刊おもてなし純ジャパENGLISH』（講談社刊）で大好評だったコンテンツを購入者限定に専用サイトから配信しています。海外からの観光客へ、百科事典的ではない、<span class="marker2">土産話になるような日本文化を紹介。</span>本教材の上級編コンテンツとなっています。ワイプリスニングやパワー音読のエクササイズで使えます。
          <span v-if="basePath === '/'" class="blue">※DL版は一部のみ付属します</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Tc10',
  computed: {
    basePath() {
      return this.$store.state.basePath
    }
  }
}
</script>

<style scoped lang="scss">
#tc10 {
  background-color: #fe3340;
  padding: 60px 0 140px 0;
  .blue {
    color: #0a74d7;
  }
}
#tc10 h2 {
  font-size: 38px;
  line-height: 52px;
  color: #fff;
  background-image: url("/top2020/img/tc8_huki.webp");
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  background-position: bottom;
  padding-bottom: 30px;
  text-align: center;
  margin-bottom: 50px;
}
#tc10 h2 span {
  font-size: 22px;
  display: block;
}
#tc10 .container {
  background-color: #fff;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 80px;
  padding-bottom: 20px;
  border-radius: 10px;
}
#tc10 .container .box {
  width: 48%;
  margin-bottom: 60px;
}
#tc10 .container .box p.num {
  background-color: #fe3340;
  padding: 8px;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  margin: 0 auto 30px auto;
}
#tc10 .container .box p.num span.w_border {
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
}
#tc10 .container .box p.num span.num_inner {
  display: block;
  font-size: 42px;
  line-height: 40px;
  font-family: 'Roboto Condensed', sans-serif;
}
#tc10 .container .box h3 {
  font-size: 34px;
  line-height: 40px;
  color: #fe3340;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
#tc10 .container .box ul {
  margin-bottom: 30px;
}
#tc10 .container .box ul li {
  width: 100px;
  height: 30px;
  border: 1px solid #fe3340;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fe3340;
  border-radius: 20px;
}
#tc10 .container .box ul li:nth-child(2) {
  margin-left: 10px;
}
#tc10 .container .box .p1 {
  font-size: 20px;
  line-height: 34px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}
#tc10 .container .box figure {
  width: 80%;
  margin: 0 auto 30px auto;
}
#tc10 .container .box .p2 {
  font-size: 16px;
  line-height: 24px;
}
@media only screen and (max-width: 768px) {
  #tc10 {
      padding: 60px 0;
  }
  #tc10 h2 {
    font-size: 25px;
    line-height: 32px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    padding: 0px 0px 20px 0px;
    width: 90%;
  }
  #tc10 h2 span {
    font-size: 14px;
  }
  #tc10 .container {
    width: 90%;
    margin: 0 auto;
    padding: 30px 20px;
  }
  #tc10 .container .box {
    width: 100%;
    margin-bottom: 20px;
  }
  #tc10 .container .box p.num {
    padding: 5px;
    width: 70px;
    height: 70px;
    margin: 0 auto 20px auto;
  }
  #tc10 .container .box p.num span.w_border {
    font-size: 12px;
  }
  #tc10 .container .box p.num span.num_inner {
    font-size: 21px;
    line-height: 20px;
  }
  #tc10 .container .box h3 {
    font-size: 18px;
    line-height: 20px;
  }
  #tc10 .container .box .p1 {
    font-size: 14px;
    line-height: 24px;
  }
}
</style>
